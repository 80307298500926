import { Box, Dialog, DialogContent, Table, TableBody, TableHead, Typography, TableRow, TableCell, Button } from "@mui/material";
import { useEffect } from "react";
import { darkGrayColor, darkGreenColor, greenColor, whiteColor, grayColor} from "../../constants/constants";
import {
    addAssignment,
    closeSaveSaleDialog,
    fetchProjectsFromClients,
    fetchRecurringSales,
    savesSales,
    cleanList,
    fetchProjectServices, availableBtnNew
} from "../../core/state/dialogs/saveSaleDialogSlice";
import { useAppDispatch, useAppSelector } from "../../core/state/hooks";
import SalesRowDialog from "./salesRowDialog";


interface Column {
    id: 'Borrar' | 'Fecha' | 'Cliente' | 'Documento' | 'NDeDoc' | 'Concepto' | 'Servicio' |
     'CentroDeCosto' | 'Moneda' | 'NetoOrigen' | 'IVA' | 'Total' | 'FDeCobro';  
    label: string;
    minWidth?: number;
}

const columns: readonly Column[] = [
    { id: 'Borrar', label: '', minWidth:20},
    { id: 'Fecha', label: 'Fecha', minWidth: 200 },
    { id: 'Cliente', label: 'Cliente', minWidth: 150 },
    { id: 'CentroDeCosto', label: 'Centro de costo',minWidth: 250, },
    { id: 'Documento', label: 'Documento', minWidth: 150 },
    { id: 'NDeDoc', label: 'N. de Doc', minWidth: 150,}, 
    { id: 'Concepto', label: 'Concepto', minWidth: 250,},
    { id: 'Servicio', label: 'Servicio',minWidth: 125, },
    { id: 'Moneda', label: 'Moneda',minWidth: 125, },
    { id: 'NetoOrigen', label: 'Neto Origen',minWidth: 150, },
    { id: 'IVA', label: 'IVA',minWidth: 150, },
    { id: 'Total', label: 'Total',minWidth: 150, },
    { id: 'FDeCobro', label: 'F. de Cobro',minWidth: 250, },
];

export function SaveSaleDialog () {
    const dispatch = useAppDispatch();
    const {isOpen: dialogIsOpen, salesList, clientList, recurringSales,btnNew, projectServices, recurringNewSales, futureSaveList  } = useAppSelector((state) => state.saveSaleDialog)
    const { page, rowsPerPage, selectedMonth, yearFilter, searchFilter} = useAppSelector((state) => state.sales);

    useEffect(() => {
        if(dialogIsOpen){
            dispatch(fetchProjectServices());
            dispatch(fetchProjectsFromClients());
            dispatch(fetchRecurringSales())
        }
    },[dialogIsOpen]);

    const handleClickSaveSales = () => {
        dispatch(closeSaveSaleDialog());
        dispatch(savesSales({offset: page*rowsPerPage, limit: rowsPerPage, month: selectedMonth, year: yearFilter.toString(), searchTerm: searchFilter}));
        dispatch(cleanList());
    }

    return (
        <Dialog open={dialogIsOpen} onClose={() => { dispatch(closeSaveSaleDialog());dispatch(cleanList()); dispatch(availableBtnNew(false)) }} maxWidth="lg" >
            <DialogContent sx={{minWidth:'1200px'}}>
                <DialogContent>
                    <Typography variant="h5" sx={{color:darkGrayColor}}>Alta de Ventas</Typography>
                </DialogContent>
                <DialogContent >
                    <Box sx={{display:'flex'}}>
                        <Table sx={{overflowX:'scroll', width:'100%'}} stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow >
                                    {recurringSales.length > 0 ? columns.map((column) => (
                                        <TableCell 
                                                key={column.id} align="center"
                                                style={{ minWidth: column.minWidth}} sx={{borderBottom:1,borderColor:grayColor}}
                                            >
                                            <Typography variant='h6'>{column.label}</Typography>
                                        </TableCell>
                                    )) : <></>
                                }
                                </TableRow>
                            </TableHead>
                            <TableBody >
                                {
                                    recurringSales.length > 0 ? recurringSales.map((sale,_index) => {
                                    return (
                                        <SalesRowDialog newSale={false} clients={[]} projectServices={projectServices} index={_index} key={`${sale.id}`} sale={sale}/>
                                    )
                                    }): <></>
                                }
                            </TableBody>
                        </Table>
                    </Box>
                </DialogContent>
                <Box sx={{display:'flex',flexDirection:'row-reverse',my:2, alignItems:'center',backgroundColor:greenColor,maxWidth:'90%', maxHeight:'2px'}}>
                        <Button disabled={btnNew} onClick={() => {
                            dispatch(addAssignment())
                            }} sx={{backgroundColor:greenColor,color:whiteColor,px:3,'&:hover': {backgroundColor: darkGreenColor,}}}>
                            Nuevo +
                        </Button>
                </Box>
                <DialogContent>
                    <Box sx={{display:'flex'}}>
                        <Table sx={{overflowX:'scroll', width:'100%'}} stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow >
                                    {salesList.length > 0 ? columns.map((column) => (
                                        <TableCell 
                                                key={column.id} align="center"
                                                style={{ minWidth: column.minWidth}} sx={{borderBottom:1,borderColor:grayColor}}
                                            >
                                            <Typography variant='h6'>{column.label}</Typography>
                                        </TableCell>
                                    )) : <></>
                                }
                                </TableRow>
                            </TableHead>
                            <TableBody >
                                {
                                    salesList.length > 0 ? salesList.map((n,_index) => {
                                    return (
                                        <SalesRowDialog newSale={true} clients={clientList} projectServices={projectServices} index={_index} key={`${n.key}`} sale={undefined}/>
                                    )
                                    }): <></>
                                }
                            </TableBody>
                        </Table>
                    </Box>
                </DialogContent>
                <Box sx={{display:'flex',flexDirection:'row-reverse',mt:2,maxWidth:'90%'}}>
                    <Button onClick={handleClickSaveSales} sx={{backgroundColor:greenColor,color:whiteColor,px:3,'&:hover': {backgroundColor: darkGreenColor,}}}
                        disabled={recurringNewSales.length > 0 || futureSaveList.length > 0 ? btnNew : true}
                    >
                        Guardar
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    )
}