import { Box, Button, Dialog, DialogContent, Typography } from "@mui/material";
import {darkGreenColor, emptyString, grayColor, isTaskDialog, redColor, whiteColor} from "../../constants/constants";
import { closeRemoveDialog } from "../../core/state/dialogs/removeTaskTagDialogSlice";
import { useAppDispatch, useAppSelector } from "../../core/state/hooks";
import {
    fetchDeleteTag,
    fetchDeleteTask,
    fetchTags,
    fetchTasks
} from "../../core/state/tasksTags/tasksTagsSlice";
export default function RemoveProjectDialog() {
    const dispatch = useAppDispatch();
    const {isOpen: dialogIsOpen, TaskId, TagId, dialogType} = useAppSelector((state) => state.removeTaskTagDialog);

    const deleteTaskTag = async() => {
        if(dialogType === isTaskDialog){
            await dispatch(fetchDeleteTask(TaskId));
            dispatch(fetchTasks())
        }
        else{
            await dispatch(fetchDeleteTag(TagId));
            dispatch(fetchTags())
        }
        dispatch(closeRemoveDialog());
    }

    let confirmationMessage;

    if (dialogType === isTaskDialog) {
        confirmationMessage = '¿Desea eliminar la tarea?';
    } else {
        confirmationMessage = '¿Desea eliminar el tag?';
    }

    return (
        <Dialog open={dialogIsOpen} onClose={() => { dispatch(closeRemoveDialog()); }} maxWidth="md">
            <DialogContent sx={{display:"flex", flexDirection:"column", alignItems:"center", m:3,p:2 }}>
                <DialogContent>
                    <Box>
                        <Typography variant='h4'>{confirmationMessage}</Typography>
                    </Box>
                </DialogContent>

                <DialogContent>
                    <Box >
                        <Button sx={{m:1,p:1,backgroundColor:grayColor, color:whiteColor, width:200, '&:hover': {backgroundColor: darkGreenColor,}}}
                                onClick={() => dispatch(closeRemoveDialog())}>
                            <Typography variant="h5">Cancelar</Typography>
                        </Button>
                        <Button sx={{m:1,p:1,backgroundColor:redColor, color:whiteColor, width:200, '&:hover': {backgroundColor: darkGreenColor,}}}
                                onClick={async() => await deleteTaskTag() }>
                            <Typography variant="h5">Eliminar</Typography>
                        </Button>
                    </Box>
                </DialogContent>
            </DialogContent>
        </Dialog>
    );
}