import { Delete, Edit as EditIcon } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import { addDays, startOfWeek } from "date-fns";
import { toast } from "react-toastify";
import { darkGreenColor, greenColor, lightGrayColor, recordCreateWeekError, recordCreateWeekFailPerDay, recordCreateWeekSuccess, tagColors, whiteColor } from "../../../constants/constants";
import { postSaveRecord } from "../../../core/http/httpClient";
import { record as Record } from "../../../core/models/recordModel";
import { fetchWeeklyRecords, removeRecord } from "../../../core/state/dailyTracker/dailyTrackerSlice";
import { openRemoveDialog, setRecordToDelete } from "../../../core/state/dialogs/removeRecordDialogSlice";
import { openSaveRecordDialog } from "../../../core/state/dialogs/saveRecordDialogSlice";
import { useAppDispatch, useAppSelector } from "../../../core/state/hooks";
import { fetchMonthlyRecords } from "../../../core/state/monthlyTracker/monthlyTrackerSlice";
import RemoveRecordDialog from "./removeRecordDialog";
import { RecordDTO } from "../../../core/models/recordModelDTO";

const RecordInfo = (props: { record: RecordDTO}) => {
  const record = props.record;
  const { selectedDate } = useAppSelector((state) => state.date);
  const { user } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  return <Box display={"flex"}  flexDirection="row" px={2} py={2} borderBottom={1} 
    borderColor={"rgba(0, 0, 0, 0.1)"} alignItems={"center"} position={"relative"}
  sx={{backgroundColor:lightGrayColor}}
  >
    <Typography variant="h5" flex={0.2} textAlign="center">{record.timeQty?.toString().substring(0, 5)}</Typography>
    <Box display={"flex"}  flexDirection="column" flex={0.35}>
      <Typography pb={1} variant="subtitle1" >{`${record.clientName || 'Client'} - ${record.projectName}`}</Typography>
      <Typography pb={1} variant="body1" >{record.taskName}</Typography>
      <Box sx={{display:"flex",flexDirection:"row",flexWrap:"wrap"}}>
          {
            record.tagRecords?.map((tagRecord : any) => <Typography variant="body2" key={tagRecord.id} 
            sx={{backgroundColor:tagColors[(tagRecord.id || 0) % tagColors.length],color:whiteColor,borderRadius:2,mr:1,px:"3%"}}
            >{tagRecord.name}</Typography>)
          }
      </Box>
    </Box>
    <Box flex={0.4} display={"flex"} flexDirection={"column"} sx={{wordBreak:"break-word"}}>
      <Box display={"flex"} flex={0.6}>
        <Typography variant="caption" alignSelf={"baseline"} padding={"3%"}>{record.description}</Typography>
      </Box>
    </Box>
    <Box flex={0.05} display={"flex"} flexDirection={"row"}>
      <IconButton children={<EditIcon/>} 
        onClick={()=>{dispatch(openSaveRecordDialog({record:record,date:record.date}))}}/>
      <IconButton children={<Delete/>} 
        onClick={()=>{dispatch(setRecordToDelete(record)); dispatch(openRemoveDialog(true)) }}/>
    </Box>
      <RemoveRecordDialog />

  </Box>
}

export default RecordInfo;
