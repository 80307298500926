export const addTimeQty = (time1: String, time2: String) => {
    const totalMinutes = parseInt(time1.split(':')[1]) + parseInt(time2.split(':')[1]);
    const minutes = totalMinutes % 60;
    const hours = Math.floor(totalMinutes / 60) + parseInt(time1.split(':')[0]) + parseInt(time2.split(':')[0]);
    const strHours = hours < 10 ? `0${hours}` : `${hours}`;
    const strMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    return strHours + ':' + strMinutes;
}

export const percentage = (amount: String, base: String)=>{
    const baseMinutes = (parseInt(base.split(':')[1]) || 0) + (parseInt(base.split(':')[0])|| 0)* 60;
    const amountMinutes = (parseInt(amount.split(':')[1])|| 0) + (parseInt(amount.split(':')[0])|| 0)* 60;
    return amountMinutes / baseMinutes * 100;
}