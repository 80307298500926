import { LocalizationProvider } from '@mui/lab';
import { Box, Button, Dialog, DialogContent, FormControl, FormLabel, IconButton, MenuItem, Select, TextField, Typography } from '@mui/material';
import SaveProjectStageDialog from './saveProjectStageDialog';
import {
    darkGreenColor,
    greenColor,
    lightGrayColor,
    purpleColor,
    tagColors,
    whiteColor,
    redColor, emptyString,
    optionalRoleServiceIds
} from '../../constants/constants';
import './projects.css'
import {
    closeSaveProjectDialog,
    selectService,
    setName,
    selectClient,
    setProcess,
    setTags,
    setTasks,
    setCode,
    fetchTags,
    fetchTasks,
    fetchResources,
    fetchRoles,
    saveProject,
    setState,
    fetchProcesses,
    openSaveProjectStageDialogEdition,
    openSaveProjectStageDialogCreation,
    duplicateStage,
    setProjectResourceRole,
    setProjectResourcePrice, fetchProjectServices
} from '../../core/state/dialogs/saveProjectDialogSlice';
import { openRemoveStageDialog } from '../../core/state/dialogs/removeProjectStageDialogSlice';
import { useAppDispatch, useAppSelector } from '../../core/state/hooks';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DatePicker } from '@mui/lab';
import { Add } from '@mui/icons-material';
import { ReactComponent as DeleteIcon } from "../../../assets/icons/DeleteIcon.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/EditIcon.svg";
import { ReactComponent as CopyIcon } from "../../../assets/icons/CopyIcon.svg";
import React, { useEffect, useState } from 'react';
import RemoveProjectStateDialog from "./removeProjectStageDialog";
import {ProjectStage} from "../../core/models/projectStageModel";
import {StageResource} from "../../core/models/stageResource";
import {fetchGetProjects} from "../../core/state/dialogs/savePurchaseDialogSlice";

export default function SaveProjectDialog() {
    const dispatch = useAppDispatch();
    const { isOpen: dialogIsOpen, stageDialogisOpen, processes, selectedData, tags, tasks, resources, roles, clients, projectServices, states } = useAppSelector((state) => state.saveProjectDialog);
    var [isRolMandatory, setIsRolMandatory] = useState(true);
    const {projectsList } = useAppSelector((state) => state.savePurchaseDialog)
    const [errors, setErrors] = useState({projectName: false, projectCode: false, projectProcess: false, projectService: false, projectClient: false, projectTasks: false, projectStages: false, projectText: false, projectRoles: false});

    const styles ={
        greyLine: {border:"1.5px solid #F1F0F0", backgroundColor:"#F1F0F0"},
        secondGreyLine :{border:"1.5px solid #F1F0F0", backgroundColor:"#F1F0F0", mt:2, position:"relative"}
    }
    
    const successField = {backgroundColor:lightGrayColor,borderRadius:4,border:2,borderColor:lightGrayColor};
    const errorField = {backgroundColor:lightGrayColor,borderRadius:4,border:2,borderColor:redColor};

    const [projectResources, setProjectResources] = React.useState<StageResource[]>([]);
    const [prices, setPrices] = React.useState<{ [key: number]: number | string }>({});
    const [projectRoleIds, setProjectRoleIds] = React.useState<Record<number, number>>({});
    const [previousCode, setPreviousCode] = React.useState<string>(selectedData.code);


    const saveNewProject = () => {
        if( validProject()){
            dispatch(saveProject(0));
            dispatch(closeSaveProjectDialog());
        }
    }

    const setStyleSuccess = () => {
        setErrors({
            projectName: false,
            projectCode: false,
            projectProcess: false,
            projectService: false,
            projectClient: false,
            projectTasks: false,
            projectStages: false,
            projectText: false,
            projectRoles: false
        });
    }

    const validProject = () => {
        const { name, serviceId, processId, clientId, projectTasks } = selectedData;

        let newErrors = {...errors};
        let isValid = true;

        const validateField = (condition: boolean, fieldName: keyof typeof newErrors) => {
            if (condition) {
                newErrors[fieldName] = true;
                isValid = false;
            } else {
                newErrors[fieldName] = false;
            }
        };

        validateField(name === '' || name === null, 'projectName');
        validateField(serviceId === 0 || serviceId === null, 'projectService');
        validateField(processId === 0 || processId === null, 'projectProcess');
        validateField(clientId === 0, 'projectClient');
        validateField(projectTasks.length === 0, 'projectTasks');
        validateField(!resourcesHaveRole() && isRolMandatory, 'projectRoles');
        validateField(selectedData.code === emptyString, 'projectCode');
        validateField(!projectHasStages() || existingProjectCode(selectedData.code), 'projectText');

        setErrors(newErrors);

        return isValid;
    }



    const existingProjectCode = (code: string) => {
        const projectCodes = projectsList.map((project) => project.code);
        if(previousCode !== code) {
            return projectCodes.includes(code)
        }
        return false
    }
    const projectHasStages = () => {
        return selectedData.Stages.length !== 0
    }

    const resourcesHaveRole = () => {
        let hasRole = true;
        projectResources.forEach((resource) => {
            if (resource.projectRoleId === 0) {
                hasRole = false;
            }
        });
        return hasRole;
    }

    useEffect(() => {
        dispatch(fetchProcesses());
        dispatch(fetchResources());
        dispatch(fetchProjectServices());
        dispatch(fetchRoles());
        dispatch(fetchTasks());
        dispatch(fetchTags());
        dispatch(fetchGetProjects());
      }, [dispatch]);

    useEffect(() => {
        const fetchedResources = getProjectResources();
        setProjectResources(fetchedResources);
        setInitialPrices(fetchedResources);
        setInitialProjectRoleIds(fetchedResources);
    }, [selectedData]);

    useEffect(() => {
        setPreviousCode(selectedData.code);
        handleMandarotyRolWithServiceId(selectedData.serviceId);
    }, [dialogIsOpen]);

    useEffect(() => {
        const handleWheel = (event: WheelEvent) => {
            if ((document.activeElement as HTMLInputElement)?.type === "number") {
                (document.activeElement as HTMLInputElement)?.blur();
            }
        };
        document.addEventListener("wheel", handleWheel);
    }, []);
    
    const setInitialPrices = (fetchedResources: StageResource[]) => {
        const initialPrices = fetchedResources.reduce((acc, resource) => ({
            ...acc,
            [resource.resourceId]: resource.price, // Replace 'price' with actual property name if different
        }), {});
        setPrices(initialPrices);
    }
    const handlePriceChange = (id: number, newPrice: number) => {
        setPrices({
            ...prices,
            [id]: newPrice,
        });
    };

    const setInitialProjectRoleIds = (fetchedResources: StageResource[]) => {
        const initialProjectRoleIds = fetchedResources.reduce((acc, resource) => ({
            ...acc,
            [resource.resourceId]: resource.projectRoleId, // Replace 'projectRoleId' with actual property name if different
        }), {});
        setProjectRoleIds(initialProjectRoleIds);
    }

    const handleProjectRoleIdChange = (id: number, newProjectRoleId: number) => {
        if(newProjectRoleId !== 0 || !isRolMandatory) {
            setProjectRoleIds({
                ...projectRoleIds,
                [id]: newProjectRoleId,
            });
        }
    };
    function saveNewProjectStage() {
        let projectStage = new ProjectStage();
        projectStage.projectId = selectedData.id;
        dispatch(openSaveProjectStageDialogCreation(projectStage));
    }

    function getProjectResources(){
        let uniqueResources: Map<number, StageResource> = new Map<number, StageResource>();

        for (let stage of selectedData.Stages) {
            for (let resource of stage.stageResources) {
                if (!uniqueResources.has(resource.resourceId)) {
                    uniqueResources.set(resource.resourceId, resource);
                }
            }
        }

        const uniqueStageResources: StageResource[] = Array.from(uniqueResources.values());
        return uniqueStageResources;
    }

    function handleServiceChange(serviceId: any){
        dispatch(selectService(serviceId))
        handleMandarotyRolWithServiceId(serviceId)
    }

    function handleMandarotyRolWithServiceId(serviceId: any){
        if(optionalRoleServiceIds.includes(serviceId)){
            setIsRolMandatory(false)
        }else{
            setIsRolMandatory(true)
        }
    }

    function updateProjectResourceRole(resourceId: number, roleId: any) {
        dispatch(setProjectResourceRole({ resourceId: resourceId, projectRoleId: roleId}));
        getProjectResources();
    }
    function updateProjectResourcePrice(resourceId: number, price: number) {
        dispatch(setProjectResourcePrice({ resourceId: resourceId, price: price }));
        getProjectResources();
    }

    const onClose = () => {
        dispatch(closeSaveProjectDialog());
        setStyleSuccess();
        setIsRolMandatory(true)
    }

    return (
        <Dialog open={dialogIsOpen} fullWidth maxWidth="md">
            <DialogContent sx={{display:"flex",flexDirection:"column"}}>
                <DialogContent sx={{display:"flex",flexDirection:"row", pb:'0px !important', position:'relative', overflowY: 'visible'}}>
                        <Typography variant='h5' sx={{ borderBottom: 2, borderBottomColor: 'rgba(35, 31, 32, 0.1)', pb: 1, flex:1 }}>{selectedData.id === 0? 'Crear' : 'Editar'} Proyecto</Typography>
                        {
                            selectedData.id !== 0?
                            <FormControl sx={{flex:0.2, overflowY:'hidden', position: 'absolute', bottom: '6px', right: '17px'}}>
                            <Select
                                id="state-select"
                                label="Estado"
                                value={selectedData.stateId}
                                onChange={(event) => { dispatch(setState(event.target.value))}}
                                sx={{backgroundColor:lightGrayColor,borderRadius:4}}
                            >
                                {states && states.map((state) => {
                                    return <MenuItem value={state.id} key={state.id}>{state.name}</MenuItem>;
                                })}
                            </Select>
                            </FormControl> : <></>
                        }
                </DialogContent>
                <DialogContent sx={{display:"flex", overflowY:'initial', pb: '2px !important'}}>

                    <FormControl sx={{flex:0.7, mr:1}}>
                        <FormLabel sx={{ml:0.5, mb:0.5}}>Proyecto (*)</FormLabel>
                        <TextField sx={errors.projectName? errorField : successField} id="description"
                            value={selectedData.name}
                            onChange={(event) => { dispatch(setName(event.target.value)); }} />
                    </FormControl>
                    <FormControl sx={{flex:0.3}}>
                        <FormLabel sx={{ml:0.5, mb:0.5}}>Servicio (*)</FormLabel>
                        <Select
                            id="service-select"
                            label="Service"
                            value={selectedData.serviceId}
                            onChange={(event) => { handleServiceChange(event.target.value)}}
                            sx={errors.projectService? errorField : successField}
                        >
                            {projectServices && projectServices.map((projectService) => {
                                return <MenuItem value={projectService.id} key={projectService.id}>{projectService.name}</MenuItem>;
                            })}
                        </Select>
                    </FormControl>
                </DialogContent>

                <DialogContent sx={{display:"flex", overflowY:'initial', pb: '2px !important'}}>
                    <FormControl sx={{flex:0.7, mr:1}}>
                        <FormLabel sx={{ml:0.5, mb:0.5}}>Cliente (*)</FormLabel>
                        <Select
                            id="cliente-select"
                            label="Cliente"
                            value={selectedData.clientId}
                            onChange={(event) => { dispatch(selectClient(event.target.value))}}
                            sx={errors.projectClient? errorField : successField}
                        >
                            {
                                clients && clients.map((client) => <MenuItem value={client.id} key={client.id}>{client.name}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                    <FormControl sx={{flex:0.3}}>
                        <FormLabel sx={{ml:0.5, mb:0.5}}>Codigo (*)</FormLabel>
                        <TextField sx={errors.projectCode? errorField : successField} id="code"
                            value={selectedData.code}
                            onChange={(event) => { dispatch(setCode(event.target.value)); }} />
                    </FormControl>
                </DialogContent>

                <DialogContent sx={{display:"flex", overflowY:'initial', pb: '2px !important', mb:3}}>
                    <FormControl sx={{flex:1}}>
                        <FormLabel sx={{ml:0.5, mb:0.5}}>Proceso (*)</FormLabel>
                        <Select
                            id="process-select"
                            label="Process"
                            value={selectedData.processId || 0}
                            onChange={(event) => { dispatch(setProcess(event.target.value))}}
                            sx={errors.projectProcess? errorField : successField}
                        >
                            {
                                processes && [...processes]
                                    .sort((a, b) => (a?.name || '').localeCompare(b?.name || ''))
                                    .map((process) => process && <MenuItem value={process.id} key={process.id}>{process.name}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                </DialogContent>

                <DialogContent sx={{display:"flex",flexDirection:"row", pb:'0px !important', position:'relative', overflowY: 'visible'}}>
                    <Typography variant='h6' sx={{ml:0.5, borderBottom: 2, borderBottomColor: 'rgba(35, 31, 32, 0.1)', pb: 1, flex:1, fontSize:'16px !important' }}>Etapas (**)</Typography>
                </DialogContent>
                <DialogContent sx={{display:"flex", justifyContent:"space-between", flexDirection: "column", overflowY:'initial', pb: '2px !important'}}>
                    {selectedData.Stages.length > 0
                        ? selectedData.Stages
                            .map((projectStage, index) => ({ projectStage, index })) // Add originalIndex here
                            .sort((a, b) => a.projectStage.number - b.projectStage.number)
                            .map(({ projectStage, index }) => {
                            return (
                                <Box key={index} sx={{display:"flex",flexDirection:"row", justifyContent:"space-between", mb:2}}>
                                    <FormControl sx={{display:"flex",flexDirection:"row",flex:0.8, alignItems:"self-end"}}>
                                        <FormControl sx={{flex:1, mr:1}}>
                                            <FormLabel sx={{ml:0.5, mb:0.5}}>Etapa {projectStage.number}</FormLabel>
                                            <TextField
                                                value={projectStage.name}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                sx={{backgroundColor:lightGrayColor,borderRadius:4}}
                                            />
                                        </FormControl>
                                        <FormControl sx={{flex:0.7, mr:1}}>
                                            <FormLabel sx={{ml:0.5, mb:0.5}}>Fecha</FormLabel>
                                            <LocalizationProvider dateAdapter={AdapterDateFns} >
                                                <Box sx={{backgroundColor:lightGrayColor,borderRadius:9}}>
                                                    <DatePicker
                                                        inputFormat="dd/MM/yyyy"
                                                        value={projectStage.startDate}
                                                        onChange={() => {}}
                                                        renderInput={(params) =>
                                                            <TextField
                                                                {...params}
                                                                InputProps={{
                                                                    readOnly: true,
                                                                }}
                                                            />}
                                                        disabled={true}
                                                    />
                                                </Box>
                                            </LocalizationProvider>
                                        </FormControl>
                                        <FormControl sx={{flex:0.7, ml:1}}>
                                            <FormControl>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <Box sx={{backgroundColor:lightGrayColor,borderRadius:9}}>
                                                        <DatePicker
                                                            inputFormat="dd/MM/yyyy"
                                                            value={projectStage.endDate}
                                                            onChange={() => {}}
                                                            renderInput={(params) =>
                                                                <TextField
                                                                    {...params}
                                                                    InputProps={{
                                                                        readOnly: true,
                                                                    }}
                                                                />}
                                                                disabled={true}
                                                        />
                                                    </Box>
                                                </LocalizationProvider>
                                            </FormControl>
                                        </FormControl>
                                    </FormControl>
                                    <FormControl sx={{display:"flex",flexDirection:"row",flex:0.2, alignItems:"self-end", justifyContent:"space-evenly"}}>
                                        <IconButton onClick={()=> {
                                            dispatch(openSaveProjectStageDialogEdition({stage: projectStage, stageIndex: index}));}
                                        } children={<EditIcon />} />
                                        <IconButton onClick={()=> {
                                            dispatch(duplicateStage({stageIndex: index}));}
                                        } children={<CopyIcon />} />
                                        <IconButton onClick={() => {
                                            dispatch(openRemoveStageDialog({stageIndex: index}));
                                        }} children={<DeleteIcon />}/>
                                    </FormControl>
                                </Box>
                            )
                        }) : <Box my={3}></Box>
                    }
                </DialogContent>

                <DialogContent sx={{display:"flex", flexDirection:"column", flex:"1 50 auto !important",zIndex:10}}>

                </DialogContent>

                <Box sx={styles.secondGreyLine}>
                    <IconButton children={<Add />} onClick={() => {
                        saveNewProjectStage();
                    }}
                    sx={{zIndex:10,position:"absolute", right:"-5px",top:"-25px",backgroundColor:greenColor,color:whiteColor,"&:hover":{backgroundColor:darkGreenColor}}}/>
                </Box>
                <DialogContent sx={{display:"flex",flexDirection:"row", pb:'0px !important', position:'relative', overflowY: 'visible'}}>
                    <Typography variant='h6' sx={{ml:0.5, borderBottom: 2, borderBottomColor: 'rgba(35, 31, 32, 0.1)', pb: 1, flex:1, fontSize:'16px !important' }}>Recursos</Typography>
                </DialogContent>
                <DialogContent sx={{display:"flex", justifyContent:"space-between", flexDirection: "column", overflowY:'initial', pb: '2px !important'}}>
                    {
                        projectResources.length > 0 ? projectResources.map(resource => {
                            return (
                                <Box key={resource.resourceId} sx={{display:"flex",flexDirection:"row", justifyContent:"space-between", mb:2}}>
                                    <FormControl sx={{display:"flex",flexDirection:"row",flex:1, alignItems:"self-end"}}>
                                        <FormControl sx={{flex:0.5}} fullWidth>
                                            <FormLabel sx={{ml:0.5, mb:0.5}}>Nombre</FormLabel>
                                            <Select
                                                id="resource-select"
                                                label="Resource"
                                                value={resource.resourceId}
                                                onChange={(event) => { }}
                                                disabled={true}
                                                IconComponent={() => null}
                                            >
                                                {
                                                    resources && resources.map((resource) => <MenuItem value={resource.id} key={resource.id}>{resource.name + " " + resource.lastName } </MenuItem>)
                                                }
                                            </Select>
                                        </FormControl>
                                        <FormControl sx={{ml:1, flex:0.25}} fullWidth>
                                            <FormLabel sx={{ml:0.5, mb:0.5}}>{isRolMandatory? "Rol (*)" : "Rol"}</FormLabel>
                                            <Select
                                                id="rol-select"
                                                label="Rol"
                                                value={projectRoleIds[resource.resourceId] !== undefined ? String(projectRoleIds[resource.resourceId]) : ''}
                                                onChange={(event) => {handleProjectRoleIdChange(resource.resourceId, parseInt(event.target.value))}}
                                                sx={errors.projectRoles? errorField : successField}
                                                onBlur={(event) => {
                                                    updateProjectResourceRole(resource.resourceId, parseInt(projectRoleIds[resource.resourceId].toString()))
                                                }}
                                            >
                                                {
                                                    roles && roles.map((role) => <MenuItem value={role.id} key={role.id}>{role.name} </MenuItem>)
                                                }
                                            </Select>
                                        </FormControl>
                                        <FormControl sx={{flex:0.25, ml:1}}>
                                            <FormLabel sx={{ml:0.5, mb:0.5}}>Precio</FormLabel>
                                            <FormControl>
                                                <Box sx={{ backgroundColor: lightGrayColor, borderRadius: 9 }}>
                                                    <TextField
                                                        type='number'
                                                        value={prices[resource.resourceId] !== undefined ? String(prices[resource.resourceId]) : ''}
                                                        onChange={event => {
                                                            const newValue = event.target.value !== '' ? Number(event.target.value) : 0;
                                                            if (!isNaN(newValue)) {
                                                                handlePriceChange(resource.resourceId, newValue);
                                                            }
                                                        }}
                                                        onBlur={(event) => {
                                                            const newValue = event.target.value !== '' ? Number(event.target.value) : 0;
                                                            if (!isNaN(newValue)) {
                                                                updateProjectResourcePrice(resource.resourceId, newValue);
                                                            } else {
                                                                updateProjectResourcePrice(resource.resourceId, 0); // Set value to 0 if invalid input is entered or field is empty
                                                            }
                                                        }}
                                                    />
                                                </Box>
                                            </FormControl>
                                        </FormControl>
                                    </FormControl>
                                </Box>
                            )
                        }) : <Box my={3}>{}</Box>
                    }
                </DialogContent>
                <Box sx={{...styles.secondGreyLine, ml: 2, mr: 2}}></Box>
                <DialogContent sx={{display:"flex", justifyContent:"space-between", overflowY: "visible" , pb: '2px !important'}}>
                    <FormControl sx={{flex:0.46}}>
                        <FormLabel sx={{ml:0.5, mb:0.5}}>Tareas (*)</FormLabel>
                        <Select
                            id="tasks-select"
                            label="Tasks"
                            multiple={true}
                            value={selectedData.projectTasks.map((task) => task.taskId)}
                            onChange={(event) => { dispatch(setTasks(event.target.value))}}
                            sx={errors.projectTasks? errorField : successField}
                            renderValue={(selected)=>
                                <Box  sx={{display:"flex",flexDirection:"row",flexWrap:"wrap",position:"absolute", bottom: "-80px",left:"7px", zIndex:1, width:"100%", height:"80px", overflowY:"scroll"}}>
                                    {selected.map((value,_index)=>(
                                        <Typography variant="subtitle1" key={_index}
                                        sx={{backgroundColor:tagColors[value % tagColors.length],borderRadius:5,color:whiteColor,mr:1,mt:1, px:"3%",height:"22px"}}
                                        >{tasks?.filter((task)=> task.id === value)[0]?.name}</Typography>
                                    ))}
                                </Box>
                            }>
                            {
                                tasks && tasks.map((task) =>
                                    <MenuItem value={task.id} key={task.id}>{task.name}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                    <FormControl sx={{flex:0.46}}>
                        <FormLabel sx={{ml:0.5, mb:0.5}}>Tags</FormLabel>
                        <Select
                            id="tag-select"
                            label="Tag"
                            multiple={true}
                            value={selectedData.projectTags.map((tag) => tag.tagId)}
                            onChange={(event) => { dispatch(setTags(event.target.value))}}
                            sx={{backgroundColor:lightGrayColor,borderRadius:4}}
                            renderValue={(selected)=>
                                <Box  sx={{display:"flex",flexDirection:"row",flexWrap:"wrap",position:"absolute", bottom: "-80px",left:"7px", zIndex:1, width:"100%", height:"80px", overflowY:"scroll"}}>
                                    {selected.map((value,_index)=>(
                                        <Typography variant="subtitle1" key={_index}
                                                    sx={{backgroundColor:tagColors[value % tagColors.length],borderRadius:5,color:whiteColor,mr:1,mt:1, px:"3%",height:"22px"}}
                                        >{tags?.filter((tag)=> tag.id === value)[0]?.name}</Typography>
                                    ))}
                                </Box>
                            }>
                            {
                                tags && tags.map((tag) => {
                                    return <MenuItem value={tag.id} key={tag.id}>{tag.name}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                </DialogContent>


                <Box display={'flex'} sx={{justifyContent:'space-between'}}>
                    <Box display={'flex'} sx={{mt:13,ml:2, color: errors.projectText? redColor : "None"}}>
                        (*) Campos obligatorios <br/>
                        (**) El proyecto debe tener al menos una etapa <br/>
                        (***) El Código debe ser único
                    </Box>
                    <Box display={'flex'}>
                        <Button sx={{backgroundColor:redColor,color:whiteColor,width:120,alignSelf:"end",mr:2, mt:10,'&:hover': {backgroundColor: darkGreenColor,}}}
                                onClick={onClose}>Cancelar</Button>
                    </Box>
                    <Box display={'flex'}>
                    <Button sx={{backgroundColor:greenColor,color:whiteColor,width:120,alignSelf:"end",mr:2, mt:10,'&:hover': {backgroundColor: darkGreenColor,}}}
                        onClick={saveNewProject}>Guardar</Button>
                    </Box>
                </Box>
            </DialogContent>
            <RemoveProjectStateDialog/>
            <SaveProjectStageDialog/>
        </Dialog>
    )
};

