import { Box, Button, Dialog, DialogContent, Typography } from "@mui/material";
import {StageHasHoursError, darkGreenColor, grayColor, redColor, whiteColor} from "../../constants/constants";
import { closeRemoveStageDialog } from "../../core/state/dialogs/removeProjectStageDialogSlice";
import { useAppDispatch, useAppSelector } from "../../core/state/hooks";
import {deleteStage} from "../../core/state/dialogs/saveProjectDialogSlice";
import {getStageHasHours} from "../../core/http/httpClient";
import {toast} from "react-toastify";

export default function RemoveProjectStateDialog() {
    const dispatch = useAppDispatch();
    const {selectedData} = useAppSelector((state) => state.saveProjectDialog);

    const removeProjectStage = async (stageIndex: number) => {
        if (await getStageHasHours(selectedData.Stages[stageIndex].id)) {
            toast.error(StageHasHoursError);
            dispatch(closeRemoveStageDialog());
        }else{
            dispatch(deleteStage(stageIndex));
            dispatch(closeRemoveStageDialog());
        }
    }

    const {isOpen: dialogIsOpen, stageIndex} = useAppSelector((state) => state.removeProjectStageDialog);
    return (
        <Dialog open={dialogIsOpen} onClose={() => { dispatch(closeRemoveStageDialog()); }} maxWidth="md">
            <DialogContent sx={{display:"flex", flexDirection:"column", alignItems:"center", m:3,p:2 }}>
                <DialogContent>
                    <Box>
                        <Typography variant='h4'>¿Desea eliminar la etapa?</Typography>
                    </Box>
                </DialogContent>

                <DialogContent>
                    <Box >
                        <Button sx={{m:1,p:1,backgroundColor:grayColor, color:whiteColor, width:200, '&:hover': {backgroundColor: darkGreenColor,}}}
                                onClick={() => dispatch(closeRemoveStageDialog())}>
                            <Typography variant="h5">Cancelar</Typography>
                        </Button>
                        <Button
                            sx={{m:1,p:1,backgroundColor:redColor, color:whiteColor, width:200, '&:hover': {backgroundColor: darkGreenColor,}}}
                            onClick={() => {removeProjectStage(stageIndex)}}
                        >
                            <Typography variant="h5">Eliminar</Typography>
                        </Button>
                    </Box>
                </DialogContent>
            </DialogContent>
        </Dialog>
    );
}