import { configureStore } from '@reduxjs/toolkit';
import { dailyTrackerReducer } from './dailyTracker/dailyTrackerSlice';
import { saveRecordDialogReducer } from './dialogs/saveRecordDialogSlice';
import { dateReducer } from './date/dateSlice';
import { monthlyTrackerReducer } from './monthlyTracker/monthlyTrackerSlice';
import { saveClientDialogReducer } from './dialogs/saveClientDialogSlice';
import { userReducer } from './user/userSlice';
import { clientsReducer } from './client/clientSlice';
import { projectsReducer } from './projects/projectSlice';
import { resourcesReducer } from './resources/resourceSlice';
import { saveProjectDialogReducer } from './dialogs/saveProjectDialogSlice';
import { saveResourceDialogReducer } from './dialogs/saveResourceDialogSlice';
import { saveBonusDialogReducer} from './dialogs/saveBonusDialogSlice';
import { sideBarReducer } from './sideBar/sideBarSlice';
import { tasksTagsReducer } from './tasksTags/tasksTagsSlice';
import { removeRecordDialogReducer } from './dialogs/removeRecordDialogSlice';
import { removeClientDialogReducer } from './dialogs/removeClientDialogSlice';
import { removeSaleDialogReducer } from './dialogs/removeSaleDialogSlice';
import { removePurchaseDialogReducer } from './dialogs/removePurchaseDialogSlice';
import { removeProjectStageDialogReducer } from './dialogs/removeProjectStageDialogSlice';
import { removeProjectDialogReducer } from './dialogs/removeProjectDialogSlice';
import { salesReducer } from './sales/salesSlice';
import { purchaseReducer } from './purchases/purchaseSlice';
import { saveSaleDialogReducer } from './dialogs/saveSaleDialogSlice';
import { savePurchaseDialogReducer } from './dialogs/savePurchaseDialogSlice';
import { editSaleDialogReducer } from './dialogs/editSaleDialogSlice';
import { editPurchaseDialogReducer } from './dialogs/editPurchaseDialogSlice';
import { removeTaskTagDialogReducer} from "./dialogs/removeTaskTagDialogSlice";
import { saveTaskTagDialogReducer} from "./dialogs/saveTaskTagDialogSlice";

export const store = configureStore({
  reducer: {
    saveRecordDialog : saveRecordDialogReducer,
    saveClientDialog : saveClientDialogReducer,
    saveProjectDialog : saveProjectDialogReducer,
    saveResourceDialog : saveResourceDialogReducer,
    saveBonusDialog : saveBonusDialogReducer,
    removeRecordDialog : removeRecordDialogReducer,
    removeClientDialog : removeClientDialogReducer,
    removeProjectDialog : removeProjectDialogReducer,
    removeSaleDialog : removeSaleDialogReducer,
    removePurchaseDialog : removePurchaseDialogReducer,
    removeProjectStageDialog : removeProjectStageDialogReducer,
    removeTaskTagDialog : removeTaskTagDialogReducer,
    saveTaskTagDialog: saveTaskTagDialogReducer,
    saveSaleDialog : saveSaleDialogReducer,
    savePurchaseDialog : savePurchaseDialogReducer,
    user:userReducer,
    dailyTracker: dailyTrackerReducer,
    monthlyTracker: monthlyTrackerReducer,
    date: dateReducer,
    clients: clientsReducer,
    projects: projectsReducer,
    sideBar: sideBarReducer,
    tasksTags: tasksTagsReducer,
    resources: resourcesReducer,
    sales : salesReducer,
    purchases : purchaseReducer,
    editSaleDialog : editSaleDialogReducer,
    editPurchaseDialog : editPurchaseDialogReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
 }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
