import { Box, TableRow, TableCell, Button, FormControl,  TextField, Select, MenuItem, Checkbox, SelectChangeEvent } from "@mui/material";
import { darkGrayColor, lightGrayColor,TypeOfMoneys, invalidNumberTypeMessage, emptyString, recurringN, addSaleRecurrentCreateFail, selectDateOfPaymentLesserFail } from "../../constants/constants";
import { Close } from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { Client } from "../../core/models/clientModel";
import { Project } from "../../core/models/projectModel";
import { availableBtnNew, deleteAssignment, updateAssignment,
    deleteSaleRecurrences, addSaleRecurrences } from "../../core/state/dialogs/saveSaleDialogSlice";
import { useAppDispatch  } from "../../core/state/hooks";
import { SaleDTO } from "../../core/models/saleModelDTO";
import {projectService} from "../../core/models/projectServiceModel";

export default function SalesRowDialog (props : {clients : Array<Client>, projectServices: Array<projectService>, index: number, newSale : boolean, sale:SaleDTO | undefined}) {
    const dispatch = useAppDispatch();
    const isNew = props.newSale

    const [ document , setDocument] = React.useState(isNew ? emptyString : props.sale?.document);
    const [ numDocument , setNumDocument] = React.useState(emptyString);
    const [ concept , setConcept] = React.useState(emptyString);
    const [ clientId , setClientId] = React.useState(0);
    const [ projectServiceId  , setProjectServiceId ] = React.useState(isNew ? 0 : props.sale?.serviceId);
    const [ projectCode  , setProjectCode ] = React.useState(isNew ? 0 : props.sale?.projectId);
    const [ netOrigin  , setNetOrigin ] = React.useState(0);
    const [ tax  , setTax ] = React.useState(0);
    const [ total  , setTotal ] = React.useState(0);
    const [ dateSale, setDateSale] = React.useState(new Date());
    const [ dateOfPayment, setDateOfPayment ] = React.useState(emptyString);

    const [ money, setMoney] = React.useState(TypeOfMoneys[1]);
    const [available , setAvailable] = React.useState(true);
    const [goProject, setGoProject ] = React.useState(true);
    const [clientsProjects, setClientsProjects] = React.useState(new Array<Project>());
    const [saleAdded , setSaleAdded] = React.useState(false);
    const [checked ,setChecked] = React.useState(false);

    const style = {backgroundColor:lightGrayColor,borderRadius:4}

    const isEmpty = (text : String = emptyString) : Boolean => {
        return (text === emptyString ? true : false);
    }
    const isZero = (num : any) : Boolean => {
        return (isNaN(num) || num === 0);
    }

    const compareDate = (date1 : Date, date2: Date) : boolean => {
        if (date1.valueOf() <= date2.valueOf()) 
            return true;
        else 
            return false; 

    }

    const [ ok, setOk] = React.useState(false);
    const [count,setCount] = React.useState(0);
    const checkForm = () : void => {
        if(isNew) {
            if(!isEmpty(document) && !isEmpty(numDocument) && !isEmpty(concept) && !isZero(clientId) &&
            !isZero(projectCode) && !isZero(netOrigin)){
                setCount(count + 1);
                setOk(true);
                dispatch(availableBtnNew(false))
            }else{
                setOk(false)
                dispatch(availableBtnNew(true))
            }
        }
    }

    useEffect(() => {
        checkForm();
    }, [dateSale, document, numDocument, concept, clientId, projectCode, netOrigin, tax, dateOfPayment, money]);
    const validForm = () => {
        if(!isEmpty(numDocument) && !isEmpty(concept) && !isZero(netOrigin)){
                return true;
        }else{
            return false;
        }
    }
    
    const addSale = () : void => {
        const sale = {
            id: 0,
            projectId : projectCode,
            project:null,
            dateSale,
            document,
            number : numDocument,
            concept,
            currency: money,
            netOrigin,
            tax,
            total,
            dateOfPayment,
            recurring : recurringN,
        }
        dispatch(updateAssignment({ index : props.index, sale  }))
    }

    const addRecurrencySale = () : void => {
        if(validForm()){
            const sale = {
                id: props.sale?.id,
                projectId : projectCode,
                project:null,
                dateSale,
                document,
                number : numDocument,
                concept,
                currency: money,
                netOrigin,
                tax,
                total,
                dateOfPayment,
                recurring : recurringN,
            }
            dispatch(addSaleRecurrences(sale));
        }else{
            toast.error(addSaleRecurrentCreateFail) 
            setChecked(!checked);
        }
    }

    useEffect(() => {
        if(ok && !saleAdded){
            addSale();
            setSaleAdded(!saleAdded);
        }
    },[count])

    useEffect(() => {
        if(checked){
            addRecurrencySale();
        }
    },[checked])


    function getTotal() {
        if(isNaN(tax) && isNaN(netOrigin)){
            return 0;
        }
        if(isNaN(tax+netOrigin)){
        }
        return tax + netOrigin;
    }

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.checked) {
            dispatch(deleteSaleRecurrences(props.sale?.id))
        }
        setChecked(!checked);
    }

    const handleClientOnChange = (event: SelectChangeEvent<number>) => {
        const projects = props.clients.find(c => c.id === event.target.value)?.projects;
        setClientId(parseInt(event.target.value.toString()))
        setClientsProjects(projects ? projects : new Array<Project>());
        setProjectCode(0);
        setGoProject(false);
        setAvailable(true);
        setSaleAdded(false);
}

    return (
        <TableRow>
            <TableCell>
                <Box display={"flex"} flexDirection={"column"} justifyContent={"end"}>
                    { isNew ?
                    <>
                        <Button sx={{color:darkGrayColor,minWidth:10,alignSelf:"end", p:0.2 }}
                        onClick={() => {dispatch(deleteAssignment(props.index))}}
                        >
                            <Close sx={{fontSize:"1rem"}}/>
                        </Button>
                        <Box flex={0.15}></Box>
                    </>
                    :
                    <>

                        <Checkbox sx={{p:0,}}
                            checked={checked}
                            onChange={handleOnChange}
                            inputProps={{
                                'aria-labelledby': "labelId",
                            }}
                        />
                    </>
                }
                </Box>
            </TableCell>
            <TableCell>
                <FormControl>
                    <LocalizationProvider dateAdapter={AdapterDateFns} >
                        <Box sx={{backgroundColor:lightGrayColor,borderRadius:9}}>
                            <DatePicker
                                disableOpenPicker={false}
                                inputFormat="dd/MM/yyyy"
                                value={dateSale}
                                onChange={(date) => { 
                                    if(date){
                                        const day = new Date(date.getFullYear(),date.getMonth(),date.getDate());
                                        setDateSale(day);
                                    }
                                    setSaleAdded(false);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </Box>
                    </LocalizationProvider>
                </FormControl>
            </TableCell>
            <TableCell>
                <FormControl sx={{flex:0.37}} fullWidth>
                    {isNew ?
                    <>
                        <Select
                            id="client-select"
                            label="Client"
                            value={clientId}
                            onChange={handleClientOnChange}
                            sx={isNew ? style : {}}
                        >
                            {
                                props.clients && props.clients.map((client:Client) => <MenuItem value={client.id} key={client.id}>{client.name}</MenuItem>)
                            }
                        </Select>
                    </>
                    :
                    <>
                        <TextField inputProps={{style: { textAlign: 'center' }}}  disabled id="client" value={props.sale?.client}/>
                    </>
                    }
                </FormControl>
            </TableCell>
            <TableCell>
                <FormControl sx={{flex:0.37}} fullWidth>
                    {isNew ?
                    <>
                        <Select
                        disabled={goProject}
                        id="code-select"
                        label="Code"
                        value={projectCode}
                        onChange={(event) => {
                            const project = clientsProjects.find(p => p.id === event.target.value);
                            const projectService = props.projectServices.find(ps => ps.id === project?.serviceId);
                            setProjectServiceId(projectService?.id ? projectService?.id  : undefined);
                            setProjectCode(parseInt(event.target.value.toString()));
                            setAvailable(false);
                            setSaleAdded(false);
                        }}
                        sx={isNew ? style : {}}
                        >
                            {
                                clientsProjects && clientsProjects.map((project) => <MenuItem value={project.id} key={project.id}>{project.code} </MenuItem>)
                            }
                        </Select>
                    </>
                    :
                    <>
                        <TextField inputProps={{style: { textAlign: 'center' }}}  disabled id="code" value={props.sale?.projectCode}/>
                    </>
                    }
                </FormControl>
            </TableCell>
            <TableCell>
                <FormControl sx={{flex:0.27}}>
                    {isNew ?
                    <>
                        <TextField sx={style} id="document"
                        value={document}
                        disabled={isNew ? available : checked}
                        onChange={(event) => {
                            setDocument(event.target.value)
                            setSaleAdded(false);
                        }}
                        />
                    </>
                    :
                    <>
                        <TextField disabled id="document" value={props.sale?.document}/>
                    </>
                    }

                </FormControl>
            </TableCell>
            <TableCell>
                <FormControl sx={{flex:0.27}}>
                    <TextField sx={{backgroundColor:lightGrayColor,borderRadius:9}} id="numDocument"
                        disabled={isNew ? available : checked}
                        value={numDocument}
                        onChange={(event) => {
                            setNumDocument(event.target.value)
                            setSaleAdded(false);
                        }}
                        />
                </FormControl>
            </TableCell>
            <TableCell>
                <FormControl sx={{flex:0.27}}>
                    <TextField sx={{backgroundColor:lightGrayColor,borderRadius:9}} id="concept"
                        disabled={isNew ? available : checked}
                        value={concept}
                        onChange={(event) => {
                            setConcept(event.target.value)
                            setSaleAdded(false);
                        }}
                        />
                </FormControl>
            </TableCell>
            <TableCell>
                <FormControl sx={{flex:0.37}} fullWidth>
                    <Select
                        disabled
                        id="service-select"
                        label="Service"
                        value={projectServiceId}
                         sx={isNew ? style : {}}
                    >
                        {
                            props.projectServices && props.projectServices.map((service) => <MenuItem value={service.id} key={service.id}>{service.name} </MenuItem>)
                        }
                    </Select>
                </FormControl>
            </TableCell>
            <TableCell>
                <FormControl sx={{flex:0.37}} fullWidth>
                    <Select
                        disabled={isNew ? available : true}
                        id="money-select"
                        label="Money"
                            sx={isNew ? style : {}}
                        value={money}
                        onChange={(event) => {
                            setMoney(event.target.value)
                            setSaleAdded(false);
                        }}
                    >
                        {
                            TypeOfMoneys.map((money) => <MenuItem value={money} key={money}>{money} </MenuItem>)
                        }
                    </Select>
                </FormControl>
            </TableCell>
            <TableCell>
            <FormControl sx={{flex:0.27}}>
                    <TextField 
                        id="netOrigin"
                        type="number"
                        inputProps={{style: { textAlign: 'center' }}} 
                        sx={style} 
                        disabled={isNew ? available : checked}
                        onChange={(event) => {
                            setNetOrigin(parseFloat(event.target.value))
                            setTotal(tax+parseFloat(event.target.value));
                            setSaleAdded(false);
                        }} 
                        InputLabelProps={{
                            shrink: true
                        }}
                        />
                </FormControl>
            </TableCell>
            <TableCell>
            <FormControl sx={{flex:0.27}}>
                    <TextField
                        id="tax"
                        type="number"
                        InputLabelProps={{
                            shrink: true
                        }}
                        disabled={isNew ? available : checked} sx={style}
                        inputProps={{style: { textAlign: 'center' }}}
                        onChange={(event) => { 
                            setTax(parseFloat(event.target.value))
                            setTotal(parseFloat(event.target.value) + netOrigin)
                            setSaleAdded(false);
                        }}
                        />
                </FormControl>
            </TableCell>
            <TableCell>
            <FormControl sx={{flex:0.27}}>
                <TextField 
                    inputProps={{style: { textAlign: 'center' }}}  
                    id="total" sx={style}  
                    value={getTotal()} 
                    disabled />
                </FormControl>
            </TableCell>
            <TableCell>
                <FormControl>
                    <FormControl sx={{flex:0.27}}>
                        <TextField sx={{backgroundColor:lightGrayColor,borderRadius:9}} id="dateOfPayment"
                            disabled={isNew ? available : checked}
                            value={dateOfPayment}
                            onChange={(event) => {
                                setDateOfPayment(event.target.value)
                                setSaleAdded(false);
                            }}
                            />
                    </FormControl>
                </FormControl>
            </TableCell>
        </TableRow>
    )
}
