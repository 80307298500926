import './loading.css';


export function startSpinning(){
    const root = document.getElementById('root');
    const loading = document.getElementById('spinner');
    root?.classList.add('loading-indicator');
    loading?.classList.remove('display-none');
}

export function hideSpinner(){
    const root = document.getElementById('root');
    const loading = document.getElementById('spinner');
    root?.classList.remove('loading-indicator');
    loading?.classList.add('display-none');
}