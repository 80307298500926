import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { RootState } from "../store";
import {postBonusData, getBonusData, getPaginatedResources} from "../../http/httpClient";
import { fetchPaginatedResources } from "../resources/resourceSlice";
import {
    invalidNumberMessage,
    requestLoading, requestMissed,
    requestPending,
    requestSuccessful,
    TypeOfMoneys
} from "../../../constants/constants";
import {ResourceDTO} from "../../models/resourceModelDTO";

const namespace = 'saveBonusDialog'
const initialState = {
    isOpen:false,
    MoneysType : [{name:"UYU", id:1},{name:"USD", id:2}],
    selectedData: {
        resourceId:0,
        id:0,
        name : '',
        lastName: '',
        money: 0,
        typeOfMoney: 1,
    },
    status: requestPending
}

export const fetchResourceBonus = createAsyncThunk(
    `${namespace}/Bonus`,
    async (resourceId:number,thunkAPI) => {
        return(await getBonusData(resourceId));
    },
);

export const saveBonusData = createAsyncThunk(
    `${namespace}/Bonus`,
    async ({resourceId, offset, limit, area, searchTerm, }: {resourceId:number, offset: number; limit: number, area: string, searchTerm: string}
        ,thunkAPI) => {
        const { saveBonusDialog : state } = thunkAPI.getState() as RootState;
        await postBonusData({
            resourceId,
            bonusResource: state.selectedData.money,
            typeOfMoney: (state.selectedData.typeOfMoney === 1 ? TypeOfMoneys[0]:TypeOfMoneys[1]),
            date: new Date(),
        });
        thunkAPI.dispatch(fetchPaginatedResources({offset: offset,limit: limit, area:area, searchTerm: searchTerm}))
    },
);

export const saveBonusDialog = createSlice({
    name : namespace,
    initialState : initialState,
    reducers:{
        openSaveBonusDialog: (state,action) => {
            if(action.payload.resource){
                const resource = action.payload.resource as ResourceDTO;
                state.selectedData.id = resource.id ;
                state.selectedData.name = resource.name as string;
                state.selectedData.lastName = resource.lastName as string;
                state.selectedData.money = 0;
                state.selectedData.typeOfMoney = 1;
            }
            state.isOpen = true;
        },
        closeSaveBonusDialog:(state) => {
            state.isOpen = false;
        },
        setBonusResource : (state,action) => {
            if(!isNaN(action.payload) && (action.payload !== 0)){
                state.selectedData.money = action.payload;
            }else{
                toast.error(invalidNumberMessage)
            }
        },
        setTypeOfMoney : (state, action) => {
            state.selectedData.typeOfMoney = action.payload;
        }
    },
    extraReducers:(builder) =>{
        builder.addCase(fetchResourceBonus.pending, (state) =>{
            state.status = requestLoading;
        });
        builder.addCase(fetchResourceBonus.fulfilled, (state,action) =>{

            if(action.payload?.bonusResource !== undefined)
                state.selectedData.money = action.payload.bonusResource
            else
                state.selectedData.money = 0

            if(action.payload?.typeOfMoney === TypeOfMoneys[0])
                state.selectedData.typeOfMoney = 1;
            else
                state.selectedData.typeOfMoney = 2;

            state.status = requestSuccessful;
        });
        builder.addCase(fetchResourceBonus.rejected, (state) => {
            state.status = requestMissed;
        });
    }
})

export const saveBonusDialogReducer = saveBonusDialog.reducer;
export const { openSaveBonusDialog, closeSaveBonusDialog, setBonusResource,setTypeOfMoney } = saveBonusDialog.actions;