import { Box, TableRow, TableCell, Button, FormControl,  TextField, Select, MenuItem, Checkbox, SelectChangeEvent, Autocomplete, IconButton, InputAdornment, OutlinedInput } from "@mui/material";
import { darkGrayColor, lightGrayColor,TypeOfMoneys, emptyString, recurringN, addPurchaseRecurrentCreateFail} from "../../constants/constants";
import { Close, PhotoCamera } from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { Project } from "../../core/models/projectModel";
import { addPurchaseRecurrences, availableBtnNew, deleteAssignment, deletePurchaseRecurrences, updateAssignment, } from "../../core/state/dialogs/savePurchaseDialogSlice";
import { useAppDispatch  } from "../../core/state/hooks";
import { PurchaseDTO } from "../../core/models/purchaseModelDTO";
import { Sector } from "../../core/models/sectorModel";
import { SubSector } from "../../core/models/subSectorModel";
import {projectService} from "../../core/models/projectServiceModel";


export default function PurchaseRowDialog (props : { projectsList : Array<Project>, sectores:Array<Sector>, subSectores : Array<SubSector>, index: number, newPurchase : boolean, services:Array<projectService>, purchase:PurchaseDTO | undefined }) {
    const dispatch = useAppDispatch();
    const isNew = props.newPurchase;
    const codeList = props.projectsList.map((a) => a.code);
    const style = {backgroundColor:lightGrayColor,borderRadius:4}

    const [ datePurchase, setDatePurchase ] = React.useState(new Date());
    const [ document , setDocument] = React.useState(emptyString);
    const [ supplier , setSupplier] = React.useState(props.purchase?.supplier);
    const [ sectorId  , setSectorId ] = React.useState(isNew ? 0 : props.purchase?.sectorId);
    const [ subSectorId  , setSubSectorId ] = React.useState(isNew ? 0 : props.purchase?.subSectorId);
    const [ concept , setConcept] = React.useState(emptyString);
    const [ rut , setRut] = React.useState(props.purchase?.rut);
    const [ serviceId  , setServiceId ] = React.useState(isNew ? 0 : props.purchase?.serviceId);
    const [ projectId  , setProjectId ] = React.useState(isNew ? 0 : props.purchase?.projectId);
    const [ currency, setCurrency] = React.useState(TypeOfMoneys[1]);
    const [ netOrigin  , setNetOrigin ] = React.useState(0);
    const [ tax  , setTax ] = React.useState(0);
    const [ total  , setTotal ] = React.useState(0);
    
    const [available , setAvailable] = React.useState(false);
    const [withCode , setWithCode] = React.useState(false);

    const [purchaseAdded , setpurchaseAdded] = React.useState(false);
    const [checked ,setChecked] = React.useState(false);

    const isEmpty = (text : String = emptyString) : Boolean => (text === emptyString ? true : false)
    const isZero = (num : Number = 0) : Boolean => (num === 0 ? true : false) 

    const [ ok, setOk] = React.useState(false);

    const validForm = () : boolean =>  {
        if( !isEmpty(datePurchase?.toString()) && !isEmpty(document) && !isEmpty(supplier) && !isEmpty(rut) && !isZero(sectorId)
        && !isZero(subSectorId) &&  !isEmpty(concept) && !isEmpty(currency) && !isZero(netOrigin)){
                setOk(true);
                return true;
        }
        return false;
    }

    
    const addpurchase = () : void => {
        const purchase = {
            id: 0,
            date: datePurchase,
            document,
            supplier,
            rut,
            serviceId: (serviceId == 0 ? null : serviceId),
            subSectorId,
            concept,
            currency,
            projectId: (projectId ? projectId : 0),
            netOrigin,
            tax,
            total,
            subSector: null,
            project: null,
            recurring: recurringN,
        }
        if(!purchaseAdded){
            setpurchaseAdded(!purchaseAdded)
        }
        dispatch(updateAssignment({ index : props.index, purchase }))
        dispatch(availableBtnNew(false))
    }

    const addRecurrencypurchase = () : void => {
        if(validForm()){
            const purchase = {
                id: props.purchase?.id,
                date: datePurchase,
                document,
                supplier,
                rut,
                subSectorId,
                concept,
                currency,
                projectId : (projectId ? projectId : 0),
                netOrigin,
                tax,
                total,
                serviceId: (serviceId == 0 ? null : serviceId),
                subSector: null,
                project: null,
                recurring: recurringN,
            }
            dispatch(addPurchaseRecurrences(purchase));
        }else{
            toast.error(addPurchaseRecurrentCreateFail) 
            setChecked(!checked);
        }
    }

    useEffect(() => {
        if(validForm() && !purchaseAdded && isNew){
            addpurchase();
        }else if (ok && isNew) {
            dispatch(availableBtnNew(ok))
        }
    },[datePurchase, document, supplier,rut, serviceId, sectorId,subSectorId, concept, currency, projectId, netOrigin, tax, total])
    
    useEffect(() => {
        if(checked){
            addRecurrencypurchase();
        }
    },[checked])

    function getTotal() {
        if(isNaN(tax+netOrigin)){
            return 0;
        }
        return tax + netOrigin;
    }

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.checked) {
            dispatch(deletePurchaseRecurrences(props.purchase?.id))
        }
        setChecked(!checked);
    }

    const handleServiceOnChange = (id:number) : void => {
        setServiceId(id)
        setpurchaseAdded(false);
    }

    const [value, setValue] = React.useState<string>("");
    const [inputValue, setInputValue] = React.useState('');

    return (
        <TableRow>
            <TableCell align="center">
                <Box display={"flex"} flexDirection={"column"} justifyContent={"end"}>
                    { isNew ?
                    <>
                        <Button sx={{color:darkGrayColor,minWidth:10,alignSelf:"end", p:0.2 }}
                        onClick={() => {dispatch(deleteAssignment(props.index))}}
                        >
                            <Close sx={{fontSize:"1rem"}}/>
                        </Button>
                        <Box flex={0.15}></Box>
                    </>
                    :
                    <>

                        <Checkbox sx={{p:0,}}
                            checked={checked}
                            onChange={handleOnChange}
                            inputProps={{
                                'aria-labelledby': "labelId",
                            }}
                        />
                    </>
                }
                </Box>
            </TableCell>
            <TableCell align="center">
                <FormControl sx={{width:"170px"}}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} >
                        <Box sx={style}>
                            <DatePicker
                                disableOpenPicker={false}
                                inputFormat="dd/MM/yyyy"
                                value={datePurchase}
                                onChange={(date) => { 
                                    if(date){
                                        setDatePurchase(date)
                                        setpurchaseAdded(false);
                                    }
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </Box>
                    </LocalizationProvider>
                </FormControl>
            </TableCell>
            <TableCell align="center">
                <FormControl sx={{width:"175px",flex:0.27}}>
                    <TextField sx={{backgroundColor:lightGrayColor,borderRadius:9}} 
                        id="document"
                        disabled={isNew ? available : checked}
                        value={document}
                        onChange={(event) => { setDocument(event.target.value); setpurchaseAdded(false);}}
                    />
                </FormControl> 
            </TableCell>
            <TableCell align="center">
                <FormControl sx={{minWidth:200,flex:0.27}}>
                    <TextField sx={style} id="supplier"
                        value={supplier} disabled={!isNew}
                        onChange={(event) => { setSupplier(event.target.value); setpurchaseAdded(false);}}
                    />
                </FormControl>
            </TableCell>
            <TableCell align="center">
                <FormControl sx={{minWidth:200,flex:0.27}}>
                    <TextField sx={style} id="rut"
                        value={rut} disabled={!isNew}
                        onChange={(event) => { setRut(event.target.value); setpurchaseAdded(false);}}
                    />
                </FormControl>
            </TableCell>
            <TableCell align="center">
                <FormControl fullWidth>
                    {isNew ?
                    <>
                        <Select
                        id="sector-select"
                        label="sector"
                        value={sectorId}
                        onChange={(event : SelectChangeEvent<number>) => { 
                            const idSector = parseInt(event.target.value.toString());
                            const ssId = props.subSectores.find((ss : SubSector) => ss.sectorId===idSector);
                            setSectorId(idSector);
                            setSubSectorId(ssId?.id); setpurchaseAdded(false);
                        }}
                        sx={style}
                        >
                        {
                            props.sectores && props.sectores.map((sector : Sector) => <MenuItem value={sector.id} key={sector.id}>{sector.name} </MenuItem>)
                        }
                        </Select>
                    </>
                    :
                    <>
                        <TextField inputProps={{style: { textAlign: 'center' }}} sx={style} disabled id="sector-select" value={props.purchase?.sector}/>
                    </>
                    }
                </FormControl>
            </TableCell>
            <TableCell align="center">
                <FormControl fullWidth>
                    {isNew ?
                    <>
                        <Select
                        id="subSector-select"
                        label="SubSector"
                        value={subSectorId}
                        onChange={(event : SelectChangeEvent<number>) => { 
                            const idSubSector = parseInt(event.target.value.toString());
                            const ssId = props.subSectores.find((ss : SubSector) => ss.id===idSubSector);
                            setSubSectorId(idSubSector)
                            setSectorId(ssId?.sectorId); setpurchaseAdded(false);
                        }}
                        sx={style}
                        >
                            {
                                props.subSectores && props.subSectores.map((subSector : SubSector) => <MenuItem value={subSector.id} key={subSector.id}>{subSector.name} </MenuItem>)
                            }
                        </Select>
                    </>
                    :
                    <>
                        <TextField inputProps={{style: { textAlign: 'center' }}} sx={style} disabled id={`subSector-select-${subSectorId}`} value={props.purchase?.subSector}/>
                    </>
                    }
                </FormControl>
            </TableCell>
            <TableCell align="center">
                <FormControl sx={{width:"200px",flex:0.27}}>
                    <TextField sx={{backgroundColor:lightGrayColor,borderRadius:9}} id="concept"
                        disabled={isNew ? available : checked}
                        value={concept}
                        onChange={(event) => {
                            setConcept(event.target.value)
                            setpurchaseAdded(false);
                        }}
                        />
                </FormControl>
            </TableCell>
            <TableCell align="center">
                <FormControl sx={{width:"250px",flex:0.37}} fullWidth>
                    {isNew ?
                    <>
                        <Select
                        id="service-select"
                        label="Servicio"
                        disabled={withCode}
                        value={serviceId}
                        onChange={(event : SelectChangeEvent<number>) => { 
                            const idService = parseInt(event.target.value.toString());
                            setServiceId(idService);
                            setpurchaseAdded(false);
                        }}
                        sx={style}
                        >
                            <MenuItem value={0} key={0}>{emptyString}</MenuItem>
                        {
                            props.services && props.services.map((service : projectService) => <MenuItem value={service.id} key={service.id}>{service.name} </MenuItem>)
                        }
                        </Select>
                    </>
                    :
                    <>
                        <TextField inputProps={{style: { textAlign: 'center' }}} sx={style} disabled id={`service-select-${serviceId}`} value={props.purchase?.service}/>
                    </>
                    }
                </FormControl>
            </TableCell>
            <TableCell align="center">
                <FormControl fullWidth>
                    {isNew ? 
                        <Autocomplete
                            value={value}
                            onChange={(event, newValue) => {
                                if(newValue){
                                    const proj = props.projectsList.find((p : Project) => p.code===newValue);
                                    if (proj) {
                                        setProjectId(proj.id);
                                        handleServiceOnChange(proj.serviceId);
                                        setValue(newValue);
                                        setWithCode(true)
                                    }
                                }else{
                                    setValue("");
                                    setWithCode(false)
                                    setProjectId(0);
                                }
                                setpurchaseAdded(false);
                            } }
                            inputValue={inputValue}
                            onInputChange={(event, newInputValue) => {
                                setInputValue(newInputValue);
                            }}
                            id="controllable-states"
                            options={codeList}
                            sx={{ backgroundColor:lightGrayColor,borderRadius:9,width: 200, }}
                            renderInput={(params) => {
                                params.inputProps.style = { textAlign: 'center',paddingTop:"0px",marginTop:"-6px"};
                                return (<TextField {...params} />)
                                
                            }}
                        />
                        :
                        <>
                            <TextField inputProps={{style: { textAlign: 'center' }}} sx={style} disabled id={`projectId-select-${projectId}`} value={props.purchase?.projectCode}/>
                        </>
                    }
                </FormControl>
            </TableCell>
            <TableCell align="center">
                <FormControl sx={{flex:0.37}} fullWidth>
                    <Select
                        id="currency-select"
                        label="Currency"
                        sx={style} disabled={isNew ? available : checked}
                        value={currency} 
                        onChange={(event) => {
                            setCurrency(event.target.value)
                            setpurchaseAdded(false);
                        }}        
                    >
                        {
                            TypeOfMoneys.map((currency) => <MenuItem value={currency} key={currency}>{currency}</MenuItem>)        
                        }
                    </Select>
                </FormControl>
            </TableCell>
            <TableCell align="center">
                <FormControl sx={{flex:0.27}}>
                    <TextField 
                        id="netOrigin"
                        type="number"
                        inputProps={{style: { textAlign: 'center' }}} 
                        sx={style} 
                        disabled={isNew ? available : checked}
                        onChange={(event) => {
                            setNetOrigin(parseFloat(event.target.value))
                            setTotal(tax+parseFloat(event.target.value));
                            setpurchaseAdded(false);

                        }} 
                        InputLabelProps={{
                            shrink: true
                        }}
                        />
                </FormControl>
            </TableCell>
            <TableCell align="center">
                <FormControl sx={{flex:0.27}}>
                    <TextField
                        id="tax"
                        type="number"
                        InputLabelProps={{
                            shrink: true
                        }}
                        disabled={isNew ? available : checked} sx={style}
                        inputProps={{style: { textAlign: 'center' }}}
                        onChange={(event) => { 
                            setTax(parseFloat(event.target.value))
                            setTotal(parseFloat(event.target.value) + netOrigin)
                            setpurchaseAdded(false);
                        }}
                        />
                </FormControl>
            </TableCell>
            <TableCell align="center">
                <FormControl sx={{flex:0.27}}>
                <TextField 
                inputProps={{style: { textAlign: 'center' }}}  
                id="total" sx={style}  
                value={getTotal()} 
                disabled />
                </FormControl>
            </TableCell>
        </TableRow>
    )
}