import { ListItemText } from '@mui/material';
import { BusinessCenter } from '@mui/icons-material';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import { CSSObject, styled, Theme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { Link } from 'react-router-dom';
import '../../../App.css';
import { ReactComponent as HomeIcon } from "../../../assets/icons/home.svg";
import { ReactComponent as PeopleIcon } from "../../../assets/icons/people.svg";
import { ReactComponent as SaleIcon } from "../../../assets/icons/sale.svg";
import { ReactComponent as TaskTagIcon } from "../../../assets/icons/taskTag.svg";
import { ReactComponent as ResourceIcon } from "../../../assets/icons/resource.svg";
import { ReactComponent as PurchaseIcon } from "../../../assets/icons/purchaseIcon.svg";
import {drawerWidth, greenColor, lightBlackColor, manager, pm, whiteColor} from "../../constants/constants";
import { useAppSelector } from '../state/hooks';
import { admin, superAdmin } from '../../constants/constants';


export default function SideBar() {
  const { left } = useAppSelector((state) => state.sideBar);
  const { user } = useAppSelector((state) => state.user);

  const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create(['all'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });
  
  const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create(['all'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(22)} + 1px)`,
  });

  const Drawer = styled(MuiDrawer)(
    ({ theme, open }) => ({
      width: `${drawerWidth}px`,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
    }),
  );

  return <>
    <Drawer
      variant="permanent"
      open={!left}
      sx={{
        flexShrink: 0,
        width: `${drawerWidth}px`,
        zIndex: 1000,
        '& .MuiDrawer-paper': {
          backgroundColor:lightBlackColor
        },
      }}
    >
    <Toolbar sx={{backgroundColor:lightBlackColor}} />
    <List sx={{backgroundColor:lightBlackColor,height:"100%"}}>
      <ListItemButton component={Link} to="/home" key='Home' sx={{
                minHeight: 48,
                justifyContent: left ? 'initial !important' : 'center',
              }}>
        <ListItemIcon sx={{color:greenColor, minWidth: 0,
                  mr: left ? 3 : 'auto',
                  ml: left ? -0.5 : 'auto',
                  justifyContent: 'center',
                  width: 50}}>
          <HomeIcon />
        </ListItemIcon>
        <ListItemText primary={"Home"} sx={{ opacity: left ? 1 : 0, color:whiteColor }} />
      </ListItemButton>
      <ListItemButton component={Link} to="/clients" key='clients' sx={{
                minHeight: 48,
                justifyContent: left ? 'initial !important' : 'center',
              }}>
        <ListItemIcon sx={{color:greenColor, minWidth: 0,
                  mr: left ? 3 : 'auto',
                  ml: left ? -0.5 : 'auto',
                  justifyContent: 'center',
                  width: 50}}>
          <PeopleIcon/>
        </ListItemIcon>
        <ListItemText primary={"Clientes"} sx={{ opacity: left ? 1 : 0, ml: left? -0.1 : 0, color:whiteColor  }} />
      </ListItemButton>
      <ListItemButton component={Link} to="/projects" key='Projects' sx={{
                minHeight: 48,
                justifyContent: left ? 'initial !important' : 'center',
              }}>
        <ListItemIcon sx={{color:greenColor, minWidth: 0,
                  mr: left ? 3 : 'auto',
                  ml: left ? -0.5 : 'auto',
                  justifyContent: 'center',
                  width: 50}}>
          <BusinessCenter sx={{height: 27, width: 27}}/>
        </ListItemIcon>
        <ListItemText primary={"Proyectos"} sx={{ opacity: left ? 1 : 0, color:whiteColor }} />
      </ListItemButton>
        {
            (admin === user.appRoleId || pm === user.appRoleId || manager  === user.appRoleId || superAdmin === user.appRoleId )  ?
                <ListItemButton component={Link} to="/tasks&tags" key='tasks&tags' sx={{
                    minHeight: 48,
                    justifyContent: left ? 'initial !important' : 'center',
                }}>
                    <ListItemIcon sx={{color:greenColor, minWidth: 0,
                        mr: left ? 3 : 'auto',
                        ml: left ? -0.5 : 'auto',
                        justifyContent: 'center',
                        width: 50}}>
                        <TaskTagIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Tareas y Tags"} sx={{ opacity: left ? 1 : 0, color:whiteColor }} />
                </ListItemButton> : <></>
        }
      {
        (admin === user.appRoleId || superAdmin === user.appRoleId )  ? 
        <ListItemButton component={Link} to="/sales" key='Sales' sx={{
            minHeight: 48,
            justifyContent: left ? 'initial !important' : 'center',
          }}>
          <ListItemIcon sx={{color:greenColor, minWidth: 0, 
                  mr: left ? 3 : 'auto', 
                  ml: left ? -0.5 : 'auto',
                  justifyContent: 'center',
                  width: 50}}>
          <SaleIcon />
          </ListItemIcon>
          <ListItemText primary={"Ventas"} sx={{ opacity: left ? 1 : 0, color:whiteColor }} />
        </ListItemButton> : <></>
      }

      {
        (admin === user.appRoleId || superAdmin === user.appRoleId )  ? 
      <ListItemButton component={Link} to="/purchases" key='Purchases' sx={{
              minHeight: 48,
              justifyContent: left ? 'initial !important' : 'center',
            }}>
        <ListItemIcon sx={{color:greenColor, minWidth: 0, 
              mr: left ? 3 : 'auto', 
              ml: left ? -0.5 : 'auto',
              justifyContent: 'center',
              width: 50}}>
          <PurchaseIcon />
        </ListItemIcon>
        <ListItemText primary={"Compras"} sx={{ opacity: left ? 1 : 0, color:whiteColor }} />
      </ListItemButton> : <></>
      }

      <ListItemButton component={Link} to="/resources" key='Resources' sx={{
                minHeight: 48,
                justifyContent: left ? 'initial !important' : 'center',
              }}>
        <ListItemIcon sx={{color:greenColor, minWidth: 0, 
                  mr: left ? 3 : 'auto', 
                  ml: left ? -0.5 : 'auto',
                  justifyContent: 'center',
                  width: 50}}>
          <ResourceIcon />
        </ListItemIcon>
        <ListItemText primary={"Recursos"} sx={{ opacity: left ? 1 : 0, color:whiteColor }} />
      </ListItemButton>
    </List>
    </Drawer>
  </>
}
