import { Box, Button, Dialog, DialogContent, Typography } from "@mui/material";
import { darkGreenColor, grayColor, redColor, whiteColor } from "../../constants/constants";
import { fetchDeleteSale } from "../../core/state/sales/salesSlice";
import { closeRemoveDialog } from "../../core/state/dialogs/removeSaleDialogSlice";
import { useAppDispatch, useAppSelector } from "../../core/state/hooks";

export default function RemoveSaleDialog() {
    const dispatch = useAppDispatch();
    const {isOpen: dialogIsOpen, id} = useAppSelector((state) => state.removeSaleDialog);
    const { page, rowsPerPage, selectedMonth, yearFilter, searchFilter} = useAppSelector((state) => state.sales);

    return (
    <Dialog open={dialogIsOpen} onClose={() => { dispatch(closeRemoveDialog()); }} maxWidth="md">
        <DialogContent sx={{display:"flex", flexDirection:"column", alignItems:"center", m:3,p:2 }}>
            <DialogContent>
                <Box>
                    <Typography variant='h4'>¿Desea eliminar la venta?</Typography>
                </Box>
            </DialogContent>

            <DialogContent>
                <Box >
                    <Button sx={{m:1,p:1,backgroundColor:grayColor, color:whiteColor, width:200, '&:hover': {backgroundColor: darkGreenColor,}}}
                    onClick={() => dispatch(closeRemoveDialog())}>
                        <Typography variant="h5">Cancelar</Typography>
                    </Button>
                    <Button sx={{m:1,p:1,backgroundColor:redColor, color:whiteColor, width:200, '&:hover': {backgroundColor: darkGreenColor,}}}
                        onClick={() => dispatch(fetchDeleteSale({ saleId:id , offset:page*rowsPerPage , limit:rowsPerPage,  month: selectedMonth, year: yearFilter.toString(), searchTerm: searchFilter})) }>
                        <Typography variant="h5">Eliminar</Typography>
                    </Button>
                </Box>
            </DialogContent>
        </DialogContent>
    </Dialog>
    );
}