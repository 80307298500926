import { createSlice } from "@reduxjs/toolkit";
import { requestPending } from "../../../constants/constants";

const namespace = 'removePurchaseDialog'
const initialState = {
    isOpen:false,
    id:0,
    status: requestPending
}

export const removePurchaseDialog = createSlice({
    name : namespace,
    initialState : initialState,
    reducers:{
        openRemoveDialog: (state,action) => {
            state.id = action.payload;
            state.isOpen = true;
        },
        closeRemoveDialog:(state) => {
            state.isOpen = false;
        },
    },
})

export const removePurchaseDialogReducer = removePurchaseDialog.reducer;
export const { openRemoveDialog, closeRemoveDialog } = removePurchaseDialog.actions;