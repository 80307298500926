import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import Box from '@mui/material/Box';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import './App.css';
import { lightGrayColor } from './app/constants/constants';
import NavBar from './app/core/layout/navBar';
import SideBar from './app/core/layout/sideBar';
import Clients from './app/views/clients/clients';
import Credentials from './app/views/credentials/credentials';
import Home from './app/views/home/home';
import Login from './app/views/login/login';
import Projects from './app/views/projects/projects';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from './app/core/loading/spinner';
import Resources from './app/views/resources/resources';
import Sales from './app/views/sales/sales';
import { useAppSelector } from './app/core/state/hooks';
import Purchases from './app/views/purchases/purchases';
import TasksTagsMaster from './app/views/tasksTagsMaster/tasksTagsMaster';


export default function ResponsiveDrawer() {
  const { left } = useAppSelector((state) => state.sideBar);
  return <Box display={"flex"} flex={1} flexDirection={"column"}
    sx={{ background: lightGrayColor }} height="100%"    >
    <Spinner></Spinner>
    <UnauthenticatedTemplate>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </BrowserRouter>
    </UnauthenticatedTemplate>
    <AuthenticatedTemplate>
      <BrowserRouter>
        <ToastContainer
          theme='dark'
          position= "bottom-center"
          autoClose= {5000}
          hideProgressBar = {true}
          closeOnClick = {true}
          pauseOnHover= {false}
          draggable= {false}
        />
        <NavBar />
        <Box
          p={1.5}
          display={"flex"} flex={1} flexDirection={"row"} sx={{ flexGrow: 1, pl:left ? 14 : 1.5 }} >
          <SideBar />
          <Routes>
            <Route path="/home" element={<Home />} />
            <Route path="/credentials" element={<Credentials />} />
            <Route path="/projects" element={<Projects/>}/>
            <Route path="*" element={<Navigate to="/home" />} />
            <Route path="/clients" element={<Clients />} />
            <Route path="/resources" element={<Resources />}/>
            <Route path="/sales" element={<Sales />}/>
            <Route path="/purchases" element={<Purchases />}/>
            <Route path="/tasks&tags" element={<TasksTagsMaster />} />
          </Routes>
        </Box>
      </BrowserRouter>
    </AuthenticatedTemplate>
  </Box>
}