import React, { useEffect } from 'react';
import './resource.css'
import { Visibility, Paid, LastPage, FirstPage, KeyboardArrowRight, KeyboardArrowLeft,Search  } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles'; 
import { useAppDispatch, useAppSelector } from "../../core/state/hooks";
import { Box, Card, Table, TableBody, TableCell, TableRow, Typography, TextField,
        MenuItem, Select, TableContainer, IconButton, Button, TablePagination } from '@mui/material';
import {
    whiteColor,
    greenColor,
    darkGrayColor,
    areaList,
    admin,
    emptyString,
    nonSubArea,
    nonCompanyRole,
    superAdmin,
    noneValue,
    allAreas
} from '../../constants/constants';
import { fetchPaginatedResources, selectSearchFilter, setPage, setRowsPerPage, selectAreaFilter } from '../../core/state/resources/resourceSlice';
import defaultUserImage from './../../../assets/icons/default-profile-icon.png';
import { ReactComponent as SquareDeveloper } from './../../../assets/icons/SquareDeveloper.svg'
import { ReactComponent as SquareConsulting } from './../../../assets/icons/SquareConsulting.svg'
import { ReactComponent as SquareMarketing } from './../../../assets/icons/squareMarketing.svg'
import { ReactComponent as SquareRRHH } from './../../../assets/icons/squareRRHH.svg'
import { ReactComponent as SquareComercial } from './../../../assets/icons/squareComercial.svg'
import { ReactComponent as CirculeAdmin } from './../../../assets/icons/circuleAdmin.svg'
import { ReactComponent as CirculeDirection } from './../../../assets/icons/circuleDirection.svg'
import { openSaveResourceDialog } from './../../core/state/dialogs/saveResourceDialogSlice'
import { openSaveBonusDialog } from './../../core/state/dialogs/saveBonusDialogSlice'
import SaveResourceDialog from './saveResourceDialog';
import SaveBonusDialog from './saveBonusDialog';

export default function Resources(){
    const dispatch = useAppDispatch();
    const { user } = useAppSelector((state) => state.user);
    const { selectedDate } = useAppSelector((state) => state.date);
    const [searchterm, setSearchterm] = React.useState("");
    const { resources, selectedArea, resourcesCount, searchFilter, rowsPerPage, page} = useAppSelector((state) => state.resources);

    
    useEffect(() => {
      var offset = page*rowsPerPage;
      dispatch(fetchPaginatedResources({offset: offset,limit: rowsPerPage,area: selectedArea, searchTerm: searchFilter}))
    },[rowsPerPage, page, selectedArea, searchFilter, dispatch])
    
    const isEmpty = (t : string) : boolean => (t === emptyString)

    const controlHasFilter = () => {
      if(!isEmpty(searchFilter) || selectedArea !== allAreas){
          return emptyString;
      }
      return noneValue;
  }

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        dispatch(setPage(newPage));
    };

    const onClickCleanFilter = () => {
      setSearchterm(emptyString);
      dispatch(selectSearchFilter(emptyString))
      dispatch(selectAreaFilter(allAreas));
        dispatch(setPage(0));
  }

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
        dispatch(setPage(0));
    };
    
        const chargeGraphicArea = (name : string = emptyString) => {
          let res;
          switch (name) {
            case areaList[1]:
              res =  <><SquareDeveloper /> {" " + name}</>
              break;
            case areaList[2]:
              res =  <><SquareConsulting /> {" " + name}</>
              break;
            case areaList[3]:
              res =  <><SquareComercial /> {" " + name}</>
              break;
            case areaList[4]:
              res =  <><SquareRRHH /> {" " + name}</>
              break;
            case areaList[5]:
              res =  <><CirculeAdmin /> {" " + name}</>
              break;
            case areaList[6]:
              res =  <><SquareMarketing /> {" " + name}</>
              break;
            case areaList[7]:
              res =  <><CirculeDirection /> {" " + name}</>
              break;
            default:
              res = <></>;
              break;
          }
          return res;
        }
    
    return (
        <Box component="main" display={"flex"} flex={1} flexDirection="column"  >
            <Box display={"flex"} flexDirection="row" borderBottom={2} borderColor='rgba(35, 31, 32, 0.1)'  pb={2}  alignItems="center">
                <Typography variant='h5' >Recursos</Typography>
                <Box display={"flex"} flex={0.05}/>
                <TextField
                  value={searchterm}
                  onChange={(event)=>{
                    setSearchterm(event.target.value);
                  }}
                  sx={{backgroundColor:whiteColor,boxShadow:"2px 2px 5px rgba(35, 31, 32, 0.1)",borderRadius:5,color:greenColor,mx:2}}
                />
                <IconButton sx={{color:whiteColor,backgroundColor:greenColor, mr:2}}  onClick={() => {
                    dispatch(selectSearchFilter(searchterm));
                    dispatch(setPage(0));
                }}> 
                  <Search/>
                </IconButton>
                <Button onClick={onClickCleanFilter} sx={{color:"#918F8F",border:2,borderColor:"#918F8F",display:controlHasFilter}}>
                        Borrar filtros
                </Button>
                <Box flex={1}/>
                <Select
                    label="Area"
                    value={selectedArea}
                    onChange={(event)=>{dispatch(selectAreaFilter(event.target.value)); dispatch(setPage(0))}}
                    sx={{flex:0.6,mr:1,borderRadius:4,background:whiteColor,color:darkGrayColor}}>
                    { areaList.map((area)=> <MenuItem value={area} key={area}> {area} </MenuItem>) }
                </Select>
                <Box flex={0.1}/>
            </Box>
            
           <Card  sx={{mt:2, borderRadius:12.5,bgcolor:whiteColor, width:'100%'}}>
                <TableContainer >
                    <Table sx={{ minWidth: 500, }} aria-label="custom pagination table">
                        <TableBody >
                            <TableRow><TableCell colSpan={7}></TableCell></TableRow>
                            {
                              resources.map((resource) => (
                                <TableRow key={resource.id}>
                                    <TableCell style={{ width: 50, paddingLeft: 30 }}  component="th" scope="row">
                                        <img className='w-35 border-r-50per' src={ resource.image ? `data:image/jpeg;base64,${resource.image}` : defaultUserImage} alt=''></img>
                                    </TableCell>
                                    <TableCell style={{width: 250, minWidth: 150, }} >
                                      <Typography variant="h6">
                                        {resource.name + " " + resource.lastName}
                                      </Typography>
                                    </TableCell>
                                    <TableCell style={{width: "15%" , minWidth: 100,fontSize:17}} align="left">
                                        {resource.companyRole ? resource.companyRole : nonCompanyRole}
                                    </TableCell>

                                    <TableCell style={{width: "20%", minWidth: 120,fontSize:17}} align="left">
                                      {
                                        chargeGraphicArea(resource.area)
                                      }
                                    </TableCell>

                                    <TableCell style={{width: "20%", minWidth: 150,fontSize:17}} align="left">
                                        {resource.subArea ? resource.subArea : nonSubArea}
                                    </TableCell>

                                    <TableCell style={{width: 250, minWidth: 100,fontSize:17}} align="left">
                                      {resource.hours !== null && resource.hours !== 0? resource.hours+":00" : "Hora no disponible"}
                                    </TableCell>
                                    <TableCell style={{width: 250, textAlign: "end", paddingRight: 30,}} >
                                      {
                                        (user.appRoleId === admin || user.appRoleId === superAdmin) ? <IconButton onClick={() => {
                                          dispatch(openSaveBonusDialog({resource: resource, data : selectedDate}))
                                        }} children={<Paid />}/>:<></>
                                      }
                                      {
                                        (user.appRoleId === admin || user.appRoleId === superAdmin) ? <IconButton onClick={ () =>
                                          dispatch(openSaveResourceDialog({resource: resource, data : selectedDate}))
                                        } children={<Visibility />} />:<></>
                                      }
                                    </TableCell>
                            </TableRow>
                            )) }
                        </TableBody>
                    </Table>
                    <Table>
                      <TableBody>
                        <TableRow sx={{display:'flex',flexDirection: 'row-reverse', pr:5}}>
                            <TablePagination sx={{ border: 0,p:0}}
                            rowsPerPageOptions={[6, 12, 18, 24, 30, { label: 'All', value: resourcesCount }]}
                            colSpan={6}
                            count={resourcesCount}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: {
                                'aria-label': 'rows per page',
                                },
                                native: true,
                              }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions} />
                        </TableRow>
                      </TableBody>
                    </Table>
                </TableContainer>
            </Card>
            {
              user && (user.appRoleId === admin || user.appRoleId === superAdmin) ? 
              <Box>
                <SaveResourceDialog />
                <SaveBonusDialog  />
              </Box> : <></>

            }
        </Box>
      );
}





interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (
      event: React.MouseEvent<HTMLButtonElement>,
      newPage: number,
    ) => void;
  }
  
  function TablePaginationActions(props: TablePaginationActionsProps) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPage /> : <LastPage />}
        </IconButton>
      </Box>
      );
  }