import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { projectStates, yearList } from "../../../constants/constants";
import {deleteProject, deleteProjectStage, getProjectsFromClients} from "../../http/httpClient";
import { Client } from "../../models/clientModel";
import { requestPending, requestLoading, requestSuccessful, requestMissed } from "../../../constants/constants";
import { closeRemoveDialog } from "../dialogs/removeProjectDialogSlice";
import {closeRemoveStageDialog} from "../dialogs/removeProjectStageDialogSlice";

const namespace = "projects"

export const fetchProjectsFromClients = createAsyncThunk(
    `${namespace}/fetchProjectsFromClients`,
    async () => {
        return getProjectsFromClients();
    }
)

export const fetchDeleteProject = createAsyncThunk(
    `${namespace}/fetchDeleteProject`,
    async (projectId:number, thunkAPI) => {
        await deleteProject(projectId);
    }
);

export const fetchDeleteProjectStage = createAsyncThunk(
    `${namespace}/fetchDeleteProjectStage`,
    async (stageId:number, thunkAPI) => {
        await deleteProjectStage(stageId);
        thunkAPI.dispatch(closeRemoveStageDialog());
    }
);

export const projectsSlice = createSlice({
    name:`${namespace}`,
    initialState:{
        projectsByClient: new Array<Client>() ,
        status: requestPending,
        nameFilter: '',
        clientFilter: 0,
        expandedNodes: new Array<string>(),
        stateFilter:projectStates[1],
        yearFilter: yearList[0],
    },
    reducers:{
        selectNameFilter: (state,action)=>{
            state.nameFilter = action.payload;
        },
        selectClientFilter: (state,action)=>{
            state.clientFilter = action.payload;
        },
        setExpandedNodes: (state,action) => {
            state.expandedNodes = action.payload;
        },
        expandNode: (state,action) => {
            state.expandedNodes.push(action.payload.toString());
        },
        selectStateFilter:(state,action) =>{
            state.stateFilter = action.payload
        },
        selectYearFilter:(state,action) =>{
            state.yearFilter = action.payload
        },
    },
    extraReducers:(builder) => {
        builder.addCase(fetchProjectsFromClients.pending, (state) =>{
            state.status = requestLoading;
        });
        builder.addCase(fetchProjectsFromClients.fulfilled, (state,action) =>{
            state.projectsByClient = action.payload;
            state.status = requestSuccessful;
        });
        builder.addCase(fetchProjectsFromClients.rejected, (state) => {
            state.status = requestMissed;
        }); 
    },
})
export const {selectNameFilter,selectClientFilter,setExpandedNodes,expandNode,selectStateFilter
    ,selectYearFilter} = projectsSlice.actions;

export const projectsReducer =  projectsSlice.reducer;


