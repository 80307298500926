import {Resource} from "./resourceModel";
import {ProjectRole} from "./projectRoleModel";

export class StageResource{
    public stageResourceId : number = 0;
    public ProjectStageId : number = 0;
    public weeklyHours : number = 0;
    public price : number = 0;
    public projectRoleId : number = 0;
    public resourceId : number = 0;
    public projectRole : ProjectRole | undefined;
    public resource: Resource | undefined;
}