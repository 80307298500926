import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, MenuItem, Select, 
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../core/state/hooks";
import {
    closeEditDialog,
    setClientProjects,
    setClientId,
    setConcept,
    setCurrency,
    setDocument,
    setNetOrigin,
    setNumber,
    setProject,
    setProjectId,
    setTax,
    setDateOfPayment,
    setDateSale,
    updateSale,
    setProjectServiceId,
} from "../../core/state/dialogs/editSaleDialogSlice";
import { darkGrayColor, darkGreenColor,  grayColor, greenColor, lightGrayColor,  TypeOfMoneys, whiteColor } from "../../constants/constants";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { fetchProjectsFromClients, fetchProjectServices  } from "../../core/state/dialogs/editSaleDialogSlice";
import { useEffect } from "react";
import { Client } from "../../core/models/clientModel";
import { Project } from "../../core/models/projectModel";
import React from "react";
interface Column {
    id: 'Borrar' | 'Fecha' | 'Cliente' | 'Documento' | 'NDeDoc' | 'Concepto' | 'Servicio' |
    'CentroDeCosto' | 'Moneda' | 'NetoOrigen' | 'IVA' | 'Total' | 'FDeCobro';
    label: string;
    minWidth?: number;
}

const columns: readonly Column[] = [
    { id: 'Borrar', label: '', minWidth: 20 },
    { id: 'Fecha', label: 'Fecha', minWidth: 200 },
    { id: 'Cliente', label: 'Cliente', minWidth: 150 },
    { id: 'CentroDeCosto', label: 'Centro de costo', minWidth: 250, },
    { id: 'Documento', label: 'Documento', minWidth: 150 },
    { id: 'NDeDoc', label: 'N. de Doc', minWidth: 150, },
    { id: 'Concepto', label: 'Concepto', minWidth: 250, },
    { id: 'Servicio', label: 'Servicio', minWidth: 250, },
    { id: 'Moneda', label: 'Moneda', minWidth: 125, },
    { id: 'NetoOrigen', label: 'Neto Origen', minWidth: 150, },
    { id: 'IVA', label: 'IVA', minWidth: 150, },
    { id: 'Total', label: 'Total', minWidth: 150, },
    { id: 'FDeCobro', label: 'F. de Cobro', minWidth: 200, },
];

const style = {backgroundColor:lightGrayColor,borderRadius:4, boxShadow:"2px 2px 5px rgba(35, 31, 32, 0.1)", border:2, borderColor: greenColor}

export default function EditSaleDialog() {
    const dispatch = useAppDispatch();
    const { isOpen: dialogIsOpen, selectedData, clientList, projectServices} = useAppSelector((state) => state.editSaleDialog);
    const { page, rowsPerPage, selectedMonth, yearFilter, searchFilter} = useAppSelector((state) => state.sales);

    const [disabled, setDisabled] = React.useState(false);

    useEffect(() => {
        dispatch(fetchProjectsFromClients());
        dispatch(fetchProjectServices());
    },[dispatch]);


    const handleOnClickSaveEdit = () => {
        dispatch(updateSale({offset: rowsPerPage*page, limit: rowsPerPage, month: selectedMonth, year: yearFilter.toString(), searchTerm: searchFilter}))
    }

    return (
        <Dialog open={dialogIsOpen} onClose={() => { dispatch(closeEditDialog()); }} maxWidth="lg">
            <DialogTitle sx={{fontSize:"25px! important",color:darkGrayColor,pl:3,pt:3}}>
                Edición de Ventas
            </DialogTitle>
            <DialogContent>
                <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow >
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id} align="center"
                                            style={{ minWidth: column.minWidth }} sx={{ borderBottom: 1, borderColor: grayColor }}
                                        >
                                            <Typography variant='h6'>{column.label}</Typography>
                                        </TableCell>
                                    ))
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody >
                                <TableRow hover role="checkbox" tabIndex={-1} >
                                    <TableCell align="right" sx={{ p: 0 }} />
                                    <TableCell align="center">
                                        <FormControl>
                                            <LocalizationProvider dateAdapter={AdapterDateFns} >
                                                <Box sx={{backgroundColor:lightGrayColor,borderRadius:9,border:2, borderColor: greenColor, boxShadow:"2px 2px 5px rgba(35, 31, 32, 0.1)"}}>
                                                    <DatePicker
                                                        disableOpenPicker={false}
                                                        inputFormat="dd/MM/yyyy"
                                                        value={selectedData.dateSale}
                                                        onChange={(date) => { 
                                                            dispatch(setDateSale(date));
                                                        }}
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />
                                                </Box>
                                            </LocalizationProvider>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell align="center">
                                        <FormControl sx={{flex:0.37}} fullWidth>
                                                <Select
                                                    id="client-select"
                                                    label="Client"
                                                    value={selectedData.clientId}
                                                    onChange={(event) => {
                                                        const projects = clientList.find(c => c.id === event.target.value)?.projects;
                                                        dispatch(setClientProjects(projects ? projects : new Array<Project>()));
                                                        dispatch(setClientId(event.target.value));
                                                        setDisabled(true)
                                                    }}
                                                    sx={style}
                                                >
                                                    {
                                                        clientList && clientList.map((client:Client) => <MenuItem value={client.id} key={client.id}>{client.name}</MenuItem>)
                                                    }
                                                </Select>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell align="center">
                                        <FormControl sx={{flex:0.37}} fullWidth>
                                                <Select
                                                    id="code-select"
                                                    label="code"
                                                    value={selectedData.projectId}
                                                    onChange={(event) => {
                                                        const project = selectedData.clientProjects.find(x => x.id === +event.target.value);
                                                        dispatch(setProject(project));
                                                        dispatch(setProjectId(project?.id));
                                                        dispatch(setProjectServiceId(project?.serviceId));
                                                        setDisabled(false)
                                                    }}
                                                    sx={style}
                                                >
                                                    {
                                                        (selectedData.clientProjects.length > 0) && (selectedData.clientProjects.map((project:Project) =>
                                                            <MenuItem value={project.id} key={project.id}>{project.code}</MenuItem>))
                                                    }
                                                </Select>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell align="center">
                                        <FormControl sx={{flex:0.27}}>
                                                <TextField sx={style} id="document"
                                                value={selectedData.document}
                                                onChange={(event) => {
                                                    dispatch(setDocument(event.target.value));
                                                }}
                                                />
                                        </FormControl>
                                    </TableCell>
                                    <TableCell align="center">
                                        <FormControl sx={{flex:0.27}}>
                                                <TextField sx={style} id="number"
                                                value={selectedData.number}
                                                onChange={(event) => {
                                                    dispatch(setNumber(event.target.value));
                                                }}
                                                />
                                        </FormControl>
                                    </TableCell>
                                    <TableCell align="center">
                                        <FormControl sx={{flex:0.27}}>
                                                <TextField sx={style} id="concept"
                                                value={selectedData.concept}
                                                onChange={(event) => {
                                                    dispatch(setConcept(event.target.value));
                                                }}
                                                />
                                        </FormControl>
                                    </TableCell>
                                    <TableCell align="center">
                                        <FormControl sx={{flex:0.37}} fullWidth>
                                            <Select
                                                id="service-select"
                                                label="Servicio"
                                                value={selectedData.serviceId}
                                                disabled
                                                sx={style}
                                            >
                                                { 
                                                    (projectServices.length > 0) && projectServices.map((service) => <MenuItem value={service.id} key={service.id}>{service.name}</MenuItem>)
                                                }
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell align="center">
                                        <FormControl sx={{flex:0.37}} fullWidth>
                                            <Select
                                                id="money-select"
                                                label="Money"
                                                sx={style}
                                                value={selectedData.currency}
                                                onChange={(event) => {
                                                    dispatch(setCurrency(event.target.value));
                                                }}
                                            >
                                                {
                                                    TypeOfMoneys.map((money) => <MenuItem value={money} key={money}>{money} </MenuItem>)
                                                }
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell align="center">
                                    <FormControl sx={{flex:0.27}}>
                                        <TextField 
                                            id="netOrigin"
                                            type="number"
                                            inputProps={{style: { textAlign: 'center' }}} 
                                            value={selectedData.netOrigin}
                                            sx={style} 
                                            onChange={(event) => { dispatch(setNetOrigin(event.target.value)); }}
                                        />
                                        </FormControl>
                                    </TableCell>
                                    <TableCell align="center">
                                        <FormControl sx={{flex:0.27}}>
                                        <TextField 
                                            id="tax"
                                            type="number"
                                            inputProps={{style: { textAlign: 'center' }}} 
                                            sx={style} 
                                            value={selectedData.tax}
                                            onChange={(event) => { dispatch(setTax(event.target.value)); }}
                                        />
                                        </FormControl>
                                    </TableCell>
                                    <TableCell align="center">
                                        <FormControl sx={{flex:0.27}}>
                                            <TextField inputProps={{style: { textAlign: 'center' }}}  id="total" sx={style} value={isNaN(selectedData.total) ?  0 : selectedData.total} disabled/>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell align="center">
                                        <FormControl sx={{flex:0.27}}>
                                            <TextField sx={style} id="dateOfPayment" value={selectedData.dateOfPayment}
                                                onChange={(event) => { dispatch(setDateOfPayment(event.target.value)); }} />
                                        </FormControl>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions> 
                <Button 
                    sx={{backgroundColor:greenColor,color:whiteColor,px:3,'&:hover': {backgroundColor: darkGreenColor,}}}
                    disabled={disabled}
                    onClick={handleOnClickSaveEdit}
                    >
                        Guardar
                </Button> 
            </DialogActions>
        </Dialog>)
}