import { createSlice } from "@reduxjs/toolkit";
import { requestPending } from "../../../constants/constants";

const namespace = 'removeTaskTagDialog'
const initialState = {
    isOpen: false,
    TaskId: 0,
    TagId: 0,
    status: requestPending,
    dialogType: '' // New field to track the dialog type
}

export const removeTaskTagDialog = createSlice({
    name: namespace,
    initialState: initialState,
    reducers: {
        openRemoveTaskDialog: (state, action) => {
            state.TaskId = action.payload;
            state.dialogType = 'task';
            state.isOpen = true;
        },
        openRemoveTagDialog: (state, action) => {
            state.TagId = action.payload;
            state.dialogType = 'tag';
            state.isOpen = true;
        },
        closeRemoveDialog: (state) => {
            state.isOpen = false;
            state.dialogType = '';
        },
    },
})

export const removeTaskTagDialogReducer = removeTaskTagDialog.reducer;
export const { openRemoveTaskDialog, openRemoveTagDialog, closeRemoveDialog } = removeTaskTagDialog.actions;