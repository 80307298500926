import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { requestPending } from "../../../constants/constants";
import { RecordDTO } from "../../models/recordModelDTO";


const namespace = 'removeRecordDialog'

interface RemoveRecordDialogState {
    isOpen: boolean;
    recordToDelete: RecordDTO;
    status: string;
}

const initialState: RemoveRecordDialogState = {
    isOpen: false,
    recordToDelete: new RecordDTO,
    status: requestPending,
}

export const removeRecordDialog = createSlice({
    name : namespace,
    initialState : initialState,
    reducers:{
        setRecordToDelete: (state, action) =>{
            state.recordToDelete = action.payload;
        },
        openRemoveDialog: (state,action) => {
            state.isOpen = true;
        },
        closeRemoveDialog:(state) => {
            state.isOpen = false;
        },
    },
})

export const removeRecordDialogReducer = removeRecordDialog.reducer;
export const { openRemoveDialog, setRecordToDelete, closeRemoveDialog } = removeRecordDialog.actions;