import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { createRecordFail, createRecordSuccess, editRecordFail, editRecordSuccess, errorSave, exceddingRecordsHoursMessage, invalidDescriptionMessage, requestLoading, requestMissed, requestPending, requestSuccessful, successSave } from "../../../constants/constants";
import { getProjectTags, getProjectTasks, postSaveRecord, updateRecord } from "../../http/httpClient";
import { RecordDTO } from "../../models/recordModelDTO";
import { Tag } from "../../models/tagModel";
import { task } from "../../models/taskModel";
import { fetchWeeklyRecords } from "../dailyTracker/dailyTrackerSlice";
import { fetchMonthlyRecords } from "../monthlyTracker/monthlyTrackerSlice";
import { RootState } from "../store";


const namespace = 'saveRecordDialog';
const initialState = {
    status: requestPending,
    isOpen: false,
    tasks: new Array<task>(),
    tasksStatus: requestPending,
    tags: new Array<Tag>(),
    tagsStatus: requestPending,
    selectedData:{
        id: 0,
        date: new Date(),
        timeQty:'00:00:00',
        ticketId: '',
        description: '',
        TaskId: 0,
        ProjectId: 0,
        StageId: 0,
        tagRecords: new Array<{tagId:number}>(),
        timeSelection: new Date(0,0,0,0,0)
    },
};

export const fetchProjectTasks = createAsyncThunk(
    `${namespace}/fetchProjectTasks`,
    async (idProject: number) => await getProjectTasks(idProject,false),
);
export const fetchProjectTags = createAsyncThunk(
    `${namespace}/fetchProjectTags`,
    async (idProject: number) => await getProjectTags(idProject,false),
);
export const saveRecord = createAsyncThunk(
    `${namespace}/saveRecord`,
    async (ResourceId:number,thunkAPI) => {
        const {saveRecordDialog:state} = thunkAPI.getState() as RootState;
        const recordData = {
            ...state.selectedData,
            ResourceId,
            timeStamp: new Date(),
        }
        const isNewRecord = recordData.id === successSave;
        let boolNewRecord = successSave;
        if(isNewRecord){
            boolNewRecord = await postSaveRecord(recordData);
            boolNewRecord === successSave ? toast.success(createRecordSuccess) : (boolNewRecord === errorSave) ? toast.error(createRecordFail) : toast.error(exceddingRecordsHoursMessage);
        }else{
            boolNewRecord = await updateRecord(recordData);
            boolNewRecord === successSave ? toast.success(editRecordSuccess) : (boolNewRecord === errorSave) ? toast.error(editRecordFail) : toast.error(exceddingRecordsHoursMessage);
        }
        thunkAPI.dispatch(fetchWeeklyRecords(ResourceId));
        thunkAPI.dispatch(fetchMonthlyRecords(ResourceId));
        return boolNewRecord;
    },
);

export const saveRecordDialog = createSlice({
    name: namespace,
    initialState: initialState,
    reducers: {
        openSaveRecordDialog: (state,action) => {
            const initData = initialState.selectedData;
            state.selectedData.TaskId = initData.TaskId;
            state.selectedData.ProjectId = initData.ProjectId;
            state.selectedData.ticketId = initData.ticketId;
            state.selectedData.StageId = initData.StageId;
            state.selectedData.date = action.payload.date;
            state.selectedData.description = initData.description;
            state.selectedData.id = initData.id;
            state.selectedData.tagRecords = initData.tagRecords;
            state.selectedData.timeQty = initData.timeQty;
            state.selectedData.timeSelection = initData.timeSelection;
            if(action.payload.record){
                const record  = action.payload.record as RecordDTO;
                state.selectedData.id = record.id;
                state.selectedData.TaskId = record.taskId;
                state.selectedData.ProjectId = record.projectId;
                state.selectedData.StageId = record.stageId;
                state.selectedData.ticketId = record.ticketId;

                state.selectedData.description = record.description;
                state.selectedData.tagRecords = record.tagRecords.map(tagRecord => { return { tagId: tagRecord.id } });
                state.selectedData.timeQty = record.timeQty.toString();
                const partTimeQty = state.selectedData.timeQty.split(':')
                state.selectedData.timeSelection.setHours(parseInt(partTimeQty[0]))
                state.selectedData.timeSelection.setMinutes(parseInt(partTimeQty[1]))
            };
            state.isOpen = true;
        },
        closeSaveRecordDialog: (state)=> {
            state.isOpen = false;
        },
        selectProject: (state, action) => {
            state.selectedData.ProjectId = action.payload;
            state.selectedData.StageId = 0;
            state.selectedData.TaskId = 0;
            state.selectedData.tagRecords = new Array<{tagId:number}>();
        },
        selectStage: (state, action) => {
            state.selectedData.StageId = action.payload;
        },
        selectTask: (state, action) => {
            state.selectedData.TaskId = action.payload;
        },
        selectTags: (state, action) => {
            state.selectedData.tagRecords = action.payload.map((tagId:number) => {return {tagId:tagId}});
        },
        setDescription: (state, action) => {
            if (action.payload.length <= 256){
                state.selectedData.description = action.payload;                
            } else {
                toast.error(invalidDescriptionMessage);
            }
        },
        setTicketId: (state,action) =>{
            state.selectedData.ticketId = action.payload;
        },
        setDate: (state,action) =>{
            state.selectedData.date = action.payload;
        },
        setTimeQty: (state,action) =>{
            if (action.payload instanceof Date ) {
                state.selectedData.timeQty = (action.payload.getHours() < 10? "0" + action.payload.getHours().toString() : + action.payload.getHours().toString()) + ':' + 
                (action.payload.getMinutes() < 10? "0" + action.payload.getMinutes().toString() : action.payload.getMinutes().toString());
                state.selectedData.timeSelection = action.payload;
            } else {
                state.selectedData.timeQty = action.payload;
            }
        },

    },
    extraReducers: (builder) => {
        builder.addCase(fetchProjectTasks.pending, (state) => {
            state.tasksStatus = requestLoading;
        }).addCase(fetchProjectTasks.fulfilled, (state, action) => {
            state.tasks = action.payload;
            state.tasksStatus = requestSuccessful;
        }).addCase(fetchProjectTasks.rejected, (state) => {
            state.tasksStatus = requestMissed;
        });

        builder.addCase(fetchProjectTags.pending, (state) => {
            state.tagsStatus = requestLoading;
        }).addCase(fetchProjectTags.fulfilled, (state, action) => {
            state.tags = action.payload;
            state.tagsStatus = requestSuccessful;
        }).addCase(fetchProjectTags.rejected, (state) => {
            state.tagsStatus = requestMissed;
        });

        builder.addCase(saveRecord.pending, (state) => {
            state.status = requestLoading;
        }).addCase(saveRecord.fulfilled, (state) => {
            state.status = requestSuccessful;
        }).addCase(saveRecord.rejected, (state) => {
            state.status = requestMissed;
        });
    },
})

export const saveRecordDialogReducer = saveRecordDialog.reducer;
export const {openSaveRecordDialog,closeSaveRecordDialog, selectStage, selectProject, selectTask, selectTags, setDate,
                setTimeQty, setDescription, setTicketId } = saveRecordDialog.actions;