import { DatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TimePicker from '@mui/lab/TimePicker';
import { Box, Button, Dialog, DialogContent, FormControl, FormLabel, ListSubheader, MenuItem, Select, TextField, Typography } from '@mui/material';
import frLocale from 'date-fns/locale/fr';
import { useEffect, useState } from "react";
import {
    darkGreenColor,
    greenColor,
    lightGrayColor,
    redColor,
    supportTask,
    tagColors,
    whiteColor
} from '../../../constants/constants';
import { Project } from '../../../core/models/projectModel';
import { Tag } from '../../../core/models/tagModel';
import {
    closeSaveRecordDialog,
    fetchProjectTags,
    fetchProjectTasks,
    saveRecord,
    selectProject,
    selectStage,
    selectTags,
    selectTask,
    setDate,
    setDescription,
    setTicketId,
    setTimeQty
} from '../../../core/state/dialogs/saveRecordDialogSlice';
import { useAppDispatch, useAppSelector } from '../../../core/state/hooks';


export default function SaveRecordDialog(props : any) {
    const dispatch = useAppDispatch();
    const [errors, setErrors] = useState({project: false, stage: false, task: false, hours: false, supportId: false});
    const { isOpen: dialogIsOpen, tasks: projectTasks, tags: projectTags, selectedData} = useAppSelector((state) => state.saveRecordDialog);
    const { user, projectsByClient} = useAppSelector((state) => state.user);
    const handleSelectProject = (event:any) => {
        if(event.target.value){
            dispatch(selectProject(event.target.value));
        }
    }
    const handleSelectStage = (event:any) => {
        if(event.target.value){
            dispatch(selectStage(event.target.value));
        }
    }
    const getTaskNameById = (taskId: number) => {
        const task = projectTasks.find(t => t.id === taskId);
        return task ? task.name : null;
    }

    const setStyleSuccess = () => {
        setErrors({project: false, stage: false, task: false, hours: false, supportId: false});
    }

    const validateRecord = () => {

        let newErrors = {...errors};
        let isValid = true;

        const validateField = (condition: boolean, fieldName: keyof typeof newErrors) => {
            if (condition) {
                newErrors[fieldName] = true;
                isValid = false;
            } else {
                newErrors[fieldName] = false;
            }
        };

        validateField(!selectedData.ProjectId || selectedData.ProjectId == 0, 'project');
        validateField(!selectedData.StageId || selectedData.StageId == 0, 'stage');
        validateField(!selectedData.TaskId || selectedData.TaskId == 0, 'task');
        validateField(selectedData.timeQty === "00:00" || selectedData.timeQty === "00:00:00" || !selectedData.timeQty, 'hours');
        validateField(selectedData.ticketId == '' && getTaskNameById(selectedData.TaskId) === supportTask, 'supportId')

        setErrors(newErrors);

        return isValid;
    }
    const saveNewRecord = () => {
        if (!validateRecord()) {
            return;
        }
        if (getTaskNameById(selectedData.TaskId) !== supportTask) {
            dispatch(setTicketId(''));
        }
        dispatch(saveRecord(user.id));
        dispatch(closeSaveRecordDialog());
    }

    useEffect(() => {
        if( selectedData.ProjectId !== 0){
            dispatch(fetchProjectTasks(selectedData.ProjectId));
            dispatch(fetchProjectTags(selectedData.ProjectId));
            dispatch(selectTags( new Array<{tag:Tag}>()));
        }
    }, [dispatch, selectedData.ProjectId]);

    return (
        <Dialog open={dialogIsOpen} fullWidth>
            <DialogContent sx={{display:"flex",flexDirection:"column"}}>
                <DialogContent sx={{display:"flex",flexDirection:"row"}}>
                    <Typography variant='h5' sx={{ borderBottom: 2, borderBottomColor: 'rgba(35, 31, 32, 0.1)', pb: 1, flex:1 }}>{props.isNew ? "Crear Registro": "Editar Registro"}</Typography>
                </DialogContent>
                <DialogContent sx={{display:"flex",flexDirection:"row"}}>
                    <FormControl>
                        <FormLabel>Proyecto (*)</FormLabel>
                        <Select
                            id="project-select"
                            label="Proyecto"
                            value={selectedData.ProjectId}
                            onChange={handleSelectProject}
                            sx={{width:250,mr:1,backgroundColor:lightGrayColor,borderRadius:4, border:2, borderColor: errors.project ? redColor : lightGrayColor}}
                        >
                            {projectsByClient && projectsByClient.map((client) => {
                                const clientProjects = [<ListSubheader key={client.id}> {client.name}</ListSubheader>];
                                client.projects && client.projects.map((project: Project) => {
                                    if(project.state === "OPEN"){
                                        clientProjects.push(<MenuItem value={project.id} key={project.id}> {project.name} </MenuItem>)
                                    }
                                })
                                return (clientProjects.length === 1 ? [] : clientProjects);
                            })}
                        </Select>
                    </FormControl>
                    <FormControl sx={{flex:1}}>
                        <FormLabel>Fecha</FormLabel>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Box sx={{backgroundColor:lightGrayColor,borderRadius:4}}>
                                <DatePicker
                                    inputFormat="dd/MM/yyyy"
                                    value={selectedData.date}
                                    onChange={(selectedDate) => { dispatch(setDate(selectedDate)) }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </Box>
                        </LocalizationProvider>
                    </FormControl>
                </DialogContent>
                <DialogContent sx={{display:"flex",flexDirection:"row"}}>
                    <FormControl>
                        <FormLabel>Etapa (*)</FormLabel>
                        <Select
                            id="stage-select"
                            label="Etapa"
                            value={selectedData.StageId}
                            onChange={handleSelectStage}
                            sx={{ width: 250, mr: 1, backgroundColor: lightGrayColor, borderRadius: 4, border:2, borderColor: errors.stage ? redColor : lightGrayColor}}
                        >
                            {projectsByClient &&
                                projectsByClient.map((client) =>
                                    client.projects &&
                                    client.projects.map((project) =>
                                        project.stages &&
                                        project.stages
                                            .filter((stage) => stage.projectId === selectedData.ProjectId)
                                            .sort((a, b) => a.number - b.number) // Sort stages by stage.number
                                            .map((stage) => (
                                                <MenuItem value={stage.id} key={stage.id}>
                                                    {stage.name}
                                                </MenuItem>
                                            ))
                                    )
                                )}
                        </Select>
                    </FormControl>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
                        <FormControl sx={{flex:1}}>
                            <FormLabel>Horas (*)</FormLabel>
                            <Box sx={{backgroundColor: "#F4F4F4", borderRadius:4, border:2, borderColor: errors.hours ? redColor : lightGrayColor}}>
                                <TimePicker
                                    ampm={false}
                                    value={selectedData.timeSelection}
                                    onChange={(event) => {dispatch(setTimeQty(event))}}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </Box>
                        </FormControl>
                    </LocalizationProvider>
                </DialogContent>
                <DialogContent sx={{display:"flex",flexDirection:"row"}}>
                    <FormControl>
                        <FormLabel>Tarea (*)</FormLabel>
                        <Select
                            id="task-select"
                            label="Tarea"
                            value={selectedData.TaskId}
                            onChange={(event) => { dispatch(selectTask(event.target.value)) }}
                            sx={{width:250,mr:1,backgroundColor:lightGrayColor,borderRadius:5, border:2, borderColor: errors.task ? redColor : lightGrayColor}}
                        >
                            {projectTasks && projectTasks.map((task) =>
                                <MenuItem value={task.id} key={task.id}>{task.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <FormControl sx={{flex:1}}>
                        <FormLabel>Tags</FormLabel>
                        <Select
                            id="tags-select"
                            label="Tags"
                            multiple={true}
                            value={selectedData.tagRecords.map((tag)=> tag.tagId)}
                            onChange={(event) => { dispatch(selectTags(event.target.value)) }}
                            sx={{backgroundColor:lightGrayColor,borderRadius:5}}
                            renderValue={(selected)=>
                                <Box  sx={{display:"flex",flexDirection:"row",flexWrap:"wrap",ml:-1.5}}>
                                    {selected.map((value,_index)=>(
                                        <Typography variant="subtitle1"  key={_index}
                                                    sx={{backgroundColor:tagColors[value % tagColors.length],borderRadius:5,color:whiteColor,mr:1,px:"3%",height:"100%"}}
                                        >{projectTags?.filter((tag)=> tag.id === value)[0]?.name}</Typography>
                                    ))}
                                </Box>
                            }>
                            {projectTags && projectTags.map((tag) =>
                                <MenuItem value={tag.id} key={tag.id}>{tag.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogContent sx={{display:"flex",flexDirection:"row"}}>
                    <FormControl fullWidth>
                        {
                            getTaskNameById(selectedData.TaskId) === supportTask &&
                            (
                                <>
                                    <FormLabel sx={{mb:1}}>ID Ticket de Soporte (*)</FormLabel>
                                    <TextField sx={{backgroundColor:lightGrayColor,borderRadius:5, border:2, borderColor: errors.supportId ? redColor : lightGrayColor}} id="ID Ticket de Soporte"
                                               value={selectedData.ticketId}
                                               onChange={(event) => { dispatch(setTicketId(event.target.value)); }} />
                                </>
                            )
                        }
                    </FormControl>
                </DialogContent>
                <DialogContent sx={{display:"flex",flexDirection:"row"}}>
                    <FormControl fullWidth>
                        <FormLabel sx={{mb:1}}>Descripción</FormLabel>
                        <TextField multiline rows={2} sx={{backgroundColor:lightGrayColor,borderRadius:5}} id="description"
                                   value={selectedData.description}
                                   onChange={(event) => { dispatch(setDescription(event.target.value)); }} />
                    </FormControl>
                </DialogContent>
                <DialogContent sx={{ display: 'flex', justifyContent: 'space-between'}}>
                    <Button
                        sx={{
                            backgroundColor: redColor,
                            color: whiteColor,
                            width: 120,
                            alignSelf: 'start',
                            '&:hover': { backgroundColor: darkGreenColor },
                        }}
                        onClick={() => {
                            dispatch(closeSaveRecordDialog());
                            setStyleSuccess();
                        }}
                    >
                        Cancelar
                    </Button>
                    <Button
                        sx={{
                            backgroundColor: greenColor,
                            color: whiteColor,
                            width: 120,
                            alignSelf: 'end',
                            '&:hover': { backgroundColor: darkGreenColor },
                        }}
                        onClick={saveNewRecord}
                    >
                        Guardar
                    </Button>
                </DialogContent>
            </DialogContent>
        </Dialog>
    )}