import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    getSubAreas,
    getProjectProcesses,
    getProjectTags,
    getProjectTasks,
    getResources,
    getRoles,
    getTags,
    getTasks,
    postSaveProject,
    updateProject,
    postSaveProjectStage, updateProjectStage, getProjectServices
} from "../../http/httpClient";
import { Client } from "../../models/clientModel";
import { Resource } from "../../models/resourceModel";
import { Role } from "../../models/roleModel";
import { Tag } from "../../models/tagModel";
import { task } from "../../models/taskModel";
import { RootState } from "../store";
import { toast } from "react-toastify";
import { Project } from "../../models/projectModel";
import { SubArea } from "../../models/subAreaModel";
import { Process } from "../../models/processModel";
import { fetchProjectsFromClients } from "../projects/projectSlice";
import {
    requestLoading, requestSuccessful, requestMissed, requestPending, invalidEndDateMessage,
    invalidNumberTypeMessage, invalidNameMessage, invalidStartDateMessage,
} from "../../../constants/constants";
import {ProjectStage} from "../../models/projectStageModel";
import {StageResource} from "../../models/stageResource";
import {projectService} from "../../models/projectServiceModel";

const namespace = 'saveProjectDialog';
const initialState = {
    projectId: 0,
    subAreas : new Array<SubArea>(),
    projectServices : new Array<projectService>(),
    states : [{name:"OPEN", id:1}, {name:"CLOSED", id:2}],
    clients: new Array<Client>(),
    tasks: new Array<task>(),
    tags: new Array<Tag>(),
    resources: new Array<Resource>(),
    roles: new Array<Role>(),
    processes: new Array<Process>(),
    processesStatus: requestPending,
    projectServicesStatus: requestPending,
    stagesStatus: requestPending,
    rolesStatus: requestPending,
    resourcesStatus: requestPending,
    subAreasStatus: requestPending,
    tasksStatus: requestPending,
    tagsStatus: requestPending,
    projectTasksStatus: requestPending,
    projectTagsStatus: requestPending,
    projectResourcesStatus: requestPending,
    stageResourcesStatus: requestPending,
    selectedData :{
        id: 0,
        projectResources: new Array<{projectRoleId: number, resourceId:number, weeklyHours: string}>(),
        state: "OPEN",
        name: "",
        subAreaId: 0,
        serviceId: 0,
        clientId: 0,
        processId: 0,
        startDate: new Date(),
        endDate: new Date(),
        code: "",
        weekHours: 0,
        projectTags: new Array<{tagId: number}>(),
        projectTasks: new Array<{taskId: number}>(),
        Stages: new Array<ProjectStage>(),
        stateId: 0,
        totalHour:'00:00:00',
        daysOfWork: 0,
    },
    selectedStageData: {
        index: 0,
        stage: new ProjectStage(),
    },
    isOpen: false,
    stageDialogisOpen: false,
    stageDialogisCreating: false,
};

export const fetchSubAreas = createAsyncThunk(
    `${namespace}/fetchSubAreas`,
   async () => await getSubAreas(false)
);

export const fetchProjectServices = createAsyncThunk(
    `${namespace}/fetchProjectServices`,
    async () => await getProjectServices(false)
);

export const fetchTasks = createAsyncThunk(
    `${namespace}/fetchTasks`,
    async () => await getTasks(false),
);

export const fetchProcesses = createAsyncThunk(
    `${namespace}/fetchProcesses`,
    async () => await getProjectProcesses(false),
);

export const fetchTags = createAsyncThunk(
    `${namespace}/fetchTags`,
    async () => await getTags(false),
);

export const fetchResources = createAsyncThunk(
    `${namespace}/fetchResources`,
    async () => await getResources(false),
);

export const fetchRoles = createAsyncThunk(
    `${namespace}/fetchRoles`,
    async () => await getRoles(false),
);

export const fetchProjectTasks = createAsyncThunk(
    `${namespace}/fetchProjectTasks`,
    async (idProject: number) => await getProjectTasks(idProject, false),
);

export const fetchProjectTags = createAsyncThunk(
    `${namespace}/fetchProjectTags`,
    async (idProject: number) => await getProjectTags(idProject,false),
);
export const saveProject = createAsyncThunk(
    `${namespace}/saveProject`,
    async (userId:number,thunkAPI) => {
        const {saveProjectDialog:state} = thunkAPI.getState() as RootState;
        var projectData = {
            ...state.selectedData,
            startDate:new Date(state.selectedData.startDate)
        }
        projectData.id === 0 ? await postSaveProject(projectData) : await updateProject(projectData);
        thunkAPI.dispatch(fetchProjectsFromClients());
    },
    
);

export const saveProjectStage = createAsyncThunk(
    `${namespace}/saveProjectStage`,
    async (_,thunkAPI) => {
        const {saveProjectDialog:state} = thunkAPI.getState() as RootState;
        var projectData = {
            ...state.selectedStageData.stage,
        }
        projectData.id === 0 ? await postSaveProjectStage(projectData) : await updateProjectStage(projectData);
        thunkAPI.dispatch(fetchProjectsFromClients());
    },
);

export const saveProjectDialog = createSlice({
    name: namespace,
    initialState: initialState,
    reducers: {
        openSaveProjectDialog: (state,action) => {
            state.selectedData = {...initialState.selectedData};
            state.clients = action.payload.clients;
            if(action.payload.project){
                const project  = action.payload.project as Project;
                state.selectedData.id = project.id;
                state.selectedData.processId = project.processId;
                state.selectedData.Stages = project.stages;
                state.selectedData.state = project.state as string;
                state.selectedData.stateId = state.states.find((state) => project.state === state.name)?.id as number
                state.selectedData.name = project.name as string;
                state.selectedData.subAreaId = action.payload.project.subAreaId;
                state.selectedData.serviceId = action.payload.project.serviceId;
                state.selectedData.totalHour = project.totalHour;
                state.selectedData.daysOfWork = project.daysOfWork;
                if(project.startDate != null){
                    state.selectedData.startDate = (new Date(project.startDate as Date));
                }
                if(project.endDate != null){
                    state.selectedData.endDate = (new Date(project.endDate as Date));
                }
                state.selectedData.clientId = action.payload.project.clientId as number;
                state.selectedData.code = project.code as string;
            };
            state.isOpen = true;
        },
        openSaveProjectStageDialogEdition: (state, action) => {
            state.stageDialogisOpen = true;
            state.stageDialogisCreating = false;
            state.selectedStageData.index = action.payload.stageIndex;
            state.selectedStageData.stage = action.payload.stage;
        },
        openSaveProjectStageDialogCreation: (state, action) => {
            state.stageDialogisOpen = true;
            state.stageDialogisCreating = true;
            state.selectedStageData.stage = action.payload;
        },
        closeSaveProjectStageDialog: (state) => {
            state.stageDialogisOpen = false;
        },
        closeSaveProjectDialog: (state)=> {
            state.selectedData = {...initialState.selectedData}
            state.isOpen = false;
        },
        setName: (state, action) => {
            if (action.payload.length <= 255){
                state.selectedData.name = action.payload;                
            } else {
                toast.error(invalidNameMessage);
            }
        },
        setStartDate: (state,action) =>{
            state.selectedData.startDate = action.payload;
        },
        setFinishDate: (state,action) =>{
            const timeStart = state.selectedData.startDate.getTime();
            if (action.payload.getTime() > timeStart){
                state.selectedData.endDate = action.payload;
            } else {
                toast.error(invalidEndDateMessage);
            }
        },
        setStageStartDate: (state, action) => {
            const newSelectedStageData = {...state.selectedStageData};
            const newStage = {...newSelectedStageData.stage};
            newStage.startDate = action.payload;

            // If the new start date is after the end date, update the end date as well
            if (newStage.endDate && new Date(newStage.startDate) > new Date(newStage.endDate)) {
                newStage.endDate = newStage.startDate;
            }

            newSelectedStageData.stage = newStage;

            return {
                ...state,
                selectedStageData: newSelectedStageData
            };
        },
        setStageFinishDate: (state, action) => {
            const newSelectedStageData = { ...state.selectedStageData };
            const newStage = { ...newSelectedStageData.stage };
            newStage.endDate = action.payload;
            newSelectedStageData.stage = newStage;

            return {
                ...state,
                selectedStageData: newSelectedStageData
            };
        },
        setProjectResourcePrice: (state, action) => {
            const newStages = state.selectedData.Stages.map(stage => ({
                ...stage,
                stageResources: stage.stageResources.map(resource => resource.resourceId === action.payload.resourceId
                    ? { ...resource, price: action.payload.price }
                    : resource)
            }));

            return {
                ...state,
                selectedData: {
                    ...state.selectedData,
                    Stages: newStages,
                }
            };
        },
        setProjectResourceRole: (state, action) => {
            const newStages = state.selectedData.Stages.map(stage => ({
                ...stage,
                stageResources: stage.stageResources.map(resource => resource.resourceId === action.payload.resourceId
                    ? { ...resource, projectRoleId: action.payload.projectRoleId }
                    : resource)
            }));
            console.log(newStages);
            return {
                ...state,
                selectedData: {
                    ...state.selectedData,
                    Stages: newStages,
                }
            };
        },
        setProcess: (state, action) => {
            state.selectedData.processId = action.payload;
        },
        selectSubArea: (state, action) => {
            state.selectedData.subAreaId = action.payload;
        },
        selectService: (state, action) => {
            state.selectedData.serviceId = action.payload;
        },
        selectClient: (state, action) => {
            state.selectedData.clientId = action.payload;
        },
        setStageName: (state, action) => {
            const newSelectedStageData = { ...state.selectedStageData };
            const newStage = { ...newSelectedStageData.stage };
            newStage.name = action.payload;
            newSelectedStageData.stage = newStage;
            return {
                ...state,
                selectedStageData: newSelectedStageData
            };
        },
        setStageNumber: (state, action) => {
            const newSelectedStageData = { ...state.selectedStageData };
            const newStage = { ...newSelectedStageData.stage };
            if (!isNaN(action.payload)) {
                newStage.number = action.payload;
                newSelectedStageData.stage = newStage;
                return {
                    ...state,
                    selectedStageData: newSelectedStageData
                };
            } else {
                toast.error(invalidNumberTypeMessage);
                return state;
            }
        },
        setWeekHours: (state, action) => {
            if (!isNaN(action.payload.data)) {
                const { assignmentIndex, data } = action.payload;

                state.selectedStageData.stage.stageResources[assignmentIndex] = {
                    ...state.selectedStageData.stage.stageResources[assignmentIndex],
                    weeklyHours: data,
                };
            } else {
                toast.error(invalidNumberTypeMessage);
            }
        },
        setTags: (state, action) => {
            state.selectedData.projectTags = action.payload.map((tagId:number) =>  { return {tagId: state.tags.find((tag) => tagId === tag.id)?.id}});
        },
        setTasks: (state, action) => {
            state.selectedData.projectTasks = action.payload.map((taskId:number) => { return {taskId: state.tasks.find((task) => taskId === task.id)?.id}});
        },
        selectResource: (state, action) => {
            const { assignmentIndex, data } = action.payload;

            // find if the resource with same id exists in Stages array
            const existingResourceInStages = state.selectedData.Stages.flatMap(stage =>
                stage.stageResources.find(resource => resource.resourceId === data));

            // Find the first existingResource that is not undefined
            const existingResource = existingResourceInStages.find(resource => resource !== undefined);

            if(existingResource) {
                // if it exists, assign the new resource the same price and projectRoleId
                state.selectedStageData.stage.stageResources[assignmentIndex] = {
                    ...state.selectedStageData.stage.stageResources[assignmentIndex],
                    resourceId: data,
                    price: existingResource.price,
                    projectRoleId: existingResource.projectRoleId,
                };
            } else {
                // if it does not exist, assign the resourceId only
                state.selectedStageData.stage.stageResources[assignmentIndex] = {
                    ...state.selectedStageData.stage.stageResources[assignmentIndex],
                    resourceId: data,
                };
            }
        },
        selectRole: (state,action) => {
            state.selectedData.projectResources[action.payload.assignmentIndex].projectRoleId = action.payload.data;
        },
        setCode: (state,action) => {
            state.selectedData.code = action.payload;
        },
        setState: (state,action) => {
            state.selectedData.stateId = action.payload;
            state.selectedData.state = state.states[action.payload-1].name;

        },
        addAssignment: (state) => {
            let stageResource = new StageResource();
            stageResource.resourceId = 0;
            stageResource.projectRoleId = 0;
            stageResource.weeklyHours = 0;
            stageResource.ProjectStageId = state.selectedStageData.stage.id;

            const newSelectedStageData = { ...state.selectedStageData };
            const newStage = { ...newSelectedStageData.stage };

            newStage.stageResources = newStage.stageResources ? [...newStage.stageResources, stageResource] : [stageResource];

            newSelectedStageData.stage = newStage;

            return {
                ...state,
                selectedStageData: newSelectedStageData
            };
        },
        addStage: (state) => {
            state.selectedData.Stages.push(state.selectedStageData.stage);
        },
        updateStage: (state) => {
            state.selectedData.Stages[state.selectedStageData.index] = state.selectedStageData.stage;
        },
        deleteStage: (state, action) => {
            state.selectedData.Stages.splice(action.payload, 1)
        },
        duplicateStage: (state, action) => {
            const originalStage = state.selectedData.Stages[action.payload.stageIndex];
            const existingNumbers = state.selectedData.Stages.map(stage => stage.number);
            existingNumbers.sort((a, b) => a - b);

            let newNumber = originalStage.number + 1;
            while (existingNumbers.includes(newNumber)) {
                newNumber++;
            }

            const newStage = { ...originalStage, number: newNumber, id: 0};

            newStage.stageResources = newStage.stageResources.map(resource => ({
                ...resource,
                id: 0,
            }));

            state.selectedData.Stages.push(newStage);
        },
        deleteAssignment: (state, action) => {
            state.selectedStageData.stage.stageResources.splice(action.payload, 1);
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchTasks.pending, (state) => {
            state.tasksStatus = requestLoading;
        }).addCase(fetchTasks.fulfilled, (state, action) => {
            state.tasks = action.payload;
            state.tasksStatus = requestSuccessful;
        }).addCase(fetchTasks.rejected, (state) => {
            state.tasksStatus = requestMissed;
        });

        builder.addCase(fetchTags.pending, (state) => {
            state.tagsStatus = requestLoading;
        }).addCase(fetchTags.fulfilled, (state, action) => {
            state.tags = action.payload;
            state.tagsStatus = requestSuccessful;
        }).addCase(fetchTags.rejected, (state) => {
            state.tagsStatus = requestMissed;
        });

        builder.addCase(fetchResources.pending, (state) => {
            state.resourcesStatus = requestLoading;
        }).addCase(fetchResources.fulfilled, (state, action) => {
            action.payload.sort((a,b) => (`${a.name} ${a.lastName}` > `${b.name} ${b.lastName}`) ? 1 : ((`${a.name} ${a.lastName}` < `${b.name} ${b.lastName}`) ? -1 : 0))
            state.resources = action.payload;
            state.resourcesStatus = requestSuccessful;
        }).addCase(fetchResources.rejected, (state) => {
            state.resourcesStatus = requestMissed;
        });

        builder.addCase(fetchRoles.pending, (state) => {
            state.rolesStatus = requestLoading;
        }).addCase(fetchRoles.fulfilled, (state, action) => {
            state.roles = action.payload;
            state.rolesStatus = requestSuccessful;
        }).addCase(fetchRoles.rejected, (state) => {
            state.rolesStatus = requestMissed;
        });

        builder.addCase(fetchProcesses.pending, (state) => {
            state.processesStatus = requestLoading;
        }).addCase(fetchProcesses.fulfilled, (state, action) => {
            state.processes = action.payload;
            state.processesStatus = requestSuccessful;
        }).addCase(fetchProcesses.rejected, (state) => {
            state.processesStatus = requestMissed;
        });

        builder.addCase(fetchSubAreas.pending, (state) => {
            state.subAreasStatus = requestLoading;
        }).addCase(fetchSubAreas.fulfilled, (state, action) => {
            state.subAreas = action.payload;
            state.subAreasStatus = requestSuccessful;
        }).addCase(fetchSubAreas.rejected, (state) => {
            state.subAreasStatus = requestMissed;
        });

        builder.addCase(fetchProjectServices.pending, (state) => {
            state.projectServicesStatus = requestLoading;
        }).addCase(fetchProjectServices.fulfilled, (state, action) => {
            state.projectServices = action.payload;
            state.projectServicesStatus = requestSuccessful;
        }).addCase(fetchProjectServices.rejected, (state) => {
            state.projectServicesStatus = requestMissed;
        });

        builder.addCase(fetchProjectTasks.pending, (state) => {
            state.projectTasksStatus = requestLoading;
        }).addCase(fetchProjectTasks.fulfilled, (state, action) => {
            state.selectedData.projectTasks = action.payload.map((task:any) => {return {taskId: task.id}});
            state.projectTasksStatus = requestSuccessful;
        }).addCase(fetchProjectTasks.rejected, (state) => {
            state.projectTasksStatus = requestMissed;
        });

        builder.addCase(fetchProjectTags.pending, (state) => {
            state.projectTagsStatus = requestLoading;
        }).addCase(fetchProjectTags.fulfilled, (state, action) => {
            state.selectedData.projectTags = action.payload.map((tag:any)=> {return {tagId:tag.id}}) ;
            state.projectTagsStatus = requestSuccessful;
        }).addCase(fetchProjectTags.rejected, (state) => {
            state.projectTagsStatus = requestMissed;
        });
    },
})

export const saveProjectDialogReducer = saveProjectDialog.reducer
export const {openSaveProjectDialog, selectService, openSaveProjectStageDialogEdition, setProjectResourcePrice, setProjectResourceRole,openSaveProjectStageDialogCreation, deleteStage,setStageNumber, setStageStartDate, setStageFinishDate, setStageName, addStage, closeSaveProjectStageDialog,setProcess,closeSaveProjectDialog, setName, selectSubArea, selectClient, selectRole,
    setWeekHours, selectResource, duplicateStage, updateStage, setFinishDate, setStartDate, setTags, setTasks, setCode, addAssignment, deleteAssignment, setState} = saveProjectDialog.actions;