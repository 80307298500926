import { Box, IconButton, Typography } from '@mui/material'
import { ReactComponent as DeleteIcon } from "../../../assets/icons/DeleteIcon.svg"
import { ReactComponent as EditIcon } from "../../../assets/icons/EditIcon.svg"
import {admin, blackColor, greenColor, manager, pm, violetColor} from '../../constants/constants'
import { Project } from '../../core/models/projectModel'
import {fetchProjectTags, fetchProjectTasks, openSaveProjectDialog } from '../../core/state/dialogs/saveProjectDialogSlice'
import { useAppDispatch, useAppSelector } from '../../core/state/hooks'
import { Client } from '../../core/models/clientModel'
import RemoveProjectDialog from './removeProjectDialog'
import { openRemoveDialog } from '../../core/state/dialogs/removeProjectDialogSlice'
import moment from "moment/moment";

const getStateColor = (state:string = "")=>{
  switch (state) {
    case "OPEN": return greenColor;
    case "CLOSED": return violetColor;
    default: return blackColor
  }
}

const getRemainDays = (endDate:Date)=>{
    let remainingDays = moment(endDate).diff(new Date(),'days')
    return remainingDays > 0 ? remainingDays : 0
}


export default function ProjectRow(props: { project: Project, clients:Array<Client> }) {
  const dispatch = useAppDispatch();
  const moment = require('moment');
  const startDate = props.project.startDate?.toString().split('T')[0];
  const {user} = useAppSelector((state) => state.user);
  return <Box display={"flex"} height={40} flexDirection="row" alignItems={"center"}  textAlign={"center"} >
      <Typography flex={"0.18 1 0"} textAlign="left" pl={3} sx={{boxSizing:"border-box"}}> {props.project.name}</Typography>
      <Typography flex={0.20}>{props.project.code}</Typography>
      <Typography flex={0.20}>{`${startDate?.toString().split("-")[2]}/${startDate?.toString().split("-")[1]}/${startDate?.toString().split("-")[0]}`}</Typography>
      <Typography flex={0.20}>{(parseInt(props.project.totalHour.split(":")[0]) + (props.project.daysOfWork * 24)) + " horas"}</Typography>
      <Typography flex={0.20}>{props.project.endDate && `${getRemainDays(props.project.endDate)} días` }</Typography>
      <Typography flex={0.20} color={getStateColor(props.project.state)}> {props.project.state}</Typography>
      <Box flex={0.13}>
      {
        user.appRoleId === admin || user.appRoleId === pm || user.appRoleId === manager ?<IconButton onClick={() => {
          dispatch(fetchProjectTags(props.project.id));
          dispatch(fetchProjectTasks(props.project.id));
          dispatch(openSaveProjectDialog({project: props.project,clients:props.clients}));
      }} children={<EditIcon />} />:<></>
      }
      {
        user.appRoleId === admin ?<IconButton onClick={() => {
          dispatch(openRemoveDialog(props.project.id))
        }} children={<DeleteIcon  />}/>:<></>
      }
      </Box>
      <RemoveProjectDialog />
  </Box>
}
