import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { emptyString, recurringN, requestLoading, requestMissed, requestPending, requestSuccessful, TypeOfMoneys } from "../../../constants/constants";
import {
    getSectores,
    getSubSectores,
    getProjects,
    getProjectServices
} from "../../http/httpClient";
import { Project } from "../../models/projectModel";
import { PurchaseDTO } from "../../models/purchaseModelDTO";
import { Sector } from "../../models/sectorModel";
import { SubSector } from "../../models/subSectorModel";
import { fetchGetPurchases } from "../purchases/purchaseSlice";
import { RootState } from "../store";
import { putPurchase } from '../../http/httpClient'
import {projectService} from "../../models/projectServiceModel";

const namespace = 'editPurchaseDialog';

export const fetchSector = createAsyncThunk(
    `${namespace}/fetchSector`,
    async () =>  await getSectores(),
);

export const fetchSubSector = createAsyncThunk(
    `${namespace}/fetchSubSector`,
    async () => await getSubSectores(),
);

export const fetchProjectServices = createAsyncThunk(
    `${namespace}/fetchProjectServices`,
    async () => await getProjectServices(false)
);

export const fetchGetProjects = createAsyncThunk(
    `${namespace}/fetchGetProjects`,
    async () => await getProjects(),
);

export const updatePurchase = createAsyncThunk(
    `${namespace}/updatePurchase`,
    async (_, thunkAPI) => {
        const { editPurchaseDialog : state, purchases:purchaseState } = thunkAPI.getState() as RootState;
        const { rowsPerPage, page, selectedMonth, yearFilter, searchFilter, selectedSector, selectedSubSector} = purchaseState
        const { id,date, document,concept ,currency ,netOrigin ,tax ,total,rut ,projectId ,recurring, subSectorId,supplier,serviceId  } = state.selectedData;
        const purchaseDate = {
            id,date, document,concept ,currency ,netOrigin ,tax ,total,rut ,projectId:(projectId === 0 ? null : projectId) ,recurring,supplier, subSectorId, serviceId:(serviceId === 0 ? null : serviceId)
        }
        await putPurchase(purchaseDate);
        thunkAPI.dispatch(closeEditDialog())
        thunkAPI.dispatch(fetchGetPurchases({offset: page*rowsPerPage, limit: rowsPerPage, month: selectedMonth, year: yearFilter.toString(), searchTerm: searchFilter, sector: selectedSector, subSector: selectedSubSector}))
    },
);



const initialState = {
    isOpen:false,
    id:0,
    projectsList:new Array<Project>(),
    projectsListStatus : requestPending,
    sectorList : new Array<Sector>(),
    subSectorList : new Array<SubSector>(),
    servicesList : new Array<projectService>(),
    projectServices : new Array<projectService>(),
    projectServicesStatus: requestPending,
    sectorListStatus : requestPending,
    subSectorListStatus : requestPending,
    selectedData: {
        id:0,
        projectId : 0,
        clientProjects: new Array<Project>(),
        project: new Project(),
        document : emptyString, 
        supplier : emptyString,
        rut : emptyString,
        date: new Date(),
        concept : emptyString,
        currency : TypeOfMoneys[1],
        netOrigin : 0,
        tax : 0,
        total : 0,
        sectorId : 0,
        serviceId : 0,
        subSectorId : 0, 
        recurring:recurringN,
        code : emptyString
    },
    status: requestPending
}

export const editPurchaseDialog = createSlice({
    name : namespace,
    initialState : initialState,
    reducers:{
        openEditDialog: (state,action) => {
            state.isOpen = true;
            if(action.payload){
                const purchase = action.payload as PurchaseDTO;
                state.selectedData.id = purchase.id;
                state.selectedData.date = purchase.date;
                state.selectedData.document = purchase.document;
                state.selectedData.supplier = purchase.supplier;
                state.selectedData.rut = purchase.rut;
                state.selectedData.subSectorId = purchase.subSectorId;
                state.selectedData.serviceId = purchase.serviceId;
                state.selectedData.sectorId = purchase.sectorId;
                state.selectedData.concept = purchase.concept;
                state.selectedData.currency = purchase.currency;
                state.selectedData.netOrigin = purchase.netOrigin;
                state.selectedData.tax = purchase.tax;
                state.selectedData.total = purchase.total;
                state.selectedData.recurring = purchase.recurring;
                state.selectedData.code = purchase.projectCode as string;
                if(purchase.projectId !== null){
                    state.selectedData.projectId = purchase.projectId;
                }
            }
        },
        closeEditDialog:(state) => {
            state.isOpen = false;
        },
        setProject: (state, action) => {
            state.selectedData.projectId = action.payload;
        },
        setProjectCode: (state, action) => {
            state.selectedData.projectId = action.payload;
            const proj = state.projectsList.find((p) => p.id===action.payload)
            if(proj){
                state.selectedData.serviceId = proj.serviceId;
            }
        },
        setDate: (state, action) => {
            state.selectedData.date = action.payload;
        },
        setDocument: (state, action) => {
            state.selectedData.document = action.payload;
        },
        setSupplier: (state, action) => {
            state.selectedData.supplier = action.payload;
        },
        setRut: (state, action) => {
            state.selectedData.rut = action.payload;
        },
        setConcept: (state, action) => {
            state.selectedData.concept = action.payload;
        },
        setCurrency: (state, action) => {
            state.selectedData.currency = action.payload;
        },
        setNetOrigin: (state, action) => {
            state.selectedData.netOrigin = +action.payload;
            state.selectedData.total = +action.payload + state.selectedData.tax
        },
        setTax: (state, action) => {
            state.selectedData.tax = +action.payload;
            state.selectedData.total = +action.payload + state.selectedData.netOrigin;
        },
        setClientProjects: (state, action) => {
            state.selectedData.clientProjects = action.payload;
        },
        setSector: (state, action) => {
            state.selectedData.sectorId = action.payload;
            const ssId = state.subSectorList.find((ss) => ss.sectorId===action.payload);
            if(ssId){
                state.selectedData.subSectorId = ssId.id;
            }
        },
        setSubSector: (state, action) => {
            state.selectedData.subSectorId = action.payload;
        },
        setService: (state, action) => {
            state.selectedData.serviceId = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchSector.pending, (state) => {
            state.sectorListStatus = requestLoading;
        }).addCase(fetchSector.fulfilled, (state, action) => {
            state.sectorList = action.payload;
            state.sectorListStatus = requestSuccessful;
        }).addCase(fetchSector.rejected, (state) => {
            state.sectorListStatus = requestMissed;
        });

        builder.addCase(fetchSubSector.pending, (state) => {
            state.subSectorListStatus = requestLoading;
        }).addCase(fetchSubSector.fulfilled, (state, action) => {
            state.subSectorList = action.payload;
            state.subSectorListStatus = requestSuccessful;
        }).addCase(fetchSubSector.rejected, (state) => {
            state.subSectorListStatus = requestMissed;
        });

        builder.addCase(fetchProjectServices.pending, (state) => {
            state.projectServicesStatus = requestLoading;
        }).addCase(fetchProjectServices.fulfilled, (state, action) => {
            state.projectServices = action.payload;
            state.projectServicesStatus = requestSuccessful;
        }).addCase(fetchProjectServices.rejected, (state) => {
            state.projectServicesStatus = requestMissed;
        });

        builder.addCase(fetchGetProjects.pending, (state) => {
            state.projectsListStatus = requestLoading;
        }).addCase(fetchGetProjects.fulfilled, (state, action) => {
            state.projectsList = action.payload;
            state.projectsListStatus = requestSuccessful;
        }).addCase(fetchGetProjects.rejected, (state) => {
            state.projectsListStatus = requestMissed;
        });
    }
})

export const editPurchaseDialogReducer = editPurchaseDialog.reducer;
export const { openEditDialog, closeEditDialog, setConcept, setCurrency, setDate, setDocument, setRut,
    setNetOrigin, setProject, setTax, setSupplier,setSector,setProjectCode, setSubSector, setService
     } = editPurchaseDialog.actions;