import { greenColor, lightGrayColor, TypeOfMoneys,darkGrayColor,grayColor,darkGreenColor, whiteColor, documentList, emptyString, } from "../../constants/constants"
import {
    updatePurchase,
    closeEditDialog,
    setProjectCode,
    setTax,
    setNetOrigin,
    setCurrency,
    setDocument,
    setConcept,
    setDate,
    setSupplier,
    setSector,
    fetchSector,
    fetchSubSector,
    fetchGetProjects,
    setSubSector,
    setRut,
    setService, fetchProjectServices
} from "../../core/state/dialogs/editPurchaseDialogSlice";
import { useAppDispatch, useAppSelector } from "../../core/state/hooks";
import { useEffect } from "react";
import { Dialog, DialogContent, DialogTitle, Table, TableCell, TableContainer, TableRow, Typography,TableHead,TableBody, 
        FormControl,Box, TextField, Select, MenuItem, DialogActions, Button, SelectChangeEvent, Autocomplete,
         } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Project } from "../../core/models/projectModel";
import React from "react";
import { SubSector } from "../../core/models/subSectorModel";
import { Sector } from "../../core/models/sectorModel";

interface Column {
    id: "CheckBox" | "Fecha" | "Documento" | "Proveedor" | "RUT" | "Rubro" | "CDeRubro" | "Concepto" | "Servicio" |
    "CdeCosto" | "Moneda" | "NOrigen" | "Impuestos" | "Total" ;
    label: string;
    minWidth: number;
}

export const columns: readonly Column[] = [
    { id: "CheckBox", label: "", minWidth:45},
    { id: "Fecha", label: "Fecha", minWidth: 200 },
    { id: "Documento", label: "Documento", minWidth: 170 },
    { id: "Proveedor", label: "Proveedor", minWidth: 250 },
    { id: "RUT", label: "RUT", minWidth: 200 },
    { id: "Rubro", label: "Rubro", minWidth: 140,}, 
    { id: "CDeRubro", label: "Categoría de Rubro", minWidth: 250,},
    { id: "Concepto", label: "Concepto",minWidth: 200, },
    { id: "Servicio", label: "Servicio",minWidth: 250, },
    { id: "CdeCosto", label: "Centro de Costo",minWidth: 250, },
    { id: "Moneda", label: "Moneda",minWidth: 150, },
    { id: "NOrigen", label: "Neto Origen",minWidth: 170, },
    { id: "Impuestos", label: "Impuestos",minWidth: 150, },
    { id: "Total", label: "Total",minWidth: 150, },
];

const style = {backgroundColor:lightGrayColor,borderRadius:4, boxShadow:"2px 2px 5px rgba(35, 31, 32, 0.1)", border:2, borderColor: greenColor}

export default function EditPurchaseDialog(){
    const dispatch = useAppDispatch();
    const { isOpen: dialogIsOpen, selectedData, projectsList, projectServices,sectorList, subSectorList } = useAppSelector((state) => state.editPurchaseDialog);

    const codeList = projectsList.map((a) => a.code);


    const [disabled, setDisabled] = React.useState(false);
    const [withCode , setWithCode] = React.useState(false);
    const [value, setValue] = React.useState<string>();

    useEffect(() => {
        if(dialogIsOpen){
            setValue(selectedData.code?.toString());
            if(selectedData.code?.toString() === undefined){
                setWithCode(false);
            }else{
                setWithCode(true);
            }
            dispatch(fetchSector());
            dispatch(fetchSubSector());
            dispatch(fetchProjectServices());
            dispatch(fetchGetProjects());
        }

    },[dispatch, dialogIsOpen]);

    const handleOnClickSaveEdit = () => {
        dispatch(updatePurchase())
    }

    const handleOnClose = () => {
        dispatch(setService(0));
        dispatch(closeEditDialog());
    }

    return (
        <Dialog open={dialogIsOpen} onClose={() => { handleOnClose(); }} maxWidth="lg">
            <DialogTitle sx={{fontSize:"25px! important",color:darkGrayColor,pl:3,pt:3}}>
                Edición de Compra
            </DialogTitle>
            <DialogContent>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow >
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id} align="center"
                                        style={{ minWidth: column.minWidth }} sx={{ borderBottom: 1, borderColor: grayColor }}
                                    >
                                        <Typography variant='h6'>{column.label}</Typography>
                                    </TableCell>
                                ))
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody >
                            <TableRow hover role="checkbox" tabIndex={-1} >
                                <TableCell align="right" sx={{ p: 0 }} />
                                <TableCell align="center">
                                    <FormControl>
                                        <LocalizationProvider dateAdapter={AdapterDateFns} >
                                            <Box sx={{backgroundColor:lightGrayColor,borderRadius:9,border:2, borderColor: greenColor, boxShadow:"2px 2px 5px rgba(35, 31, 32, 0.1)"}}>
                                                <DatePicker
                                                    disableOpenPicker={false}
                                                    inputFormat="dd/MM/yyyy"
                                                    value={selectedData.date}
                                                    onChange={(date) => { 
                                                        dispatch(setDate(date));
                                                    }}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </Box>
                                        </LocalizationProvider>
                                    </FormControl>
                                </TableCell>
                                <TableCell align="center">
                                    <FormControl sx={{minWidth:200,flex:0.27}}>
                                            <FormControl sx={{width:"200px",flex:0.27}}>
                                                <TextField sx={style}
                                                    id="document" 
                                                    value={selectedData.document}
                                                    onChange={(event) => { dispatch(setDocument(event.target.value));}}
                                                />
                                            </FormControl> 
                                    </FormControl>
                                </TableCell>
                                <TableCell align="center">
                                    <FormControl sx={{minWidth:200,flex:0.27}}>
                                            <TextField sx={style} id="supplier"
                                                value={selectedData.supplier}
                                                onChange={(event) => {
                                                    dispatch(setSupplier(event.target.value));
                                                }}
                                            />
                                    </FormControl>
                                </TableCell>
                                <TableCell align="center">
                                    <FormControl sx={{minWidth:200,flex:0.27}}>
                                            <TextField sx={style} id="rut"
                                                value={selectedData.rut}
                                                onChange={(event) => {
                                                    dispatch(setRut(event.target.value));
                                                }}
                                            />
                                    </FormControl>
                                </TableCell>
                                <TableCell align="center">
                                    <FormControl sx={{minWidth:200,flex:0.37}} fullWidth>
                                            <Select
                                                id="sector-select"
                                                label="sector"
                                                value={selectedData.sectorId}
                                                onChange={(event) => {
                                                    dispatch(setSector(event.target.value));
                                                }}
                                                sx={style}
                                            >
                                                {
                                                    sectorList && sectorList.map((sector : Sector) => <MenuItem value={sector.id} key={sector.id}>{sector.name}</MenuItem>)
                                                }
                                            </Select>
                                    </FormControl>
                                </TableCell>
                                <TableCell align="center">
                                    <FormControl sx={{flex:0.37}} fullWidth>
                                            <Select
                                                id="subSector-select"
                                                label="subSector"
                                                value={selectedData.subSectorId}
                                                onChange={(event) => {dispatch(setSubSector(event.target.value))}}
                                                sx={style}
                                            >
                                                {
                                                    subSectorList && subSectorList.map((subSector : SubSector) => 
                                                    <MenuItem disabled={subSector.sectorId === selectedData.sectorId ? false : true} value={subSector.id} key={subSector.id}>{subSector.name}</MenuItem>)
                                                }
                                            </Select>
                                    </FormControl>
                                </TableCell>
                                <TableCell align="center">
                                    <FormControl sx={{flex:0.27}}>
                                            <TextField sx={style} id="concept"
                                            value={selectedData.concept}
                                            onChange={(event) => {
                                                dispatch(setConcept(event.target.value));
                                            }}
                                            />
                                    </FormControl>
                                </TableCell>
                                <TableCell align="center">
                                    <FormControl sx={{flex:0.37}} fullWidth>
                                        <Select
                                            id="service-select"
                                            label="Servicio"
                                            disabled={withCode}
                                            onChange={(event : SelectChangeEvent<number>) => {
                                                const idService = parseInt(event.target.value.toString());
                                                dispatch(setService(idService));
                                            }}
                                            value={selectedData.serviceId}
                                            sx={style}
                                        >
                                            <MenuItem value={0} key={0}>{""}</MenuItem>
                                            {
                                                (projectServices.length > 0) && projectServices.map((services) => <MenuItem value={services.id} key={services.id}>{services.name}</MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>
                                </TableCell>
                                <TableCell align="center">
                                    <FormControl sx={{flex:0.37}} fullWidth>
                                        <Autocomplete
                                            value={value}
                                            onChange={(event, newValue) => {
                                                if(newValue){
                                                    const proj = projectsList.find((p : Project) => p.code===newValue);
                                                    if (proj) {
                                                        dispatch(setProjectCode(proj.id));
                                                        setValue(newValue);
                                                        setWithCode(true)
                                                    }
                                                }else{
                                                    setValue("");
                                                    setWithCode(false)
                                                    dispatch(setProjectCode(0))
                                                }
                                            } }
                                            id="controllable-states"
                                            options={codeList}
                                            sx={style}
                                            renderInput={(params) => {
                                                params.inputProps.style = { textAlign: 'center',paddingTop:"0px",marginTop:"-6px"};
                                                return (<TextField {...params} />)
                                                
                                            }}
                                        />
                                    </FormControl>
                                </TableCell>
                                <TableCell align="center">
                                    <FormControl sx={{flex:0.37}} fullWidth>
                                        <Select
                                            id="money-select"
                                            label="Money"
                                            sx={style}
                                            value={selectedData.currency}
                                            onChange={(event) => {
                                                dispatch(setCurrency(event.target.value));
                                            }}
                                        >
                                            {
                                                TypeOfMoneys.map((money) => <MenuItem value={money} key={money}>{money} </MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>
                                </TableCell>
                                <TableCell align="center">
                                    <FormControl sx={{flex:0.27}}>
                                    <TextField 
                                        id="netOrigin"
                                        type="number"
                                        inputProps={{style: { textAlign: 'center' }}} 
                                        sx={style} 
                                        onChange={(event) => { dispatch(setNetOrigin(event.target.value)); }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        value={selectedData.netOrigin}
                                        />
                                    </FormControl>
                                </TableCell>
                                <TableCell align="center">
                                    <FormControl sx={{flex:0.27}}>
                                    <TextField
                                        id="tax"
                                        type="number"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        value={selectedData.tax}
                                        sx={style}
                                        inputProps={{style: { textAlign: 'center' }}}
                                        onChange={(event) => { 
                                            dispatch(setTax(event.target.value));
                                        }}
                                    />
                                    </FormControl>
                                </TableCell>
                                <TableCell align="center">
                                    <FormControl sx={{flex:0.27}}>
                                        <TextField inputProps={{style: { textAlign: 'center' }}}  id="total" sx={style} value={isNaN(selectedData.total) ?  0 : selectedData.total} disabled/>
                                    </FormControl>
                                </TableCell>                  
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions sx={{pb:2,pr:3}}> 
                <Button 
                    sx={{backgroundColor:greenColor,color:whiteColor,px:3,'&:hover': {backgroundColor: darkGreenColor,}}}
                    disabled={disabled}
                    onClick={handleOnClickSaveEdit}
                >
                    Guardar
                </Button> 
            </DialogActions>
        </Dialog>
    )
}