import React, { useEffect } from "react";
import {
    Box,
    Card,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
    TableHead,
    TextField
} from "@mui/material";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/DeleteIcon.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/EditIcon.svg";
import { Add, Search } from '@mui/icons-material';
import {greenColor, lightGrayColor, darkGreenColor, whiteColor, admin, manager} from "../../constants/constants";
import {fetchTags, fetchTasks } from "../../core/state/tasksTags/tasksTagsSlice";
import { useAppDispatch, useAppSelector } from "../../core/state/hooks";
import { task } from "../../core/models/taskModel";
import { Tag } from "../../core/models/tagModel";
import { openRemoveTagDialog, openRemoveTaskDialog} from "../../core/state/dialogs/removeTaskTagDialogSlice";
import RemoveProjectDialog from './removeTaskTagDialog';
import { openSaveTagDialog, openSaveTaskDialog, openEditTaskDialog, openEditTagDialog } from "../../core/state/dialogs/saveTaskTagDialogSlice";
import SaveTaskTagDialog from "./saveTaskTagDialog";

export default function TasksTagsMaster() {
    const dispatch = useAppDispatch();
    const { user } = useAppSelector((state) => state.user);
    const { tasks, tags } = useAppSelector((state) => state.tasksTags);
    const createTask = () => {
        dispatch(openSaveTaskDialog())
    };

    const createTag = () => {
        dispatch(openSaveTagDialog())
    };

    const [currentSearchTerm, setCurrentSearchTerm] = React.useState("");
    const [Tasks, setTasks] = React.useState<task[]>([]);
    const [Tags, setTags] = React.useState<Tag[]>([]);
    const selectSearchFilter = () => {
        const filteredTasks = tasks
            .filter(task => task.name && task.name.toLowerCase().includes(currentSearchTerm.toLowerCase()));

        const filteredTags = tags
            .filter(tag => tag.name && tag.name.toLowerCase().includes(currentSearchTerm.toLowerCase()));

        setTasks(filteredTasks);
        setTags(filteredTags);
    }


    useEffect(() => {
        dispatch(fetchTags());
        dispatch(fetchTasks());
    }, []);

    useEffect(() => {
        setTasks(tasks);
        setTags(tags);
        setCurrentSearchTerm("");
    }, [tasks, tags]);

    return (
        <Box component="main" display={"flex"} flex={1} flexDirection="column" bgcolor={lightGrayColor} sx={{ maxWidth: '100%' }}>
            <Box display={"flex"} flexDirection="row" borderBottom={2} borderColor='rgba(35, 31, 32, 0.1)' pb={1} alignItems="center" >
                <Typography variant='h5'>Maestro de tareas y tags</Typography>
                <TextField
                    value={currentSearchTerm}
                    onChange={(event) => {
                        setCurrentSearchTerm(event.target.value);
                        if(event.target.value === "") {
                            setTasks(tasks);
                            setTags(tags)
                        }
                    }}
                    sx={{ backgroundColor: whiteColor, boxShadow: "2px 2px 5px rgba(35, 31, 32, 0.1)", borderRadius: 5, color: greenColor, mx: 2 }} />
                <IconButton onClick={selectSearchFilter} sx={{ color: whiteColor, backgroundColor: greenColor, mr: 2 }}>
                    <Search />
                </IconButton>
            </Box>
            <Box display='flex' justifyContent='space-around' sx={{ mt: 2, height: '80vh' }}>
                <Box sx={{ position: 'relative', width: '45%' }}>
                    <Card sx={{ bgcolor: whiteColor, width: '100%', height: '100%', borderRadius: 8, position: 'relative', overflow: 'hidden' }}>
                        <Box sx={{ overflow: 'auto', width: '100%', maxHeight: '100%' }}>
                            <Table>
                                <TableHead sx={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: '#fff' }}>
                                    <TableRow key={`tableHeadKey`}>
                                        <TableCell
                                            style={{ minWidth: 30, padding: '10px 10px 10px 30px',position: 'sticky',top: 0,
                                                display: 'block', borderBottom: '3px solid', borderColor: greenColor }}
                                        >
                                            <Typography variant='h6'>TAREAS</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ overflowY: 'scroll' }}>
                                    {Tasks.slice().sort((a, b) => (a.name && b.name) ? a.name.localeCompare(b.name) : 0).map((task, i) => (
                                        <TableRow key={`${task.id}-${i}`} sx={{ overflow: 'auto' }}>
                                            <TableCell style={{ borderBottom: 'none', paddingLeft: 30 }}>
                                                <Box display="flex" alignItems="center" justifyContent="space-between">
                                                    <div>{task?.name}</div>
                                                    <div>
                                                        {task.id !== undefined && (
                                                            <React.Fragment>
                                                                {user && ( user.appRoleId === admin || user.appRoleId === manager) ? <IconButton
                                                                    onClick={() => {
                                                                        if (typeof task.id === 'number') {
                                                                            dispatch(openEditTaskDialog(task));
                                                                        }
                                                                    }}
                                                                    sx={{ color: whiteColor, mr: 2 }}
                                                                >
                                                                    <EditIcon />
                                                                </IconButton>: <></>}
                                                                {user && ( user.appRoleId === admin || user.appRoleId === manager) ? <IconButton
                                                                    onClick={() => {
                                                                        if (typeof task.id === 'number') {
                                                                            dispatch(openRemoveTaskDialog(task.id));
                                                                        }
                                                                    }}
                                                                    sx={{ color: whiteColor }}
                                                                >
                                                                    <DeleteIcon />
                                                                </IconButton>: <></>}
                                                            </React.Fragment>
                                                        )}
                                                    </div>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Card>

                    <Box sx={{ position: 'absolute', bottom: '-10px', right: '-10px' }}>
                        {user && ( user.appRoleId === admin || user.appRoleId === manager) ? <IconButton
                            sx={{ backgroundColor: greenColor, color: whiteColor, "&:hover": { backgroundColor: darkGreenColor } }}
                            size='large'
                            onClick={createTask}
                        >
                            <Add />
                        </IconButton>
                            : <></>}
                    </Box>
                </Box>

                <Box sx={{ position: 'relative', width: '45%' }}>
                    <Card sx={{ bgcolor: whiteColor, width: '100%', height: '100%', borderRadius: 8, position: 'relative', overflow: 'hidden' }}>
                        <Box sx={{ overflow: 'auto', width: '100%', maxHeight: '100%' }}>
                            <Table>
                                <TableHead sx={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: '#fff' }}>
                                    <TableRow key={`tableHeadKey`}>
                                        <TableCell
                                            style={{ minWidth: 30, padding: '10px 10px 10px 30px',position: 'sticky',top: 0,
                                                display: 'block', borderBottom: '3px solid', borderColor: greenColor }}
                                        >
                                            <Typography variant='h6'>TAGS</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ overflowY: 'scroll' }}>
                                    {Tags.slice().sort((a, b) => (a.name && b.name) ? a.name.localeCompare(b.name) : 0).map((tag, i) => (
                                        <TableRow key={`${tag.id}-${i}`} sx={{ overflow: 'auto' }}>
                                            <TableCell style={{ borderBottom: 'none', paddingLeft: 30 }}>
                                                <Box display="flex" alignItems="center" justifyContent="space-between">
                                                    <div>{tag?.name}</div>
                                                    <div>
                                                        {tag.id !== undefined && (
                                                            <React.Fragment>
                                                                {user && ( user.appRoleId === admin || user.appRoleId === manager) ? <IconButton
                                                                    onClick={() => {
                                                                        dispatch(openEditTagDialog(tag));
                                                                    }}
                                                                    sx={{ color: whiteColor, mr: 2 }}
                                                                >
                                                                    <EditIcon />
                                                                </IconButton> : <></>}
                                                                {user && ( user.appRoleId === admin || user.appRoleId === manager) ? <IconButton
                                                                    onClick={() => {
                                                                        dispatch(openRemoveTagDialog(tag.id));
                                                                    }}
                                                                    sx={{ color: whiteColor, mr: 2 }}
                                                                >
                                                                    <DeleteIcon />
                                                                </IconButton>: <></>}
                                                            </React.Fragment>
                                                        )}
                                                    </div>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Card>

                    <Box sx={{ position: 'absolute', bottom: '-10px', right: '-10px' }}>
                        {user && ( user.appRoleId === admin || user.appRoleId === manager) ? <IconButton
                            sx={{ backgroundColor: greenColor, color: whiteColor, "&:hover": { backgroundColor: darkGreenColor } }}
                            size='large'
                            onClick={createTag}
                        >
                            <Add />
                        </IconButton>: <></>}
                    </Box>
                </Box>
            </Box>
            <RemoveProjectDialog />
            <SaveTaskTagDialog />
        </Box>
    );
}
