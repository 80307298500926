import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { emptyString, recurringN, requestLoading, requestMissed, requestPending, requestSuccessful, selectDateOfPaymentLesserFail, TypeOfMoneys } from "../../../constants/constants";
import {getProjectServices, getProjectsFromClients, putSale} from "../../http/httpClient";
import { Client } from "../../models/clientModel";
import { Project } from "../../models/projectModel";
import { SaleDTO } from "../../models/saleModelDTO";
import { fetchGetPaginatedSales } from "../sales/salesSlice";
import { RootState } from "../store";
import {projectService} from "../../models/projectServiceModel";

const namespace = 'editSaleDialog'

export const fetchProjectsFromClients = createAsyncThunk(
    `${namespace}/fetchGetProjectsFromClients`,
    async () => {
       return getProjectsFromClients();
    }
);

export const fetchProjectServices = createAsyncThunk(
    `${namespace}/fetchProjectServices`,
    async () => await getProjectServices(false)
);


export const updateSale = createAsyncThunk(
    `${namespace}/updateSale`,
    async ({offset, limit, month, year, searchTerm}: {offset: number; limit: number; month: string; year: string, searchTerm: string},thunkAPI) => {
        const { editSaleDialog : state } = thunkAPI.getState() as RootState;
        const { id,dateSale, document,number ,concept ,currency ,netOrigin ,tax ,total ,projectId ,dateOfPayment ,recurring  } = state.selectedData;
        const saleData = {
            id,dateSale, document,number ,concept ,currency ,netOrigin ,tax ,total ,projectId ,dateOfPayment ,recurring
        }
        await putSale(saleData);
        thunkAPI.dispatch(closeEditDialog())
        thunkAPI.dispatch(fetchGetPaginatedSales({offset: offset,limit: limit,month: month, year:year, searchTerm:searchTerm}))
    },
); 


const initialState = {
    isOpen:false,
    id:0,
    clientList: new Array<Client>(),
    statusClientList: requestPending,
    projectServices : new Array<projectService>(),
    projectServicesStatus: requestPending,
    selectedData: {
        id:0,
        clientId: 0,
        projectId : 0,
        clientProjects: new Array<Project>(),
        project: new Project(),
        dateSale: new Date(),
        document : emptyString,
        number : emptyString,
        projectCode : emptyString,
        client: emptyString,
        concept : emptyString,
        currency : TypeOfMoneys[1],
        netOrigin : 0,
        tax : 0,
        total : 0,
        dateOfPayment : emptyString,
        recurring:recurringN,
        serviceId:0,
    },
    status: requestPending
}

const compareDate = (date1 : Date, date2: Date) : boolean => {
    if (date1.valueOf() <= date2.valueOf()) 
        return true;
    else 
        return false; 
}

export const editSaleDialog = createSlice({
    name : namespace,
    initialState : initialState,
    reducers:{
        openEditDialog: (state,action) => {
            state.isOpen = true;
            if(action.payload){
                const sale = action.payload as SaleDTO;
                state.selectedData.id = sale.id;
                state.selectedData.serviceId = sale.serviceId;
                state.selectedData.clientId = sale.clientId;
                state.selectedData.client = sale.client;
                state.selectedData.projectId = sale.projectId;
                state.selectedData.dateSale = sale.dateSale;
                state.selectedData.document = sale.document;
                state.selectedData.number = sale.number;
                state.selectedData.concept = sale.concept;
                state.selectedData.currency = sale.currency;
                state.selectedData.netOrigin = sale.netOrigin;
                state.selectedData.projectCode = sale.projectCode;
                state.selectedData.tax = sale.tax;
                state.selectedData.total = sale.total;
                state.selectedData.dateOfPayment = sale.dateOfPayment ? sale.dateOfPayment : emptyString;
                state.selectedData.recurring = sale.recurring;
                if(sale.client){
                    const proj = state.clientList.find((c : Client) => c.id === sale.clientId)?.projects;
                    if(proj){
                        state.selectedData.clientProjects = proj;
                    }
                    state.selectedData.clientId = sale.clientId;
                }
            }
        },
        closeEditDialog:(state) => {
            state.isOpen = false;
        },
        setProjectId: (state, action) => {
            state.selectedData.projectId = action.payload;
        },
        setProject: (state, action) => {
            state.selectedData.project = action.payload;
        },
        setDateSale: (state, action) => {
            state.selectedData.dateSale = new Date(action.payload)
        },
        setDocument: (state, action) => {
            state.selectedData.document = action.payload;
        },
        setNumber: (state, action) => {
            state.selectedData.number = action.payload;
        },
        setConcept: (state, action) => {
            state.selectedData.concept = action.payload;
        },
        setCurrency: (state, action) => {
            state.selectedData.currency = action.payload;
        },
        setNetOrigin: (state, action) => {
            state.selectedData.netOrigin = +action.payload;
            state.selectedData.total = +action.payload + state.selectedData.tax
        },
        setProjectServiceId: (state, action) => {
            state.selectedData.serviceId = action.payload;
        },
        setTax: (state, action) => {
            state.selectedData.tax = +action.payload;
            state.selectedData.total = +action.payload + state.selectedData.netOrigin;
        },
        setDateOfPayment: (state, action) => {
            state.selectedData.dateOfPayment = action.payload
        },
        setClientProjects: (state, action) => {
            state.selectedData.clientProjects = action.payload;
        },
        setClientId: (state, action) => {
            state.selectedData.clientId = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchProjectsFromClients.pending, (state) => {
            state.statusClientList = requestLoading;
        }).addCase(fetchProjectsFromClients.fulfilled, (state, action) => {
            state.clientList = action.payload;
            state.statusClientList = requestSuccessful;
        }).addCase(fetchProjectsFromClients.rejected, (state) => {
            state.statusClientList = requestMissed;
        });

        builder.addCase(fetchProjectServices.pending, (state) => {
            state.projectServicesStatus = requestLoading;
        }).addCase(fetchProjectServices.fulfilled, (state, action) => {
            state.projectServices = action.payload;
            state.projectServicesStatus = requestSuccessful;
        }).addCase(fetchProjectServices.rejected, (state) => {
            state.projectServicesStatus = requestMissed;
        });
    }
})

export const editSaleDialogReducer = editSaleDialog.reducer;
export const { openEditDialog, closeEditDialog, setProjectServiceId, setConcept, setCurrency, setDateOfPayment,
    setDateSale, setDocument, setNetOrigin, setNumber, setProject, setProjectId, setTax, 
    setClientProjects, setClientId } = editSaleDialog.actions;