import { Box, Card, IconButton, Typography } from "@mui/material";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../core/state/hooks";
import { fetchMonthlyRecords, setWeekDayOfFirstDayOfMonth } from "../../../core/state/monthlyTracker/monthlyTrackerSlice";
import { sunday, saturday, lightWhiteBg} from "../../../constants/constants";
import DayOfTheWeekColumn from "./dayOfTheWeekColumn";
import { ArrowBackIosRounded, ArrowForwardIosRounded } from "@mui/icons-material";
import { addMonths} from "date-fns";
import { selectDate } from "../../../core/state/date/dateSlice";
import { fetchWeeklyRecords } from "../../../core/state/dailyTracker/dailyTrackerSlice";

export default function MonthlyTracker() {
    const dispatch = useAppDispatch();
    const { user } = useAppSelector((state) => state.user);
    const { monthlyRecords } = useAppSelector((state) => state.monthlyTracker);
    const { weeklyViewClicked } = useAppSelector((state) => state.dailyTracker);
    const { selectedDate } = useAppSelector((state) => state.date);

    useEffect(() => {
        dispatch(fetchMonthlyRecords(user?.id));
    }, [dispatch, user?.id]);

    const fetchData = (date:Date)=>{
        dispatch(selectDate(date));
        let firstDayOfMonth = new Date(date.getTime());
        firstDayOfMonth.setDate(1);
        let dayNumber = firstDayOfMonth.getDay();
        dispatch(setWeekDayOfFirstDayOfMonth(dayNumber));
        dispatch(fetchMonthlyRecords(user?.id));
        dispatch(fetchWeeklyRecords(user?.id));
    }

    const getMonthName = (month: number) => {
        switch (month) {
            case 0: return 'ENE';
            case 1: return 'FEB';
            case 2: return 'MAR';
            case 3: return 'ABR';
            case 4: return 'MAY';
            case 5: return 'JUN';
            case 6: return 'JUL';
            case 7: return 'AGO';
            case 8: return 'SEP';
            case 9: return 'OCT';
            case 10: return 'NOV';
            case 11: return 'DIC';
        }
    }

    function handleArrowClick(monthsChange: number) {
        fetchData(addMonths(selectedDate, monthsChange));
    }

    return (
        <Box display={"flex"}  flex={ weeklyViewClicked? 0 : 1} flexDirection="column" mt={{xs: 3, md: 0}} sx={{transition: "all 2s ease"}} id="myelement">
            <Box display={{display: weeklyViewClicked? "none" : "flex"}} flexDirection={"row"}  alignItems={"center"} my={1} justifyContent={{xs:"start",md:"end"}} sx={{width: weeklyViewClicked? 0 : "auto"}}>
                <IconButton sx={{ml:3}} children={<ArrowBackIosRounded />} onClick={() => {handleArrowClick(-1)}} />
                <IconButton sx={{ml:3}} children={<ArrowForwardIosRounded />} onClick={() => {handleArrowClick(1)}} />
                <Typography sx={{ml:5}} variant="h5">
                    {getMonthName(selectedDate.getMonth())} {selectedDate.getFullYear()}
                </Typography>
                <Box flex={{xs:0, md:0.3}}></Box>
            </Box>
            <Card sx={{display: weeklyViewClicked? "none" : "flex", justifyContent:"space-between", bgcolor:lightWhiteBg, borderRadius:7, flex: 1}}>
            {
                monthlyRecords && monthlyRecords.map((dayOfTheWeekRecords,index) => <DayOfTheWeekColumn dayOfTheWeek={index === saturday? sunday : index + 1} 
                exteriorColumn={index === 0 || index === dayOfTheWeekRecords.length + 1} records={index === saturday? monthlyRecords[sunday] : monthlyRecords[index + 1]}
                key={index}/> )
            }
            </Card>
        </Box>       
    )
}