import { Tag } from "./tagModel";

export class RecordDTO {
    id: number = 0;
    date: Date | undefined;
    timeQty: String = '00:00';
    timeStamp: Date = new Date();
    description: string = '';
    ticketId: string = '';
    resourceId : number = 0;
    taskId : number =0 ;
    stageId : number = 0;
    projectId : number  = 0;
    projectName : string = '';
    clientName : string = '';
    taskName : string = '';
    tagRecords: Array<Tag> = new Array<Tag>();
}