import { IPublicClientApplication } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { Box, Button } from '@mui/material';
import React from 'react';
import Background from "../../../assets/login_background.svg";
import { ReactComponent as InTrackerLogo } from "../../../assets/Logo_InTracker.svg";
import { darkGreenColor, greenColor, whiteColor } from '../../constants/constants';
import { loginRequest } from '../../core/authorization/authConfig';

function signInClickHandler(instance: IPublicClientApplication) {
   
     instance.loginRedirect(loginRequest).catch(e => {
        console.error(e);
    });
}

export default function Login() {
    const { instance } = useMsal();

    return (
        <Box display={"flex"} flex={1} justifyContent="center" alignItems="center" flexDirection="column" sx={{backgroundImage:`url(${Background})`, backgroundSize:"cover", borderBottom:"2px #8E54E9 solid"}}>
            <InTrackerLogo width={300} height={80}/>
            <Button sx={{color:whiteColor,backgroundColor:greenColor,width:250,mt:10,"&:hover":{backgroundColor:darkGreenColor}}} 
            onClick={() => signInClickHandler(instance)}>Ingresar</Button>
        </Box>
    )   
}
