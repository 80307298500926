import { useEffect } from 'react';
import { Box, Button, Dialog, DialogContent, FormControl, FormLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { darkGreenColor, greenColor, lightGrayColor, whiteColor } from "../../constants/constants";
import { fetchSubAreas, fetchResourceIncome, fetchAreas, closeSaveResourceDialog, setDateUpdate,
        setArea, setSubArea, setLiquid,setHours,setNominal,setRole,setTypeOfMoney, saveResource, fetchCompanyRole,
    } from "../../core/state/dialogs/saveResourceDialogSlice";
import { useAppDispatch, useAppSelector } from "../../core/state/hooks";
import defaultUserImage from './../../../assets/icons/default-profile-icon.png';
import { ReactComponent as EditIcon } from "../../../assets/icons/EditIcon.svg";
import { LocalizationProvider, DatePicker } from '@mui/lab';
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import React from "react";
import { CompanyRole } from '../../core/models/companyRoleModel';
import { SubArea } from '../../core/models/subAreaModel';
import { Area } from '../../core/models/areaModel';

export default function SaveResourceDialog() {
    const dispatch = useAppDispatch();
    const [disabled, setDisabled] = React.useState(true);
    const { isOpen: dialogIsOpen, subAreas,areas, moneys,weekHours, selectedData, companyRoleList} = useAppSelector((state) => state.saveResourceDialog);
    const {selectedArea, page, rowsPerPage, searchFilter} = useAppSelector((state) => state.resources);
    const [style, setStyle] = React.useState({
        backgroundColor:lightGrayColor,
        borderRadius:4,
        border: 2,
        borderColor:lightGrayColor
    })

    const editActive = {backgroundColor:lightGrayColor,borderRadius:4,border : 2, borderColor:greenColor}
    const editInactive = {backgroundColor:lightGrayColor,borderRadius:4,border: 2, borderColor:lightGrayColor}

    const saveUpdateResource = () => {
        handleClickEdit();
        dispatch(saveResource({resourceId: selectedData.id,offset: page*rowsPerPage,limit: rowsPerPage,area: selectedArea, searchTerm: searchFilter}));
        dispatch(closeSaveResourceDialog());
    }

    const handleClickEdit = () => {
        if(disabled){
            setDisabled(false);
            setStyle(editActive)
        } else {
            setDisabled(true);
            setStyle(editInactive)

        }
    }
    useEffect(() => {
        if(dialogIsOpen){
            dispatch(fetchResourceIncome(selectedData.id))
            dispatch(fetchSubAreas());
            dispatch(fetchAreas());
            dispatch(fetchCompanyRole());
        }
      },[dispatch, dialogIsOpen])


    return (
        <Dialog open={dialogIsOpen} maxWidth="sm"
            onClose={() => {
                dispatch(closeSaveResourceDialog());
                setDisabled(true);
                setStyle(editInactive)
                }}>
            <DialogContent sx={{display:"flex",flexDirection:"column"}}>
                <DialogContent sx={{display:"flex", justifyContent:"center" , pb:'0px !important', position:'relative', overflowY: 'visible'}} >
                    <Box sx={{display:"flex", alignItems:"center"}}>
                        <Box sx={{mr:1}}>
                            <img  className='w-100 green-bg border-r-50per-green' src={ selectedData.image ? `data:image/jpeg;base64,${selectedData.image}` : defaultUserImage} alt='' />
                        </Box>
                        <Box sx={{ml:1}}>
                            <Typography variant="h4" >{selectedData.name + " " + selectedData.lastName}</Typography>
                            <span style={{fontSize: 18,}}>
                                {subAreas.map((subarea : SubArea) => subarea.id === selectedData.subAreaId ? (subarea.name):'')}
                            </span>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogContent sx={{overflow: 'visible',display:"flex", justifyContent:"space-around"}}>
                    <FormControl sx={{width:250, ml:4,mr:2}}>
                        <FormLabel><Box sx={{ fontSize: 18}}>Area</Box></FormLabel>
                        <Select
                            disabled={disabled} id="area-select" label="Area"
                            value={selectedData.areaId}
                            onChange={(event) => {
                                dispatch(setArea(event.target.value))
                            }}
                            sx={style}
                        >
                            {areas && areas.map((area : Area) => {
                                return <MenuItem value={area.id} key={area.id}>{area.name}</MenuItem>;
                            })}
                        </Select>
                    </FormControl>

                    <FormControl sx={{width:250, ml:2,mr:4}}>
                        <FormLabel><Box sx={{ fontSize: 18}}>Subarea</Box></FormLabel>
                        <Select
                            id="subarea-select"
                            label="Subarea"
                            value={selectedData.subAreaId}
                             onChange={(event) => {
                                dispatch(setSubArea(event.target.value))
                            }}
                            disabled={disabled}
                            sx={style}
                        >
                            {subAreas && subAreas.map((subArea : SubArea) => {
                                return <MenuItem disabled={subArea.areaId === selectedData.areaId ? false : true} value={subArea.id} key={subArea.id}>{subArea.name}</MenuItem>;
                            })}
                        </Select>
                    </FormControl>
                </DialogContent>

                <DialogContent sx={{overflow: 'visible',display:"flex", justifyContent:"space-around"}}>
                   <FormControl sx={{width:250, ml:4,mr:2}}>
                        <FormLabel><Box sx={{ fontSize: 18}}>Rol</Box></FormLabel>
                        <Select value={selectedData.companyRoleId} disabled={disabled}
                            sx={style} onChange={ event => dispatch(setRole(event.target.value))}>
                            {companyRoleList.map((role : CompanyRole) => {
                                return <MenuItem value={role.id} key={role.id}>{role.name}</MenuItem>
                            })}
                        </Select>
                   </FormControl>

                   <FormControl sx={{width:250, ml:2,mr:4}}>
                       <FormLabel ><Box sx={{ fontSize: 18}}>Fecha Actualización</Box></FormLabel>
                       <FormControl sx={style}>
                           <LocalizationProvider dateAdapter={AdapterDateFns}>
                               <Box>
                                   <DatePicker disabled
                                        inputFormat="MM/yyyy"
                                        mask={"__/____"}
                                        value={selectedData.resourceIncomeUpdateDate}
                                        onChange={(date) => {
                                            dispatch(setDateUpdate(date?.toISOString().split('T')[0]))
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                               </Box>
                           </LocalizationProvider>
                       </FormControl>
                   </FormControl>
                </DialogContent>

                <DialogContent sx={{overflow: 'visible',display:"flex", justifyContent:"space-around"}}>
                    <FormControl sx={{width:250, ml:4,mr:2}}>
                        <FormLabel><Box sx={{ fontSize: 18}}>Horas diarias</Box></FormLabel>
                        <Select
                            value={selectedData.resourceHours}  sx={style}
                            onChange={event => {dispatch(setHours(event.target.value))}} disabled={disabled}>
                            {weekHours && weekHours.map((hour) => {
                                return <MenuItem value={hour.id} key={hour.id}>{hour.hours + " horas"}</MenuItem>;
                            })}
                        </Select>
                    </FormControl>

                    <FormControl sx={{width:250, ml:2,mr:4}}>
                        <FormLabel><Box sx={{ fontSize: 18}}>Moneda</Box></FormLabel>
                        <Select value={selectedData.typeOfMoneyID} disabled={disabled}
                       sx={style} onChange={event => dispatch(setTypeOfMoney(event.target.value))}>
                            {moneys && moneys.map((money) => {
                                return <MenuItem value={money.id} key={money.id}>{money.name}</MenuItem>;
                            })}
                        </Select>
                    </FormControl>
                </DialogContent>

                <DialogContent sx={{overflow: 'visible',display:"flex", justifyContent:"space-around"}}>
                    <FormControl sx={{width:250, ml:4,mr:2}}>
                        <FormLabel ><Box sx={{ fontSize: 18}}>Sueldo Nominal</Box></FormLabel>
                        <TextField value={selectedData.resourceNominal} disabled={disabled}
                        sx={style} onChange={event => dispatch(setNominal(event.target.value))}/>
                    </FormControl>

                    <FormControl sx={{width:250, ml:2,mr:4}}>
                        <FormLabel ><Box sx={{ fontSize: 18}}>Sueldo Liquido</Box></FormLabel>
                        <TextField value={selectedData.resourceLiquid} disabled={disabled}
                         sx={style}  onChange={event => dispatch(setLiquid(event.target.value))}/>
                    </FormControl>

                </DialogContent>
                <DialogContent sx={{overflow: 'visible',display:"flex", justifyContent:"space-between", alignItems:"center"}} >
                    <Box onClick={handleClickEdit} sx={{ml:4, p:1, ":hover":{cursor: "pointer", backgroundColor:lightGrayColor, borderRadius:4}}}>
                        <EditIcon />
                    </Box>
                    <Button onClick={saveUpdateResource} disabled={disabled} sx={{backgroundColor:greenColor, color:whiteColor, width:250, mr:4, '&:hover': {backgroundColor: darkGreenColor,}}}>
                        <Box sx={{ fontSize: 18, pb:1,pt:1}}>
                        Actualizar
                        </Box>
                    </Button>
                </DialogContent>
            </DialogContent>
        </Dialog>
        );
}