import { Box, Typography } from '@mui/material';
import '../../../App.css';
import { useAppSelector } from '../../core/state/hooks';
import DailyTracker from './dailyTracker/dailyTracker';
import MonthlyTracker from './monthlyTracker/monthlyTracker';

export default function Home() {
  const { weeklyViewClicked } = useAppSelector((state) => state.dailyTracker);

  return (
    <Box component="main" display={"flex"} flex={1} flexDirection="column">
      <Typography variant='h5' sx={{ borderBottom: 2, borderBottomColor: 'rgba(35, 31, 32, 0.1)', pb: 2 }}> Daily Tracker</Typography>
      <Box display={"flex"} flex={1} flexDirection={{xs:"column",md:"row"}} flexWrap="wrap">
        <DailyTracker />
        <Box flex={ weeklyViewClicked? 0 : 0.025}></Box>
        <MonthlyTracker />
      </Box>
    </Box>
  );
}
