import { createSlice } from "@reduxjs/toolkit";

const namespace = "sideBar"

export const sideBarSlice = createSlice({
    name:`${namespace}`,
    initialState:{
        left: false
    },
    reducers:{
        toggleSideBar:(state) =>{
            state.left = !state.left;
        }
    }
})
export const {toggleSideBar} = sideBarSlice.actions;

export const sideBarReducer =  sideBarSlice.reducer;