
import { Box, Typography, TextField, IconButton, MenuItem, Select, Button, Card,
    Table, TableBody, TableHead, TableRow, TableCell, TablePagination } from "@mui/material";
import { Search, Add } from '@mui/icons-material';
import { admin, darkGrayColor, darkGreenColor, emptyString, greenColor, lightGrayColor, monthList, noneValue, pm, superAdmin, whiteColor
,yearList } from "../../constants/constants";
import React, { useEffect } from "react";
import  {ReactComponent as BtnSaleIcon} from "../../../assets/icons/btnSales.svg"
import { ReactComponent as DeleteIcon } from "../../../assets/icons/DeleteIcon.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/EditIcon.svg";
import { useAppDispatch, useAppSelector } from "../../core/state/hooks";
import {
    fetchGetPaginatedSales,
    cancelNewRecurrency,
    setChangedRecurrences,
    selectSearchFilter,
    selectedMonthFilter,
    addNewsRecurrency,
    selectYearFilter,
    cleanFilter,
    setRowsPerPage,
    setPage,
    changeElementRecurrency,
    fetchRecurringSales, setSystemRecurrences
} from "../../core/state/sales/salesSlice";
import { SaveSaleDialog } from "./saveSaleDialog";
import { openSaveSaleDialog } from "../../core/state/dialogs/saveSaleDialogSlice";
import SalesRows from "./salesRows";
import { openRemoveDialog } from "../../core/state/dialogs/removeSaleDialogSlice";
import { openEditDialog } from "../../core/state/dialogs/editSaleDialogSlice";
import RemoveSaleDialog from "./removeSaleDialog";
import EditSaleDialog from "./editSaleDialog";

interface Column {
    id: 'CheckBox' | 'Fecha' | 'Cliente' | 'Documento' | 'NDeDoc' | 'Concepto'| 'Servicio' |
    'CentroDeCosto' | 'Moneda' | 'NetoOrigen' | 'IVA' | 'Total' | 'FDeCobro';  
    label: string;
    minWidth?: number;
}

const columns: readonly Column[] = [
    { id: 'CheckBox', label: '', minWidth:45},
    { id: 'Fecha', label: 'Fecha', minWidth: 100 },
    { id: 'Cliente', label: 'Cliente', minWidth: 175 },
    { id: 'Documento', label: 'Documento', minWidth: 150 },
    { id: 'NDeDoc', label: 'N. de Doc', minWidth: 150,}, 
    { id: 'Concepto', label: 'Concepto', minWidth: 250,},
    { id: 'Servicio', label: 'Servicio',minWidth: 225, },
    { id: 'CentroDeCosto', label: 'Centro de costo',minWidth: 200, },
    { id: 'Moneda', label: 'Moneda',minWidth: 100, },
    { id: 'NetoOrigen', label: 'Neto Origen',minWidth: 150, },
    { id: 'IVA', label: 'IVA',minWidth: 100, },
    { id: 'Total', label: 'Total',minWidth: 150, },
    { id: 'FDeCobro', label: 'F. de Cobro',minWidth: 250, },
];

export default function SalesAux() {
    const dispatch = useAppDispatch();
    const { user } = useAppSelector((state) => state.user);
    const { page, rowsPerPage, sales, salesRecurrences, selectedMonth,allRecurrencyInTheSystem, searchFilter, yearFilter,
         resourcesCount} = useAppSelector((state) => state.sales);
    const [ leftTable, setLeftTable] = React.useState(true);
    const [ currentSearch, setCurrentSearch] = React.useState(emptyString);
    const style = {
        transform: leftTable ?   '':'rotate(180deg)', 
        transition: 'transform 250ms ease-in-out', // smooth transition
    }
    const [visibility, setVisibility] = React.useState(noneValue);
    const tableScrollBar = React.useRef<HTMLDivElement>(null);


    const salesLength = sales.length;    

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        dispatch(setPage(newPage));
    };

    const changeVisibility = () => {
        if (visibility === noneValue) {
            setVisibility(emptyString)
        }else{
            setVisibility(noneValue)
        }
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setRowsPerPage(+event.target.value));
        dispatch(setPage(0));
    };
    const handleClickAddRecurrency = () => {
        const arr1 = allRecurrencyInTheSystem;
        const arr2 = salesRecurrences
        const onlyInArr1 = arr1.filter((num) => !arr2.includes(num));
        const onlyInArr2 = arr2.filter((num) => !arr1.includes(num));
        dispatch(setChangedRecurrences([...onlyInArr1, ...onlyInArr2]));
        dispatch(setSystemRecurrences(salesRecurrences));
        dispatch(addNewsRecurrency({offset: page*rowsPerPage, limit: rowsPerPage, month: selectedMonth, year: yearFilter.toString(), searchTerm: searchFilter}));
        changeVisibility();
    }
    const handleClickCancelRecurrency = () => {
        dispatch(cancelNewRecurrency());
        changeVisibility();
    }
    
    const handleChange = (event:any) => {
        const value = event.target.value;
        dispatch(changeElementRecurrency(value))
    } 
   
    const handleMoveScrollBar = () => {

        (tableScrollBar.current as HTMLDivElement).scrollLeft = (leftTable ? 10000 : 0);
        setLeftTable(!leftTable);
    }

    
    const changeDirectionArrow = () => {
        if((tableScrollBar.current as HTMLDivElement).scrollLeft === 0)
            setLeftTable(true);
        else
            setLeftTable(false);
    }

    const onClickCleanFilter = () => {
        setCurrentSearch(emptyString);
        dispatch(selectSearchFilter(emptyString));
        dispatch(cleanFilter());
        dispatch(setPage(0))
    }
    function useRunOnce(callback: () => void) {
        useEffect(() => {
            callback();
        }, []);
    }
    useRunOnce(() => {
        dispatch(fetchRecurringSales())
    });

    useEffect(() => {
        dispatch(fetchGetPaginatedSales({offset: page*rowsPerPage,limit: rowsPerPage,month: selectedMonth, year:yearFilter.toString(), searchTerm: searchFilter}));
        (tableScrollBar.current as HTMLDivElement).addEventListener("scroll",changeDirectionArrow)
    },[dispatch, searchFilter, rowsPerPage, page, selectedMonth, yearFilter]);



    const isEmpty = (t : string) : boolean => (t === emptyString)
    
    const controlHasFilter = () => {
        if(!isEmpty(searchFilter) || (yearFilter !== (new Date()).getFullYear()) || (selectedMonth !== monthList[0])){
            return emptyString;
        }
        return noneValue;
    }

    return (
        <Box component="main" display={"flex"} flex={1} flexDirection="column" bgcolor={lightGrayColor} sx={{width:`500px`}}>
            <Box display={"flex"} flexDirection="row" borderBottom={2} borderColor='rgba(35, 31, 32, 0.1)' pb={1} alignItems="center" >
                <Typography variant='h5'>Ventas</Typography>
                <Box display={"flex"} flex={0.05}/>
                    <TextField 
                        value={currentSearch}  
                        onChange={ (event) => { 
                            setCurrentSearch(event.target.value);
                        }} 
                        sx={{backgroundColor:whiteColor,boxShadow:"2px 2px 5px rgba(35, 31, 32, 0.1)",borderRadius:5,color:greenColor,mx:2}} />
                    <IconButton onClick={()=>{dispatch(selectSearchFilter(currentSearch));  dispatch(setPage(0));}} sx={{color:whiteColor,backgroundColor:greenColor,mr:2}}>
                        <Search/>
                    </IconButton>
                    <Button onClick={() => {onClickCleanFilter();}} sx={{color:"#918F8F",border:2,borderColor:"#918F8F",display:controlHasFilter}}>
                        Borrar filtros
                    </Button>
                    <Box flex={1}></Box>
                    <Select
                        label="Año"
                        value={yearFilter}
                        onChange={(event)=>{dispatch(selectYearFilter(event.target.value)); dispatch(setPage(0))}}
                        sx={{flex:0.4,mr:1,borderRadius:4,background:whiteColor,color:darkGrayColor}}
                    >
                        {
                        yearList.map((year)=> <MenuItem value={year} key={year}> {year} </MenuItem>)
                        }
                    </Select>
                    <Select
                        value={selectedMonth}
                        label="Mes" 
                        onChange={(event)=>{dispatch(selectedMonthFilter(event.target.value)); dispatch(setPage(0))}}
                        sx={{flex:0.4,mr:1,borderRadius:4,background:whiteColor,color:darkGrayColor}}>
                        {  monthList.map((month : any)=> <MenuItem value={month} key={month}> {month} </MenuItem>) }
                    </Select>
                    <Box sx={{ml:2}}>
                        <Box display={visibility !== noneValue ? noneValue : emptyString}>
                            <Button onClick={changeVisibility} sx={{backgroundColor:greenColor,color:whiteColor,py:1,px:4,'&:hover': {backgroundColor: darkGreenColor,}}} >
                                Agregar recurrente +
                            </Button>
                        </Box>
                        <Box display={visibility === noneValue ? noneValue : emptyString}>
                            <Button onClick={handleClickCancelRecurrency} sx={{backgroundColor:darkGrayColor,color:whiteColor,mr:0.425,py:1,px:3,'&:hover': {backgroundColor: darkGreenColor,}}} >
                                Cancelar
                            </Button>
                            <Button onClick={() => {handleClickAddRecurrency()}}  sx={{backgroundColor:greenColor,color:whiteColor, ml:0.425,py:1,px:3,'&:hover': {backgroundColor: darkGreenColor,}}} >
                                Guardar
                            </Button>
                        </Box>
                    </Box>
                    <Box flex={0.1}>
                </Box>
            </Box>

            <Card  sx={{mt:2, borderRadius:12.5,bgcolor:whiteColor, width:'100%'}}>
                <Box sx={{display:'flex', width:'100%', justifyContent:'space-between'}}>
                    <Box sx={{overflowX:'scroll'}} id={"scrollbar"} ref={tableScrollBar} >
                        <Table sx={{overflowX:'scroll', width:'95%'}} stickyHeader aria-label="sticky table" >
                            <TableHead>
                                <TableRow key={`tableHeadKey`}>
                                    {columns.map((column) => (
                                        <TableCell 
                                                key={column.id} align="center"
                                                style={{ minWidth: column.minWidth }}
                                                sx={{borderBottom:3,borderColor:greenColor}}
                                            >
                                            <Typography variant='h6'>{column.label}</Typography>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sales.map((sale) => {
                                    return (
                                        <SalesRows key={`${sale.client}-${sale.id}`} sale={sale} visibility={visibility} isChecked={salesRecurrences.includes(sale.id)} option={sale.id} handleOnChange={handleChange} />
                                        );
                                })}
                            </TableBody>
                        </Table>
                    </Box>
                    <Table sx={{ display:'flex', width:'5%',flexDirection:'column', alignItems:'center' }}>
                        <TableHead sx={{maxHeight:'60px'}}>
                            <TableRow key={`botonLeftTableKey`}  >
                                <TableCell sx={{borderBottom:3,borderColor:greenColor,py:'6.8px'}}>
                                        <IconButton style={style} onClick={handleMoveScrollBar} children={<BtnSaleIcon  />} /> 
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sales.map((sale) => {
                                return (
                                    <TableRow key={`btn-${sale.id}`}  >
                                        <TableCell sx={{ display:'flex',height:'53px',px:0,py:'0px'}}  >
                                            {(user.appRoleId === admin || user.appRoleId === superAdmin)? 
                                            <IconButton sx={{height:'53px'}} onClick={() => {
                                                dispatch(openEditDialog(sale))
                                                }}  
                                                children={<EditIcon />} />:<></>}
                                            {(user.appRoleId === admin || user.appRoleId === superAdmin) ?
                                            <IconButton onClick={() => {
                                                dispatch(openRemoveDialog(sale.id))
                                                }} 
                                                children={<DeleteIcon />}/>:<></>}
                                        </TableCell> 
                                    </TableRow> 
                                );
                            })}
                        </TableBody>
                    </Table>
                </Box>

                <Box  sx={{display:'flex', flexDirection:'row-reverse', alignItems:'center',justifyContent:'space-between'}}>
                    <Box sx={{dispaly:'flex'}}>
                        {
                            user && (user.appRoleId === admin || user.appRoleId === pm || user.appRoleId === superAdmin)?
                            <Box sx={{position:'absolute', right:'5px', mt:'25px'}} children={<IconButton  
                                sx={{  backgroundColor:greenColor, 
                                    color:whiteColor,
                                    "&:hover":{backgroundColor:darkGreenColor}}} size='large'
                                    onClick={() => { dispatch(openSaveSaleDialog({sale: undefined}))}}
                                    children={<Add/>} />} /> : <></>
                        }
                        <TablePagination
                            rowsPerPageOptions={[6, 12, 18, 24, 30, { label: 'All', value: resourcesCount }]} 
                            sx={{ border: 0, display:'flex', flexDirection:'row-reverse',mr:'30px'}} 
                            colSpan={10} 
                            component='div'
                            count={salesLength === resourcesCount ? salesLength: resourcesCount} 
                            rowsPerPage={rowsPerPage}
                            page={page} 
                            onPageChange={handleChangePage} 
                            onRowsPerPageChange={handleChangeRowsPerPage} />
                    </Box>
                </Box>
            </Card>
            <SaveSaleDialog />
            <RemoveSaleDialog />
            <EditSaleDialog />
        </Box>        
    )
}





