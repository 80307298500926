import { IPublicClientApplication } from '@azure/msal-browser';
import axios, { AxiosRequestConfig } from 'axios';
import { addDays, startOfWeek } from 'date-fns';
import { toast } from 'react-toastify';
import { msalInstance } from '../../..';
import { graphConfig, loginRequest } from '../authorization/authConfig';
import { hideSpinner, startSpinning } from '../loading/loading';
import { Client } from '../models/clientModel';
import { record } from '../models/recordModel';
import { Resource } from '../models/resourceModel';
import { ResourceDTO } from '../models/resourceModelDTO';
import { PageDTO } from '../models/pageModelDTO';
import { Role } from '../models/roleModel';
import { Tag } from '../models/tagModel';
import { task } from '../models/taskModel';
import { Bonus } from  '../models/bonosModels'
import { User } from '../models/userModel';
import { Area } from '../models/areaModel';
import { addTimeQty } from '../time/time';
import { SubArea } from '../models/subAreaModel';
import { ResourceIncome } from '../models/resourceIncomeModel';
import { HistoricalResource } from '../models/historicalResourceModel';
import { CredentialField } from '../models/credentialFieldModel';
import { Process } from "../models/processModel";
import { Buffer } from 'buffer';

import {
    bonoCreateError,
    bonoCreateSuccess,
    clientCreateError,
    errorSave,
    exceedingSave,
    successSave,
    clientCreateSuccess,
    clientDeleteError,
    clientDeleteSuccess,
    clientUpdateError,
    clientUpdateSuccess,
    exceddingRecordsHours,
    projectCreateError,
    projectCreateSuccess,
    projectDeleteError,
    projectDeleteSuccess,
    projectUpdateError,
    projectUpdateSuccess,
    recordDeleteError,
    recordDeleteSuccess,
    userUpdateError,
    userUpdateSuccess,
    salesRecurrencesSuccess,
    salesRecurrencesError,
    saleDeleteError,
    saleDeleteSuccess,
    salesEditSuccess,
    salesEditError,
    purchasesRecurrencesSuccess,
    purchasesRecurrencesError,
    purchaseDeleteSuccess,
    purchaseDeleteError,
    purchasesEditSuccess,
    purchasesEditError,
    projectStageDeleteSuccess,
    projectStageDeleteError,
    projectStageCreateSuccess,
    projectStageCreateError,
    projectStageUpdateSuccess,
    projectStageUpdateError,
    taskDeleteSuccess,
    taskDeleteError,
    taskCreateSuccess,
    taskCreateError, tagCreateSuccess, tagCreateError, taskEditSuccess, taskEditError
} from '../../constants/constants';
import { SaleDTO } from '../models/saleModelDTO';
import { RecordDTO } from '../models/recordModelDTO'
import { Sale } from '../models/saleModel';
import { CompanyRole } from '../models/companyRoleModel';
import { Purchase } from '../models/purchaseModel';
import { SubSector } from '../models/subSectorModel';
import { Sector } from '../models/sectorModel';
import { Project } from '../models/projectModel';
import { PurchaseDTO } from '../models/purchaseModelDTO';
import { IndustryVertical} from "../models/IndustryVerticalModel";
import {projectService} from "../models/projectServiceModel";

const BASE_API_URL = 'https://intracker-api.azurewebsites.net' //PROD
// const BASE_API_URL = 'https://intracker-api-dev.azurewebsites.net' //DEV
// const BASE_API_URL = 'https://localhost:44320'
const GRAPH_RESOURCE = "graph.microsoft"

var callsLoading = 0;

const handleRequestFinally = (isLoading:boolean) => {
  if (isLoading){
    callsLoading--;
    if(callsLoading === 0)
      hideSpinner();
  }
}

axios.interceptors.request.use(
  
  async (config: AxiosRequestConfig<any>) => {
    if (!config?.headers) {
      throw new Error(`Expected 'config' and 'config.headers' not to be undefined`);
    }else if (config.headers.isLoading === 'true'){
      callsLoading++;
      startSpinning();
    }
    const resource = (config.url?.startsWith(graphConfig.graphMeEndpoint)) ? GRAPH_RESOURCE : "";
    const token = await RefreshAccessToken(msalInstance, resource);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error: any) => Promise.reject(error)
);

let get = (path: string, isLoading:boolean, params?:any) => {
    let config = {
      headers: {
        isLoading: isLoading.toString()
      },
      params
    }
    return new Promise((resolve,reject)=> {axios.get(`${BASE_API_URL}${path}`,config).then((response : any)=> resolve(response.data)).catch((error : any)=> reject(error) );})
}


let post = async (path: string, data: any,isLoading:boolean = true) => {
  let config = {
    headers: {
      isLoading: isLoading.toString(),
    }
  }
  return new Promise((resolve,reject)=> {axios.post(`${BASE_API_URL}${path}`,data,config).then((response: any)=> resolve(response.data)).catch((error : any)=> reject(error) );})
}
let put = async (path: string, data: any,isLoading:boolean = true) => {
  let config = {
    headers: {
      isLoading: isLoading.toString(),
    }
  }
  return new Promise((resolve,reject)=> {axios.put(`${BASE_API_URL}${path}`,data,config).then((response: any)=> resolve(response.data)).catch((error: any)=> reject(error) );})
}
let deleteEntity = async(path:string,isLoading:boolean = true) => {
  let config = {
    headers: {
      isLoading: isLoading.toString(),
    }
  }
  return new Promise((resolve,reject)=> {axios.delete(`${BASE_API_URL}${path}`,config).then((response: any)=> resolve(response.data)).catch((error: any)=> reject(error) );})
}

export let getResourceById = async (userId: number,isLoading:boolean = true) => {
  return await get(`/Resource/${userId}`,isLoading)
    .then((user)=> user as User)
    .catch((error) => new User())
    .finally(()=>{
      handleRequestFinally(isLoading);
    })
}

export let getResources = async (isLoading:boolean = true) => {
  return await get('/Resource',isLoading)
    .then((resources)=> resources as Array<Resource>)
    .catch((error) => new Array<Resource>())
    .finally(()=>{
      handleRequestFinally(isLoading);
    })
}

export let updateResource = async (resourceData:any, isLoading:boolean = true) => {
  return await put(`/Resource/UpdateResource`,resourceData,isLoading)
  .then(()=> toast.success(userUpdateSuccess))
    .catch((error)=> toast.error(userUpdateError))
    .finally(()=>{
      handleRequestFinally(isLoading);
    })
}
export let postResourceIncome = async (resourceIncomesData:any, isLoading : boolean = true) => {
  return await post(`/ResourceIncome`,resourceIncomesData,isLoading)
  .then((resourceIncomes) => {
    return resourceIncomes as Array<ResourceIncome>;
  })
  .catch((error) => {
    
    return new Array<ResourceIncome>()
  })
  .finally(() => {
    handleRequestFinally(isLoading);
  })
}

export let getAllResources = async (isLoading:boolean = true) => {
  return await get('/Resource/AllResources',isLoading)
    .then((resources)=> {
      return resources as Array<Resource>
    })
    .catch((error) => new Array<Resource>())
    .finally(()=>{
      handleRequestFinally(isLoading);
    })
}

export let getPaginatedResources = async (offset:number, limit:number, area:string,searchTerm: string = "", isLoading:boolean = true ) => {
  var pageData = {
    offset: offset,
    limit: limit,
    area: area,
    searchTerm: searchTerm
  }
  return await get('/Resource/Resources',isLoading,pageData)
    .then((resourcesPage)=> {
      return resourcesPage as PageDTO<ResourceDTO>
    })
    .catch((error) => new PageDTO<ResourceDTO>())
    .finally(()=>{
      handleRequestFinally(isLoading);
    })
}

export let getCredentialsFieldByResourceId = async (resourceId:number, isLoading : boolean = true) => {
  return await get(`/CredentialField/FromResource/${resourceId}`,isLoading)
    .then((credentialFields) => credentialFields as Array<CredentialField>)
    .catch((error) => new Array<CredentialField>())
    .finally( () =>
      handleRequestFinally(isLoading)
    )
}

export let getRecordsByResourceId = async (resourceId:number, isLoading : boolean = true) => {
  return await get(`/Record/FromResource/${resourceId}`,isLoading)
    .then((records) => records as Array<record>)
    .catch((error) => new Array<record>())
    .finally( () =>
      handleRequestFinally(isLoading)
    )
}

export let getStageHasHours = async (stageId: number, isLoading : boolean = true) => {
    return get(`/Stage/${stageId}/HasReportedHours`,isLoading)
        .then((stageHasHours) => stageHasHours as boolean)
        .catch((error) => false)
        .finally( () =>
        handleRequestFinally(isLoading)
        )
}

export let getStageHasHoursFromResource = async (stageId: number,resourceId: number, isLoading : boolean = true) => {
    return get(`/Stage/${stageId}/HasReportedHoursFromResource/${resourceId}`,isLoading)
        .then((stageHasHours) => stageHasHours as boolean)
        .catch((error) => false)
        .finally( () =>
            handleRequestFinally(isLoading)
        )
}
export let postHistoricalResource = async (historicalResourceData : any, isLoading : boolean = true) => {
  return await post(`/HistoricalResource`,historicalResourceData,isLoading)
  .then((historicalResource) => historicalResource as Array<HistoricalResource>)
  .catch((error) => {
    return new Array<HistoricalResource>();
  })
  .finally(() => {
    handleRequestFinally(isLoading);
  })
}

export let getResourceProjects = async (resourceId: number,isLoading:boolean = true) => {
  return await get(`/Resource/ResourceProjects/${resourceId}`,isLoading)
    .then((projectsByClient)=> projectsByClient as Client[])
    .catch((error) => new Array<Client>())
    .finally(()=>{
      handleRequestFinally(isLoading);
    })
}

export let getProjectTasks = async (projectId: number,isLoading:boolean = true) => {
  return await get(`/Project/ProjectTasks/${projectId}`,isLoading)
    .then((tasks)=> tasks as Array<task>)
    .catch((error)=> new Array<task>())
    .finally(()=>{
      handleRequestFinally(isLoading);
    })
}

export let getProjectTags = async (projectId: number,isLoading:boolean = true) => {
  return await get(`/Project/ProjectTags/${projectId}`,isLoading)
    .then((tags)=> tags as Array<Tag>)
    .catch((error)=> new Array<Tag>())
    .finally(()=>{
      handleRequestFinally(isLoading);
    })
}

export let getProjectProcesses = async (isLoading:boolean = true) => {
    return await get('/Process', isLoading)
        .then((process) => process as Array<Process>)
        .catch((error) => new Array<Process>())
        .finally(() => {
            handleRequestFinally(isLoading);
        })
}

export let getWeeklyRecords = async(resourceId: number, date: Date,isLoading:boolean = true) => {
  let strDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  let emptyWeek = new Array<{
    date: Date,
    records:Array<RecordDTO>,
    dayHoursQty: string,
  }>()
  for (let iDay = startOfWeek(date, { weekStartsOn: 1 }); iDay.getDay() <= 5; iDay = addDays(iDay, 1)) {
    let emptyDay = {
        date: iDay,
        records:new Array<RecordDTO>(),
        dayHoursQty: '00:00',
    }
    emptyWeek.push(emptyDay);
  }
  return await get(`/Record/${resourceId}/GetRecordsOfAWeek/${strDate}`,isLoading)
    .then((value)=> {
      const weeeklyRecords = value as Array<Array<RecordDTO>>;
      return emptyWeek.map((day,index)=>{
        day.records = index < weeeklyRecords.length ? weeeklyRecords[index] : new Array<RecordDTO>();
        day.records.forEach((record:RecordDTO) => { day.dayHoursQty = addTimeQty(day.dayHoursQty, record.timeQty.repeat(1)); });
        return day
      })
    })
    .catch((error) => {
      return emptyWeek;
    })
    .finally(()=>{
      handleRequestFinally(isLoading);
    })
}

export let getMonthlyRecords = async (resourceId: number, date: string,isLoading:boolean = true) => {
  return await get(`/Record/${resourceId}/GetMonthRecords/${date}`,isLoading)
    .then((records)=> records as Array<string>)
    .catch((error)=> new Array<string>())
    .finally(()=>{
      handleRequestFinally(isLoading);
    })
}

export let postSaveRecord = async (recordData: any,isLoading:boolean = true) => {
  return await post(`/Record/SaveRecord`, recordData,isLoading)
    .then(()=> {
      return successSave;
    })
    .catch((error)=> {
      if (error.request.response === exceddingRecordsHours){
        return exceedingSave;
      }
      return errorSave;
    })
    .finally(()=>{
      handleRequestFinally(isLoading);
    })
}

export let postClient = async (clientData: any,isLoading:boolean = true) => {
  return await post(`/Client`, clientData,isLoading)
    .then(()=> toast.success(clientCreateSuccess))
    .catch((error)=> toast.error(clientCreateError))
    .finally(()=>{
      handleRequestFinally(isLoading);
    })
}

export let postTask = async (name: any,isLoading:boolean = true) => {
    return await post(`/Task`, {name},isLoading)
        .then(()=> toast.success(taskCreateSuccess))
        .catch((error)=> toast.error(taskCreateError))
        .finally(()=>{
        handleRequestFinally(isLoading);
        })
}
export let putTask = async (id: number, name: any,isLoading:boolean = true) => {
    return await put(`/Task`, {id, name},isLoading)
        .then(()=> toast.success(taskEditSuccess))
        .catch((error)=> toast.error(taskEditError))
        .finally(()=>{
            handleRequestFinally(isLoading);
        })
}
export let postTag = async (name: any,isLoading:boolean = true) => {
    return await post(`/Tag`,{name},isLoading)
        .then(()=> toast.success(tagCreateSuccess))
        .catch((error)=> toast.error(tagCreateError))
        .finally(()=>{
        handleRequestFinally(isLoading);
        })
}
export let putTag = async (id: number, name: any,isLoading:boolean = true) => {
    return await put(`/Tag`,{id, name},isLoading)
        .then(()=> toast.success(tagCreateSuccess))
        .catch((error)=> toast.error(tagCreateError))
        .finally(()=>{
            handleRequestFinally(isLoading);
        })
}
export let getBonusData = async (resourceId: number, isLoading:boolean = true) => {
    return await get(`/Bonus/LastBonus/${resourceId}`,isLoading)
        .then((bonus)=> {
            return bonus as Bonus})
        .catch((error) => undefined)
        .finally(() => {
            handleRequestFinally(isLoading);
        })
}


export let postBonusData = async (bonusData: any, isLoading:boolean = true) => {
  return await post(`/Bonus`,bonusData,isLoading)
  .then(() => toast.success(bonoCreateSuccess))
  .catch((error) => toast.error(bonoCreateError))
    .finally(() => {
      handleRequestFinally(isLoading);
    })
}

export let getProfilePicture = async () => {
  try {
    const response = await axios.get(`${graphConfig.graphMeEndpoint}/photo/$value`, {
      headers: { AccessControlAllowOrigin: '*' },
      responseType: 'arraybuffer',
    });
    return Buffer.from(response.data, 'binary').toString('base64');
  } catch (error) {
    console.error(error);
  }
}

export let deleteTask = async (taskId: number, isLoading:boolean = true) => {
    return await deleteEntity(`/Task/${taskId}`, isLoading)
        .then(() => toast.success(taskDeleteSuccess))
        .catch((error) => toast.error(taskDeleteError))
        .finally(() => {
            handleRequestFinally(isLoading);
        })
}

export let deleteTag = async (tagId: number, isLoading:boolean = true) => {
    return await deleteEntity(`/Tag/${tagId}`, isLoading)
        .then(() => toast.success(taskDeleteSuccess))
        .catch((error) => toast.error(taskDeleteError))
        .finally(() => {
            handleRequestFinally(isLoading);
        })
}

export let saveImage = async (imageData:any,isLoading:boolean = true) => {
  return await put(`/resource/SaveImage`,imageData,isLoading)
    .catch((error) => undefined)
    .finally(() => {
      handleRequestFinally(isLoading);
    })
}

const RefreshAccessToken = async (instance: IPublicClientApplication, resource: string) => {
  let accessToken : any;
  accessToken = await instance.acquireTokenSilent({
    scopes: (resource === GRAPH_RESOURCE) ? loginRequest.graphScopes : loginRequest.scopes,
    account: instance.getAllAccounts()[0],
  }).catch(async (e: any) => {
    accessToken = await instance.acquireTokenPopup({
      scopes: (resource === GRAPH_RESOURCE) ? loginRequest.graphScopes : loginRequest.scopes,
      account: instance.getAllAccounts()[0],
    });
});
  return accessToken?.accessToken;
}

export let CheckIfNewRessource = async (isLoading:boolean = true) => {
  return get(`/Resource/CheckIfNewResource`,isLoading)
      .then((data)=> data as any)
      .catch((error) => undefined)
      .finally(()=>{
        handleRequestFinally(isLoading);
      })
}

export let updateRecord = async(recordData:any,isLoading:boolean = true) =>{
  return await put(`/Record/UpdateRecord`,recordData,isLoading)
    .then(()=> {
      return 0
    })
    .catch((error)=> {
      if (error.request.response === exceddingRecordsHours){
        return 2;
      }
      return 1;
    })
    .finally(()=>{
      handleRequestFinally(isLoading);
    })
}

export let updateClient = async(clientData:any,isLoading:boolean = true) =>{
  return await put(`/Client`, clientData,isLoading)
  .then(()=> toast.success(clientUpdateSuccess))
  .catch((error)=> toast.error(clientUpdateError))
    .finally(()=>{
      handleRequestFinally(isLoading);
    })
}

export let getIndustryVerticals = async (isLoading:boolean = true) => {
    return await get('/IndustryVertical',isLoading)
        .then((industryVertical)=> industryVertical as Array<IndustryVertical>)
        .catch((error)=> new Array<IndustryVertical>())
        .finally(()=>{
            handleRequestFinally(isLoading);
        })
}

export let getPartners = async (isLoading:boolean = true) => {
  return await get('/Partner',isLoading)
    .then((projectClients)=> projectClients as Array<Client>)
    .catch((error)=> new Array<Client>())
    .finally(()=>{
      handleRequestFinally(isLoading);
  })
}

export const updateProject = async(ProjectData:any, isLoading:boolean = true) =>{
    return await put(`/Project`, ProjectData, isLoading)
    .then(()=> toast.success(projectUpdateSuccess))
    .catch((error)=> toast.error(projectUpdateError))
    .finally(()=>{
      handleRequestFinally(isLoading);
    });  
}

export let getProjectsFromClients = async (isLoading : boolean = true) => {
  return await get(`/Client/ProjectsFromClients`,isLoading)
  .then((clients) =>  clients as Array<Client>)
  .catch((error) => new Array<Client>())
  .finally(() => handleRequestFinally(isLoading))
}

export let getRecurringSales = async (isLoading : boolean = true) => {
    return await get(`/Sale/Recurrency`,isLoading)
        .then((sales) =>  sales as Array<SaleDTO>)
        .catch((error) => new Array<SaleDTO>())
        .finally(() => handleRequestFinally(isLoading))
}
export let getProjects = async (isLoading : boolean = true) => {
  return await get(`/Project`,isLoading)
  .then((projects) =>  projects as Array<Project>)
  .catch((error) => new Array<Project>())
  .finally(() => handleRequestFinally(isLoading))
}

export let deleteRecord = async(recordId:any,isLoading:boolean = true) =>{
  await deleteEntity(`/Record/DeleteRecord/${recordId}`,isLoading)
  .then(()=> toast.info(recordDeleteSuccess))
  .catch((error)=> toast.error(recordDeleteError))
  .finally(()=>{
    handleRequestFinally(isLoading);
  })
}

export let getTasks = async (isLoading:boolean = true) => {
  return await get(`/Task`,isLoading)
      .then((tasks)=> tasks as Array<task>)
      .catch((error)=> new Array<task>())
      .finally(()=>{
        handleRequestFinally(isLoading);
      })
}

export let getTags = async (isLoading:boolean = true) => {
  return await get(`/Tag`,isLoading)
      .then((tags)=> tags as Array<Tag>)
      .catch((error)=> new Array<Tag>())
      .finally(()=>{
        handleRequestFinally(isLoading);
      })
}

export let getAreas = async (isLoading:boolean = true) => {
  return await get(`/Area`,isLoading)
    .then((areas) => areas as Array<Area>)
    .catch((error) => new Array<Area>())
    .finally(() => {
      handleRequestFinally(isLoading);
    })
}

export let getAllAreas = async (isLoading:boolean = true) => {
  return await get(`/Area/AllAreas`,isLoading)
    .then((areas) => areas as Array<Area>)
    .catch((error) => new Array<Area>())
    .finally(() => {
      handleRequestFinally(isLoading);
    })
}
export let getResourceIncomeById = async (resourceId: number, isLoading:boolean = true) => {
    return await get(`/Resource/Income/${resourceId}`,isLoading)
        .then((income) => income as ResourceIncome)
        .catch((error) => new ResourceIncome())
        .finally(() => {
            handleRequestFinally(isLoading);
        })
}
export let getSubAreas = async (isLoading:boolean = true) => {
    return await get(`/SubArea`,isLoading)
        .then((subAreas) => subAreas as Array<SubArea>).catch(()=> new Array<SubArea>()).finally(() => {
            handleRequestFinally(isLoading);
        })
}

export let getProjectServices = async (isLoading:boolean = true) => {
    return await get(`/ProjectService`,isLoading)
        .then((projectServices) => projectServices as Array<projectService>).catch(()=> new Array<projectService>()).finally(() => {
            handleRequestFinally(isLoading);
        })
}

export let getRecurringPurchases = async (isLoading:boolean = true) => {
    return await get(`/Purchase/Recurrency`,isLoading)
        .then((purchases) => purchases as Array<PurchaseDTO>).catch(()=> new Array<PurchaseDTO>()).finally(() => {
            handleRequestFinally(isLoading);
        })
}

export let getRoles = async (isLoading:boolean = true) => {
  return await get(`/ProjectRole`,isLoading)
      .then((roles)=> roles as Array<Role>)
      .catch((error)=> new Array<Role>())
      .finally(()=>{
        handleRequestFinally(isLoading);
      })
}

export let postSaveProject = async (projectData:any,isLoading:boolean = true) => {
  return await post(`/Project`,projectData,isLoading)
  .then(()=> toast.success(projectCreateSuccess))
  .catch((error)=> toast.error(projectCreateError))
    .finally(()=>{
      handleRequestFinally(isLoading);
    })
}

export let deleteProject = async(projectId:number,isLoading:boolean = true) =>{
  await deleteEntity(`/Project/DeleteProject/${projectId}`,isLoading)
  .then(()=>  toast.info(projectDeleteSuccess))
  .catch((error)=> toast.error(projectDeleteError))
  .finally(()=>{
    handleRequestFinally(isLoading);
  })
}

export let deleteProjectStage = async(stageId:number,isLoading:boolean = true) =>{
    await deleteEntity(`/Stage/${stageId}`,isLoading)
        .then(()=>  toast.info(projectStageDeleteSuccess))
        .catch((error)=> toast.error(projectStageDeleteError))
        .finally(()=>{
            handleRequestFinally(isLoading);
        })
}

export let postSaveProjectStage = async (projectStageData:any,isLoading:boolean = true) => {
    return await post(`/Stage`,projectStageData,isLoading)
        .then(()=> toast.success(projectStageCreateSuccess))
        .catch((error)=> toast.error(projectStageCreateError))
        .finally(()=>{
            handleRequestFinally(isLoading);
        })
}

export const updateProjectStage = async(ProjectStageData:any, isLoading:boolean = true) => {
    return await put(`/Stage`, ProjectStageData, isLoading)
        .then(() => toast.success(projectStageUpdateSuccess))
        .catch((error) => toast.error(projectStageUpdateError))
        .finally(() => {
            handleRequestFinally(isLoading);
        });
}

export let deleteClient = async(clientId:number,isLoading:boolean = true) =>{
  await deleteEntity(`/Client/DeleteClient/${clientId}`,isLoading)
  .then(()=> toast.info(clientDeleteSuccess))
  .catch((error)=> toast.error(clientDeleteError))
  .finally(()=>{
    handleRequestFinally(isLoading);
  })
}

export let getSales = async (isLoading:boolean = true) => {
  return await get(`/Sale`,isLoading)
      .then((sales)=> sales as Array<SaleDTO>)
      .catch((error)=> new Array<SaleDTO>())
      .finally(()=>{
        handleRequestFinally(isLoading);
      })
}

export let getPaginatedSales = async (offset:number, limit:number, month:string, year: string, searchTerm?: string, area?: string, subArea?: string, isLoading:boolean = true) => {
  var pageData = {
    offset: offset,
    limit: limit,
    month: month,
    year: year,
    searchTerm: searchTerm,
    area: area,
    subArea: subArea
  }
  return await get(`/Sale/Sales`,isLoading, pageData)
      .then((sales)=> sales as PageDTO<SaleDTO>)
      .catch((error)=> new PageDTO<SaleDTO>())
      .finally(()=>{
        handleRequestFinally(isLoading);
      })
}

export let postSale = async (listDate: Array<Sale>, isLoading:boolean = true) => {
  return await post(`/Sale/PostMultipleSales`,listDate,isLoading)
    .then(()=> { return successSave; })
    .catch((error)=> {
      return errorSave;
    })
    .finally(()=>{
      handleRequestFinally(isLoading);
    })
}

export let deleteSale = async(saleId:number,isLoading:boolean = true) =>{
  await deleteEntity(`/Sale/DeleteSale/${saleId}`,isLoading)
  .then(()=> toast.info(saleDeleteSuccess))
  .catch((error)=> toast.error(saleDeleteError))
  .finally(()=>{
    handleRequestFinally(isLoading);
  })
}

export let putRecurrency = async (salesList:Array<number>, isLoading:boolean = true) => {
  return await put(`/Sale/Recurrency`,salesList,isLoading)
  .then(()=> toast.success(salesRecurrencesSuccess))
    .catch((error)=> toast.error(salesRecurrencesError))
    .finally(()=>{
      handleRequestFinally(isLoading);
    })
}
export let putRecurrencyPurchase = async (purchaseList:Array<number>, isLoading:boolean = true) => {
  return await put(`/Purchase/Recurrency`,purchaseList,isLoading)
  .then(()=> toast.success(purchasesRecurrencesSuccess))
    .catch((error)=> toast.error(purchasesRecurrencesError))
    .finally(()=>{
      handleRequestFinally(isLoading);
    })
}

export let putSale = async (sale : any, isLoading:boolean = true) => {
  return await put(`/Sale`,sale,isLoading)
  .then(()=> toast.success(salesEditSuccess))
    .catch((error)=> toast.error(salesEditError))
    .finally(()=>{
      handleRequestFinally(isLoading);
    })
}

export let getCompanyRoles = async (isLoading:boolean = true) => {
  return await get(`/CompanyRole`,isLoading)
    .then((companyRoles) => companyRoles as Array<CompanyRole>)
    .catch((error) => new Array<CompanyRole>())
    .finally(() => {
      handleRequestFinally(isLoading);
    })
}
export let getPaginatedPurchases = async (offset:number, limit:number, month:string, year: string, searchTerm?: string, area?: string, subArea?: string, sector?: string, subSector?: string, isLoading:boolean = true) => {
    var pageData = {
        offset: offset,
        limit: limit,
        month: month,
        year: year,
        searchTerm: searchTerm,
        area: area,
        subArea: subArea,
        sector: sector,
        subSector: subSector
    }
    return await get(`/Purchase/Purchases`,isLoading, pageData)
        .then((purchases)=> purchases as PageDTO<PurchaseDTO>)
        .catch((error)=> new PageDTO<PurchaseDTO>())
        .finally(()=>{
            handleRequestFinally(isLoading);
        })
}

export let getPurchases = async (isLoading:boolean = true) => {
  return await get(`/Purchase`,isLoading)
      .then((purchases)=> purchases as Array<Purchase>)
      .catch((error)=> new Array<Purchase>())
      .finally(()=>{
        handleRequestFinally(isLoading);
      })
}

export let postPurchase = async (listDate: Array<Purchase>, isLoading:boolean = true) => {
  return await post(`/Purchase/PostMultiplePurchases`,listDate,isLoading)
    .then(()=> { return successSave; })
    .catch((error)=> {
      return errorSave;
    })
    .finally(()=>{
      handleRequestFinally(isLoading);
    })
}

export let getSectores = async (isLoading:boolean = true) => {
  return await get(`/Sector`,isLoading)
      .then((sectores)=> sectores as Array<Sector>)
      .catch((error)=> new Array<Sector>())
      .finally(()=>{
        handleRequestFinally(isLoading);
      })
}

export let getSubSectores = async (isLoading:boolean = true) => {
  return await get(`/SubSector`,isLoading)
    .then((subSectores)=> subSectores as Array<SubSector>)
    .catch((error)=> new Array<SubSector>())
    .finally(()=>{
      handleRequestFinally(isLoading);
    })
}

export let deletePurchase = async(purchaseId:number,isLoading:boolean = true) =>{
  await deleteEntity(`/Purchase/DeletePurchase/${purchaseId}`,isLoading)
  .then(()=> toast.info(purchaseDeleteSuccess))
  .catch((error)=> toast.error(purchaseDeleteError))
  .finally(()=>{
    handleRequestFinally(isLoading);
  })
}

export let putPurchase = async (purchase : any, isLoading:boolean = true) => {
  return await put(`/Purchase`,purchase,isLoading)
  .then(()=> toast.success(purchasesEditSuccess))
    .catch((error)=> toast.error(purchasesEditError))
    .finally(()=>{
      handleRequestFinally(isLoading);
    })
}