import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { requestPending } from "../../../constants/constants";

const namespace = 'removeClientDialog'
const initialState = {
    isOpen:false,
    id:0,
    status: requestPending
}

export const removeClientDialog = createSlice({
    name : namespace,
    initialState : initialState,
    reducers:{
        openRemoveDialog: (state,action) => {
            state.id = action.payload;
            state.isOpen = true;
        },
        closeRemoveDialog:(state) => {
            state.isOpen = false;
        },
    },
})

export const removeClientDialogReducer = removeClientDialog.reducer;
export const { openRemoveDialog, closeRemoveDialog } = removeClientDialog.actions;