import { SubArea } from "./subAreaModel";
import { Client } from "./clientModel";
import { Tag } from "./tagModel";
import { task } from "./taskModel";
import {ProjectStage} from "./projectStageModel";

export class Project{
    public  id: number = 0;
    public  name: string| undefined;
    public state: string | undefined;
    public code: string | undefined;
    public subAreaId : number = 0;
    public subArea: SubArea | undefined;
    public client: Client | undefined;
    public startDate: Date | undefined;
    public endDate: Date | undefined;
    public reported: string = "00:00";
    public totalHour : string = '00:00';
    public daysOfWork : number = 0;
    public processId: number=0;
    public serviceId: number=0;
    public stages: Array<ProjectStage> = [];
    public projectTags: Array<Tag> | undefined;
    public projectTasks: Array<task> | undefined;
}