import { Box, Button, Dialog, DialogContent, Typography } from "@mui/material";
import { darkGreenColor, grayColor, redColor, whiteColor } from "../../../constants/constants";
import { closeRemoveDialog } from "../../../core/state/dialogs/removeRecordDialogSlice";
import { useAppDispatch, useAppSelector } from "../../../core/state/hooks";
import { removeRecord } from "../../../core/state/dailyTracker/dailyTrackerSlice";

export default function RemoveRecordDialog() {
    const dispatch = useAppDispatch();
    const {isOpen: dialogIsOpen, recordToDelete} = useAppSelector((state) => state.removeRecordDialog);
    return (
    <Dialog open={dialogIsOpen} onClose={() => { dispatch(closeRemoveDialog()); }} maxWidth="md">
        <DialogContent sx={{display:"flex", flexDirection:"column", alignItems:"center", m:3,p:2 }}>
            <DialogContent>
                <Box>
                    <Typography variant='h4'>¿Desea eliminar el registro?</Typography>
                </Box>
            </DialogContent>

            <DialogContent>
                <Box >
                    <Button sx={{m:1,p:1,backgroundColor:grayColor, color:whiteColor, width:200, '&:hover': {backgroundColor: darkGreenColor,}}}
                    onClick={() => dispatch(closeRemoveDialog())}>
                        <Typography variant="h5">Cancelar</Typography>
                    </Button>
                    <Button sx={{m:1,p:1,backgroundColor:redColor, color:whiteColor, width:200, '&:hover': {backgroundColor: darkGreenColor,}}}
                        onClick={() => dispatch(removeRecord(recordToDelete)) }>
                        <Typography variant="h5">Eliminar</Typography>
                    </Button>
                </Box>
            </DialogContent>
        </DialogContent>
    </Dialog>
    );
}