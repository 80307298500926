import { createSlice } from "@reduxjs/toolkit";
import { requestPending } from "../../../constants/constants";

const namespace = 'removeProjectStageDialog'
const initialState = {
    isOpen:false,
    stageIndex:0,
    status: requestPending
}

export const removeProjectStageDialog = createSlice({
    name : namespace,
    initialState : initialState,
    reducers:{
        openRemoveStageDialog: (state,action) => {
            state.stageIndex = action.payload.stageIndex;
            state.isOpen = true;
        },
        closeRemoveStageDialog:(state) => {
            state.isOpen = false;
        },
    },
})

export const removeProjectStageDialogReducer = removeProjectStageDialog.reducer;
export const { openRemoveStageDialog, closeRemoveStageDialog } = removeProjectStageDialog.actions;