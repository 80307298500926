import { Add, ArrowBackIosRounded, ArrowForwardIosRounded } from "@mui/icons-material";
import { Box, Button, Card, IconButton, Typography } from "@mui/material";
import { addDays, addWeeks, isSameMonth, startOfWeek } from "date-fns";
import React, { useEffect } from "react";
import { ReactComponent as HomeIcon } from "../../../../assets/icons/home.svg";
import { blackColor, darkGreenColor, greenColor, purpleColor, whiteColor } from "../../../constants/constants";
import { fetchWeeklyRecords, handleWeeklyViewClick } from "../../../core/state/dailyTracker/dailyTrackerSlice";
import { selectDate } from "../../../core/state/date/dateSlice";
import { openSaveRecordDialog } from "../../../core/state/dialogs/saveRecordDialogSlice";
import { useAppDispatch, useAppSelector } from "../../../core/state/hooks";
import { fetchMonthlyRecords, setWeekDayOfFirstDayOfMonth } from "../../../core/state/monthlyTracker/monthlyTrackerSlice";
import RecordInfo from "./recordInfo";
import SaveRecordDialog from "./saveRecordDialog";
import WeeklyViewColumn from "./weeklyViewColumn";
import { fetchUserProjects } from "../../../core/state/user/userSlice";

export const getDayName = (day: number) => {
    switch (day) {
        case 0: return 'DOM';
        case 1: return 'LUN';
        case 2: return 'MAR';
        case 3: return 'MIE';
        case 4: return 'JUE';
        case 5: return 'VIE';
        case 6: return 'SAB';
    }
}

function DailyTracker() {
    const dispatch = useAppDispatch();
    const { user } = useAppSelector((state) => state.user);
    const { weekRecords, weeklyViewClicked } = useAppSelector((state) => state.dailyTracker);
    const { selectedDate } = useAppSelector((state) => state.date);
    const [isNew, setIsNew] = React.useState(true);

    const styles = {
        dayButton: {color:whiteColor, backgroundColor:greenColor, borderRadius:7, minWidth:{xs:35,md:43,lg:47}, paddingY: {xs: 1/8, sm: 4/8, lg:1}},
        noClickedButton: {color:blackColor, opacity:0.25, minWidth:{xs:35,md:43, lg:47}, paddingY: {xs: 1/8, sm: 4/8, lg:1}},
        weeklyButton: {background:purpleColor, borderRadius: 10, color:whiteColor, ml:2,display:{xs:"none", md:"block"}},
        addRecordDailyView: {backgroundColor:greenColor,color:whiteColor,ml:"auto",mr:1,mt:-8.5,"&:hover":{backgroundColor:darkGreenColor}},
        addRecordWeeklyView: {backgroundColor:greenColor,color:whiteColor,"&:hover":{backgroundColor:darkGreenColor}}
    }

    const fetchWeekData = (date:Date)=>{
        const actual = selectedDate;
        dispatch(selectDate(date));
        let firstDayOfMonth = new Date(date.getTime());
        firstDayOfMonth.setDate(1);
        let dayNumber = firstDayOfMonth.getDay();
        if(!isSameMonth(date,actual)){
            dispatch(setWeekDayOfFirstDayOfMonth(dayNumber));
            dispatch(fetchMonthlyRecords(user?.id));
        }
        dispatch(fetchWeeklyRecords(user?.id));
    }

    const handleWeeklyViewOnClick = () => {
        dispatch(handleWeeklyViewClick());
    }

    const activeFilter = (date:Date) => {
        dispatch(selectDate(date));
    }

    useEffect(() => {
        if(user?.id !== null && user?.id !== 0){
            dispatch(fetchWeeklyRecords(user?.id));
            dispatch(fetchUserProjects(user?.id));
        }
    }, [dispatch, user?.id]);

    return <Box display={"flex"}  flex={1} flexDirection="column"
    >
        <Box  display={"flex"}  flexDirection="row" alignItems="center" my={1}>
            <IconButton sx={{ml:3}} children={<ArrowBackIosRounded />}
                onClick={() => {fetchWeekData(addWeeks(selectedDate, -1))}} />
            <Box display={"flex"} alignContent={"center"} ml={{xs:1,md:3}} sx={{cursor:"pointer"}} onClick={() => fetchWeekData(new Date())}><HomeIcon/></Box>
            <IconButton sx={{ml:{xs:1,md:3}}} children={<ArrowForwardIosRounded />}
                onClick={() => {fetchWeekData(addWeeks(selectedDate, 1))}} />
            <Typography sx={{ml:5}} variant="h5" >
                {'Lun ' + startOfWeek(selectedDate, { weekStartsOn: 1 }).getDate() + ' - Vie ' + addDays(startOfWeek(selectedDate, { weekStartsOn: 1 }), 4).getDate()}
            </Typography>
            <IconButton sx={styles.weeklyButton} onClick={() => {handleWeeklyViewOnClick()}}>
                 <Typography variant="body2" > <span> {"<<"} </span> {!weeklyViewClicked? "Vista Semanal" : "Vista Diaria"}</Typography>
            </IconButton>
        </Box>
        <Card sx={{display:"flex",flexDirection:"column",flex:1,borderRadius: 7,bgcolor:whiteColor,py:2, transition: "all 2s ease"}}>
            <Box display={"flex"}  flexDirection="row" flex={0.2} pb={2} >
                {weekRecords.map((day) => {
                    if (day.date.getDate() === selectedDate.getDate()) {
                        return <Box display="flex" flexDirection="column" alignItems="center" justifyContent={"space-evenly"} flex={0.2} key={day.date.getDay()}>
                            <Button sx={styles.dayButton} 
                                key='date' onClick={() => activeFilter(day.date)}
                            >
                                <Typography variant="h5" textAlign='center'  >{day.date.getDate()}</Typography>
                            </Button>
                            <Typography variant="h6" color={blackColor} key='day'>{getDayName(day.date.getDay())}</Typography>
                            <Typography variant="subtitle1" color={blackColor}  key='hours'>{day.dayHoursQty}</Typography>
                        </Box>
                    } else {
                        return <Box display="flex"  flexDirection="column" alignItems="center" flex={0.2} key={day.date.getDay()} justifyContent={"space-evenly"}>
                            <Button sx={styles.noClickedButton} key='date' onClick={() => activeFilter(day.date)}>
                                <Typography variant="h5"  >{day.date.getDate()}</Typography>
                            </Button>
                            <Typography variant="h6" sx={{color:blackColor,opacity:0.25}} textAlign='center' key='day'>{getDayName(day.date.getDay())}</Typography>
                            <Typography variant="subtitle1" sx={{color:blackColor,opacity:0.4}}  key='hours'>{day.dayHoursQty}</Typography>
                        </Box>
                    }
                })}
            </Box>
            
            {!weeklyViewClicked?
                <Box display={"flex"} flexDirection="column" borderBottom={1}  borderColor={"rgba(0, 0, 0, 0.1)"} 
                    sx={{overflowY:"scroll"}} flex={"1 1 0"}
                    >
                    {weekRecords && weekRecords[selectedDate.getDay() - 1]?.records?.map((record) => <RecordInfo key={record.id} record={record} />)}
                </Box> :
                <Box display={"flex"} flexDirection="row" borderBottom={1} borderColor={"rgba(0, 0, 0, 0.1)"} 
                sx={{overflowY: "scroll"}} flex={"1 1 0"} flexWrap={"wrap"}>
                    {
                        (weekRecords && weekRecords.map((dayOfTheWeek,index) => {
                            return <WeeklyViewColumn key={index} records={dayOfTheWeek.records} />
                        }))
                    }
                </Box>
            }
 
            {!weeklyViewClicked?
                <Box display={"flex"}  flexDirection="row" flex={0.15} alignItems="center" px={2} mt={1} sx={{transition: "all 2s ease"}}>
                    <Typography variant="h4" flex={{xs:0.29, md:0.23}} textAlign="center" > {weekRecords && weekRecords[selectedDate.getDay() - 1]?.dayHoursQty}</Typography>
                    <Typography flex={0.35}  variant="subtitle2">Horas totales</Typography>
                    <IconButton sx={styles.addRecordDailyView}
                        onClick={() => {dispatch(openSaveRecordDialog({record:undefined,date:selectedDate})) }}
                        children={<Add />}/>
                </Box> :
                <Box display={"flex"}  flexDirection="row" flex={0.15} alignItems="center" px={2} mt={1} sx={{transition: "all 2s ease"}}>
                    {
                        (weekRecords && weekRecords.map((dayOfTheWeek,index) => {
                            return <Box display={"flex"} flex={0.2} justifyContent={"flex-end"} position={"relative"} mr={2.5} mt={-8} key={index}> 
                                <IconButton sx={styles.addRecordWeeklyView}
                                onClick={() => {dispatch(openSaveRecordDialog({record:undefined,date:addDays(startOfWeek(selectedDate, { weekStartsOn: 1 }), index)})) }}
                                children={<Add />}/>
                            </Box>
                        }))
                    }
                </Box>
            }
        
        </Card>        
        <SaveRecordDialog isNew={isNew} />
    </Box>
}

export default React.memo(DailyTracker);