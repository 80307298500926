import { createSlice } from "@reduxjs/toolkit";


const namespace = 'date';


const initialState = {
    selectedDate: new Date(),
}


export const dateSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        selectDate: (state, action) => {
            state.selectedDate = action.payload;
        }
    },
})

export const dateReducer = dateSlice.reducer;

export const { selectDate } = dateSlice.actions;