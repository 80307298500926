import {StageResource} from "./stageResource";


export class ProjectStage{
    public id : number = 0;
    public name : string = "";
    public startDate : Date = new Date();
    public endDate : Date = new Date();
    public projectId : number = 0;
    public number : number = 0;
    public stageResources: Array<StageResource> = new Array<StageResource>();
}