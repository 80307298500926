import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
    deletePurchase,
    getPaginatedPurchases, getProjectServices,
    getRecurringPurchases,
    getSectores,
    getSubSectores,
    putRecurrencyPurchase
} from "../../http/httpClient";
import {
    emptyString,
    monthList,
    requestLoading,
    requestMissed,
    requestPending,
    requestSuccessful
} from "../../../constants/constants";
import {RootState} from "../store";
import {closeRemoveDialog} from "../dialogs/removePurchaseDialogSlice";
import {SubSector} from "../../models/subSectorModel";
import {Sector} from "../../models/sectorModel";
import {PurchaseDTO} from "../../models/purchaseModelDTO";
import {projectService} from "../../models/projectServiceModel";

const namespace = "purchases";


interface FetchGetPurchasesParams {
    offset: number;
    limit: number;
    month: string;
    year: string;
    searchTerm: string;
    sector?: string;
    subSector?: string;
}

export const fetchGetPurchases = createAsyncThunk(
    `${namespace}/fetchGetPurchases`,
    async (params: FetchGetPurchasesParams) => {
        return await getPaginatedPurchases(params.offset, params.limit, params.month, params.year, params.searchTerm, params.sector, params.subSector);
    }
);

export const fetchProjectServices = createAsyncThunk(
    `${namespace}/fetchProjectServices`,
    async () => await getProjectServices(false)
);

export const fetchRecurringPurchases = createAsyncThunk(
    `${namespace}/fetchRecurringPurchases`,
    async () => {
        return await getRecurringPurchases();
    }
);

export const fetchSector = createAsyncThunk(
    `${namespace}/fetchSector`,
    async () =>  await getSectores(),
);

export const fetchSubSector = createAsyncThunk(
    `${namespace}/fetchSubSector`,
    async () => await getSubSectores(),
);

export const fetchData = createAsyncThunk(
    `${namespace}/fetchData`,
    async (userId,thunkAPI) => {
        await Promise.all([
            thunkAPI.dispatch(fetchSector()),
            thunkAPI.dispatch(fetchSubSector()),
        ])
    },
);


export const fetchDeletePurchase = createAsyncThunk(
    `${namespace}/fetchDeletePurchase`,
    async (id:number, thunkAPI) => {
        await deletePurchase(id);
        const {purchases : state} = thunkAPI.getState() as RootState;
        const { rowsPerPage, page, selectedMonth, yearFilter, searchFilter, selectedSector, selectedSubSector} = state
        thunkAPI.dispatch(fetchGetPurchases({offset:page * rowsPerPage, limit: rowsPerPage, month: selectedMonth, year: yearFilter.toString(), searchTerm: searchFilter, sector: selectedSector, subSector: selectedSubSector}))
        thunkAPI.dispatch(closeRemoveDialog());
    }
);

export const addNewsRecurrency = createAsyncThunk(
    `${namespace}/addNewsRecurrency`,
    async (_,thunkAPI) => {
        const {purchases : state} = thunkAPI.getState() as RootState;
        await putRecurrencyPurchase(state.changedRecurrences);
    }
)

export const purchaseSlice = createSlice({
    name:`${namespace}`,
    initialState:{
        purchases : new Array<PurchaseDTO>(),
        allRecurrencyInTheSystem: new Array<number>(),
        purchasesRecurrences: new Array<number>(),
        changedRecurrences: new Array<number>(),
        selectedMonth : monthList[0],
        status: requestPending,
        recurringPurchasesStatus: requestPending,
        searchFilter : emptyString,
        projectServices: Array<projectService>(),
        yearFilter : new Date().getFullYear(),
        selectedSector : emptyString,
        selectedSubSector : emptyString,
        projectServicesStatus: requestPending,
        sectores : new Array<Sector>(),
        sectoresStatus : requestPending,
        subSectores : new Array<SubSector>(),
        subSectoresStatus : requestPending,
        purchasesCount: 0,
        page: 0,
        rowsPerPage: 6,
    },
    reducers:{
        selectSearchFilter: (state,action) => {
            state.searchFilter = action.payload;
        },
        selectedMonthFilter:(state,action) => {
            state.selectedMonth = action.payload;
        },cancelNewRecurrency:(state) =>{
            state.purchasesRecurrences = state.allRecurrencyInTheSystem;
        },changeElementRecurrency: (state,action) => {
            const elementId = parseInt(action.payload)
            const index = state.purchasesRecurrences.indexOf(elementId);
            if (index !== -1) {
                state.purchasesRecurrences.splice(index, 1);
            } else {
                state.purchasesRecurrences.push(elementId);
            }
        },selectYearFilter:(state,action) =>{
            state.yearFilter = action.payload
        },setChangedRecurrences:(state,action) =>{
            state.changedRecurrences = action.payload
        },setFilters:(state,action) => {
            state.selectedSector = action.payload.sectorName;
            state.selectedSubSector = action.payload.subSectorName;
        },setSystemRecurrences:(state,action) =>{
            state.allRecurrencyInTheSystem = action.payload
        },setRowsPerPage:(state,action) => {
            state.rowsPerPage = action.payload;
        },setPage:(state,action) => {
            state.page = action.payload;
        },cleanFilter: (state) => {
            state.selectedSector = emptyString;
            state.selectedSubSector = emptyString;
            state.searchFilter = emptyString;
            state.yearFilter = new Date().getFullYear();
            state.selectedMonth = monthList[0];
        }
    },
    extraReducers:(builder) =>{
        builder.addCase(fetchGetPurchases.pending, (state) =>{
            state.status = requestLoading;
        });
        builder.addCase(fetchGetPurchases.fulfilled, (state,action) =>{
            state.purchases = action.payload.pageItems;
            state.purchasesCount = action.payload.itemsCount;
            state.status = requestSuccessful;

        });
        builder.addCase(fetchGetPurchases.rejected, (state) => {
            state.status = requestMissed;
        });

        builder.addCase(fetchRecurringPurchases.pending, (state) => {
            state.recurringPurchasesStatus = requestLoading;
        }).addCase(fetchRecurringPurchases.fulfilled, (state, action) => {
            state.recurringPurchasesStatus = requestSuccessful;
            state.purchasesRecurrences = [];
            state.allRecurrencyInTheSystem = [];
            state.changedRecurrences = [];
            action.payload.map((purchaseRecurrence) => {
                state.purchasesRecurrences.push(purchaseRecurrence.id);
                state.allRecurrencyInTheSystem.push(purchaseRecurrence.id)

            })
        }).addCase(fetchRecurringPurchases.rejected, (state) => {
            state.recurringPurchasesStatus = requestMissed;
        });

        builder.addCase(fetchSector.pending, (state) => {
            state.sectoresStatus = requestLoading;
        }).addCase(fetchSector.fulfilled, (state, action) => {
            state.sectores = action.payload;
            state.sectoresStatus = requestSuccessful;
        }).addCase(fetchSector.rejected, (state) => {
            state.sectoresStatus = requestMissed;
        });

        builder.addCase(fetchSubSector.pending, (state) => {
            state.subSectoresStatus = requestLoading;
        }).addCase(fetchSubSector.fulfilled, (state, action) => {
            state.subSectores = action.payload;
            state.subSectoresStatus = requestSuccessful;
        }).addCase(fetchSubSector.rejected, (state) => {
            state.subSectoresStatus = requestMissed;
        });

        builder.addCase(fetchProjectServices.pending, (state) => {
            state.projectServicesStatus = requestLoading;
        }).addCase(fetchProjectServices.fulfilled, (state, action) => {
            state.projectServices = action.payload;
            state.projectServicesStatus = requestSuccessful;
        }).addCase(fetchProjectServices.rejected, (state) => {
            state.projectServicesStatus = requestMissed;
        });

    }
})




export const purchaseReducer = purchaseSlice.reducer;
export const { selectSearchFilter,selectYearFilter, setSystemRecurrences,selectedMonthFilter, setChangedRecurrences,cancelNewRecurrency,changeElementRecurrency,
        setFilters,cleanFilter, setPage, setRowsPerPage } = purchaseSlice.actions;