import { Box, Toolbar, Typography } from '@mui/material'
import React from 'react'
import { drawerWidth } from '../../constants/constants'
import SideBar from '../../core/layout/sideBar'

export default function Credentials() {
    return (
        <>
            <SideBar/>
            <Box
            sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <Toolbar />
                <Typography variant="h1" component="div" align='center' gutterBottom>WORK IN PROGRESS</Typography>
            </Box>
        </>
    )
}
