export const msalConfig = {
  auth: {
    clientId: "94cdf8e6-6288-43d0-b0d0-fd21023cd4f8",
    authority: "https://login.microsoftonline.com/9881e478-9b48-43ae-b7ae-e2942db279ad", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: "https://intracker.azurewebsites.net/", //PROD
    // redirectUri: "https://intracker-dev.azurewebsites.net/", //DEV
    // redirectUri: "http://localhost:3000",

  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    tokenRenewalOffsetSeconds: 300
  }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["api://94cdf8e6-6288-43d0-b0d0-fd21023cd4f8/access_as_user"],
  graphScopes: ["User.Read"]
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};
