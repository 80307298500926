import { Box, Dialog, DialogContent, Table, TableBody, TableHead, Typography, TableRow, TableCell, Button } from "@mui/material";
import { useEffect } from "react";
import { darkGrayColor, darkGreenColor, greenColor, whiteColor, grayColor, recurringY, } from "../../constants/constants";
import { PurchaseDTO } from "../../core/models/purchaseModelDTO";
import { openSavePurchaseDialog,cleanList, addAssignment,fetchProjectServices,fetchSector,fetchSubSector,fetchRecurringPurchases, fetchGetProjects, closeSavePurchaseDialog, savesPurchases } from "../../core/state/dialogs/savePurchaseDialogSlice";
import { useAppDispatch, useAppSelector } from "../../core/state/hooks";
import PurchaseRowDialog from "./purchaseRowDialog";
import { columns } from "./purchases"; 

export function SavePurchaseDialog () {
    const dispatch = useAppDispatch();
    const {isOpen: dialogIsOpen, purchaseList,projectsList, projectServices,recurringPurchases,sectorList,subSectorList,  btnNew, recurringNewPurchases, futureSaveList  } = useAppSelector((state) => state.savePurchaseDialog)

    useEffect(() => {
        if(dialogIsOpen){
            dispatch(fetchSector());
            dispatch(fetchProjectServices());
            dispatch(fetchSubSector());
            dispatch(fetchGetProjects());
            dispatch(fetchRecurringPurchases())
        }
    },[dialogIsOpen]);

    const handleClickSavePurchases = () => {
        dispatch(closeSavePurchaseDialog());
        dispatch(savesPurchases());
        dispatch(cleanList());
    }

    return (
        <Dialog open={dialogIsOpen} onClose={() => { dispatch(closeSavePurchaseDialog());dispatch(cleanList()); }} maxWidth="lg" >
            <DialogContent sx={{minWidth:'1200px'}}>
                <DialogContent>
                    <Typography variant="h5" sx={{color:darkGrayColor}}>Alta de Compras</Typography>
                </DialogContent>
                <DialogContent >
                    <Box sx={{display:'flex'}}>
                        <Table sx={{overflowX:'scroll', width:'100%'}} stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow >
                                    {recurringPurchases.length > 0 ? columns.map((column) => (
                                        <TableCell 
                                            key={column.id} align="center"
                                            style={{ minWidth: column.minWidth}} sx={{borderBottom:1,borderColor:grayColor}}
                                        >
                                            <Typography variant='h6'>{column.label}</Typography>
                                        </TableCell>
                                    )) : <></>
                                }
                                </TableRow>
                            </TableHead>
                            <TableBody >
                                {
                                    recurringPurchases.length > 0 ? recurringPurchases.map((purchase,_index) => {
                                        return (<PurchaseRowDialog projectsList={projectsList} newPurchase={false} services={projectServices} sectores={sectorList} subSectores={subSectorList} index={_index} key={`${purchase.id}`} purchase={purchase}/>)
                                    }) : <></>
                                }
                            </TableBody>
                        </Table>
                    </Box>
                </DialogContent>
                <Box sx={{display:'flex',flexDirection:'row-reverse',my:3, alignItems:'center',backgroundColor:greenColor,maxWidth:'90%', maxHeight:'2px'}}>
                        <Button disabled={btnNew} onClick={() => {
                            dispatch(addAssignment())
                            }} sx={{backgroundColor:greenColor,color:whiteColor,px:3,'&:hover': {backgroundColor: darkGreenColor,}}}>
                            Nuevo +
                        </Button>
                </Box>
                <DialogContent>
                    <Box sx={{display:'flex'}}>
                        <Table sx={{overflowX:'scroll', width:'100%'}} stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow >
                                    {purchaseList.length > 0 ? columns.map((column) => (
                                        <TableCell 
                                                key={column.id} align="center"
                                                style={{ minWidth: column.minWidth}} sx={{borderBottom:1,borderColor:grayColor}}
                                            >
                                            <Typography variant='h6'>{column.label}</Typography>
                                        </TableCell>
                                    )) : <></>
                                }
                                </TableRow>
                            </TableHead>
                            <TableBody >
                                {
                                    purchaseList.length > 0 ? purchaseList.map((n,_index) => {
                                    return (
                                        <PurchaseRowDialog projectsList={projectsList} sectores={sectorList} subSectores={subSectorList} index={_index} newPurchase={true} purchase={undefined} services={projectServices} key={`${n.key}`} />
                                    )
                                    }): <></>
                                }
                            </TableBody>
                        </Table>
                    </Box>
                </DialogContent>
                <Box sx={{display:'flex',flexDirection:'row-reverse',mt:2,maxWidth:'90%'}}>
                    <Button onClick={handleClickSavePurchases} sx={{backgroundColor:greenColor,color:whiteColor,px:3,'&:hover': {backgroundColor: darkGreenColor,}}}
                        disabled={recurringNewPurchases.length > 0 || futureSaveList.length > 0 ? btnNew : true}
                    >
                        Guardar
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    )
}