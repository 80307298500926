
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { emptyString, recurringN, requestLoading, requestMissed, requestPending, requestSuccessful, salesCreateError, salesCreateFailAll, salesCreateSuccess, TypeOfMoneys } from "../../../constants/constants";
import {
    getProjectsFromClients,
    getRecurringSales,
    postSale,
    getProjectServices
} from "../../http/httpClient";
import { Client } from "../../models/clientModel";
import { Sale } from "../../models/saleModel";
import { fetchGetPaginatedSales } from "../sales/salesSlice";
import { RootState } from "../store";
import {SaleDTO} from "../../models/saleModelDTO";
import {projectService} from "../../models/projectServiceModel";

const namespace = 'saveSaleDialog';

export const fetchProjectsFromClients = createAsyncThunk(
    `${namespace}/fetchGetProjectsFromClients`,
    async () => {
       return getProjectsFromClients();
    }
);

export const fetchRecurringSales = createAsyncThunk(
    `${namespace}/fetchRecurringSales`,
    async () => {
        return getRecurringSales();
    }
);

export const fetchProjectServices = createAsyncThunk(
    `${namespace}/fetchProjectServices`,
    async () => await getProjectServices(false)
);

export const savesSales = createAsyncThunk(
    `${namespace}/savesSales`,
    async ({offset, limit, month, year, searchTerm}: {offset: number; limit: number; month: string; year: string, searchTerm: string},thunkAPI) => {
        const { saveSaleDialog : state } = thunkAPI.getState() as RootState;
        let newSale = new Array<Sale>();
        state.futureSaveList.forEach((s : Sale) => {
            newSale.push(s)
        })
        state.recurringNewSales.forEach((s : Sale) => {
            newSale.push({...s,id:0})
        })
        
        const isPosted = await postSale(newSale);

        if(isPosted === 0){
            toast.success(salesCreateSuccess);
        }else if(isPosted === 2){
            toast.info(salesCreateFailAll)
        }else{
            toast.error(salesCreateError);
        }

        thunkAPI.dispatch(fetchGetPaginatedSales({offset: offset,limit: limit,month: month, year:year, searchTerm: searchTerm}));
    },
);

const initialState = {
    recurringSales : new Array<SaleDTO>(),
    recurringSalesStatus : requestPending,
    salesList : new Array<{id:number,key:number}>(),
    futureSaveList : new Array<Sale>(),
    recurringNewSales: new Array<Sale>(), 
    clientList : new Array<Client>(),
    statusClientList : requestPending,
    projectServices : new Array<projectService>(),
    projectServicesStatus: requestPending,
    selectedData:{
    },
    isOpen:false,
    btnNew:false,
    uniqueId:0,
}

const emptySale = {
    id:0,
    projectId : 0,
    project: undefined,
    dateSale: new Date(),
    document : emptyString,
    number : emptyString,
    concept : emptyString,
    currency : TypeOfMoneys[1],
    netOrigin : 0,
    tax : 0,
    total : 0,
    dateOfPayment : emptyString,
    recurring:recurringN,
}

export const saveSaleDialog = createSlice({
    name:namespace,
    initialState: initialState,
    reducers: {
        openSaveSaleDialog:(state, action)=> {
            state.isOpen = true;
        },closeSaveSaleDialog:(state) => {
            state.isOpen=false;
        },addAssignment: (state) => {
            state.futureSaveList.push(emptySale);
            state.salesList.push({id:state.salesList.length+1,key:state.uniqueId});
            state.btnNew = true;
            state.uniqueId = state.uniqueId + 1;
        },deleteAssignment:(state,action)=>{
            state.btnNew = false;
            state.salesList.splice(action.payload,1);
            state.futureSaveList.splice(action.payload,1);
        },updateAssignment:(state,action) => {
            state.futureSaveList.splice(action.payload.index,1,action.payload.sale)
        },updateSaleRecurrences:(state,action) => {
            state.recurringNewSales.splice(action.payload.index,1,action.payload.sale);
        },addSaleRecurrences:(state,action) => {
            state.recurringNewSales.push(action.payload);
        },deleteSaleRecurrences:(state,action) => {
            const i = state.recurringNewSales.findIndex( (s : Sale) => (s.id === action.payload))
            state.recurringNewSales.splice(i,1);
        },availableBtnNew:(state,action)=>{
            state.btnNew = action.payload;
        },cleanList:(state) => {
            state.salesList = [];
            state.futureSaveList = [];
            state.recurringNewSales = [];
        }

    },
    extraReducers:(builder) => {
        builder.addCase(fetchProjectsFromClients.pending, (state) =>{
            state.statusClientList = requestLoading;
        }).addCase(fetchProjectsFromClients.fulfilled, (state,action) =>{
            state.clientList = action.payload;
            state.statusClientList = requestSuccessful;
        }).addCase(fetchProjectsFromClients.rejected, (state) => {
            state.statusClientList = requestMissed;
        });

        builder.addCase(fetchRecurringSales.pending, (state) => {
            state.recurringSalesStatus = requestLoading;
        }).addCase(fetchRecurringSales.fulfilled, (state, action) => {
            state.recurringSales = action.payload;
            state.recurringSalesStatus = requestSuccessful;
        }).addCase(fetchRecurringSales.rejected, (state) => {
            state.recurringSalesStatus = requestMissed;
        });

        builder.addCase(fetchProjectServices.pending, (state) => {
            state.projectServicesStatus = requestLoading;
        }).addCase(fetchProjectServices.fulfilled, (state, action) => {
            state.projectServices = action.payload;
            state.projectServicesStatus = requestSuccessful;
        }).addCase(fetchProjectServices.rejected, (state) => {
            state.projectServicesStatus = requestMissed;
        });
    }
});


export const saveSaleDialogReducer = saveSaleDialog.reducer;
export const { addAssignment,closeSaveSaleDialog,openSaveSaleDialog, deleteAssignment, updateAssignment,deleteSaleRecurrences,
    updateSaleRecurrences,addSaleRecurrences,availableBtnNew,cleanList } = saveSaleDialog.actions;



