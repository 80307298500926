import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteClient, getProjectsFromClients } from "../../http/httpClient";
import { Client } from "../../models/clientModel";
import { countryList } from "../../../constants/constants";
import { requestLoading, requestSuccessful, requestMissed, requestPending } from "../../../constants/constants";
import { closeRemoveDialog } from "../dialogs/removeClientDialogSlice";

const namespace = "clients";

export const fetchClients = createAsyncThunk( 
    `${namespace}/fetchClients`,
    async () => {
        return getProjectsFromClients();
    },
);

export const fetchDeleteClient = createAsyncThunk(
    `${namespace}/fetchDeleteClient`,
    async (clientId:number, thunkAPI) => {
        await deleteClient(clientId);
        thunkAPI.dispatch(fetchClients())
        thunkAPI.dispatch(closeRemoveDialog());
    }
);


export const clientSlice = createSlice({
    name:`${namespace}`,
    initialState:{
        clients: new Array<Client>() ,
        status: requestPending,
        nameFilter: '',
        clientFilter: 0,
        isFiltersCollapsed: true,
        checkBoxChecked: false,
        country: countryList[0],
    },
    reducers:{
        toggleCollapse: (state, action) => {
            state.clients.filter(x=>x.id === action.payload).forEach((client)=>{client.isColapsed = !client.isColapsed});
        },
        showHideFilters: (state)=>{
            state.isFiltersCollapsed = !state.isFiltersCollapsed;
        },
        setChecked: (state, action)=> {
            state.checkBoxChecked = action.payload;
        },
        selectNameFilter: (state,action)=>{
            state.nameFilter = action.payload;
        },
        selectClientFilter: (state,action)=>{
            state.clientFilter = action.payload;
        },
        selectCountryFilter: (state, action)=> {
            state.country = action.payload;
        },
    },
    extraReducers:(builder) => {
        builder.addCase(fetchClients.pending, (state) =>{
            state.status = requestLoading
        });
        builder.addCase(fetchClients.fulfilled, (state,action) =>{
            state.clients = action.payload;
            state.status = requestSuccessful;
        });
        builder.addCase(fetchClients.rejected, (state) => {
            state.status = requestMissed;
        }); 
    },
})
export const { toggleCollapse,showHideFilters, setChecked, selectNameFilter, selectClientFilter, selectCountryFilter} = clientSlice.actions;

export const clientsReducer =  clientSlice.reducer;
