
import {Box, Button, Dialog, DialogContent, FormControl, FormLabel, TextField, Typography} from '@mui/material';
import {saveTask, saveTag, fetchTasks, fetchTags, editTask, editTag} from "../../core/state/tasksTags/tasksTagsSlice";
import {closeSaveDialog} from "../../core/state/dialogs/saveTaskTagDialogSlice";
import { useAppDispatch, useAppSelector } from '../../../app/core/state/hooks';
import React, {useEffect, useState} from "react";
import {
    darkGreenColor,
    emptyString,
    greenColor,
    isTaskDialog,
    lightGrayColor,
    whiteColor
} from "../../constants/constants";
import {setName} from "../../core/state/dialogs/saveClientDialogSlice";

export default function SaveTaskTagDialog(props : any) {
    const dispatch = useAppDispatch();
    const { isOpen: dialogIsOpen, dialogType, isEditing, selectedData} = useAppSelector((state) => state.saveTaskTagDialog);
    const [taskTagName, setTaskTagName] = useState("");
    const saveNewTaskTag = async () => {
        if(taskTagName === emptyString)
            return;
        if(isEditing){
            await taskTagEdit();
        }else{
            await taskTagCreation();
        }
        closeDialog();
    }

    const taskTagCreation = async () => {
        if (dialogType === isTaskDialog) {
            await dispatch(saveTask(taskTagName));
            dispatch(fetchTasks());
        } else {
            await dispatch(saveTag(taskTagName));
            dispatch(fetchTags());
        }
    }

    const taskTagEdit = async () => {
        if(dialogType === isTaskDialog){
            await dispatch(editTask({ id: selectedData.task.id || 0, name: taskTagName }));
            dispatch(fetchTasks());
        }else{
            await dispatch(editTag({id: selectedData.tag.id, name: taskTagName}));
            dispatch(fetchTags());
        }
    }

    const closeDialog = () => {
        setTaskTagName("");
        dispatch(closeSaveDialog());
    }

    useEffect(() => {
        if (isEditing) {
            if (dialogType === isTaskDialog){
                setTaskTagName(selectedData.task.name ?? selectedData.task.name ?? emptyString);
            }else{
                setTaskTagName(selectedData.tag.name ?? selectedData.tag.name ?? emptyString);
            }
        }

    }, [isEditing, dialogIsOpen]);

    let confirmationMessage;

    if (dialogType === isTaskDialog) {
        confirmationMessage = 'Edición tarea';
    } else {
        confirmationMessage = 'Edición de tag';
    }

    return (
        <Dialog open={dialogIsOpen} onClose={() => { closeDialog() }} fullWidth>
            <Box sx={{padding: "16px"}}>
                <DialogContent sx={{display:"flex",flexDirection:"row"}}>
                    <Typography variant='h5' sx={{ borderBottom: 2, borderBottomColor: 'rgba(35, 31, 32, 0.1)', pb: 1, flex:1 }}>
                        {confirmationMessage}
                    </Typography>
                </DialogContent>
                <DialogContent sx={{display:"flex",flexDirection:"row"}}>
                    <FormControl fullWidth>
                        <FormLabel sx={{mb:1}}>Nombre</FormLabel>
                        <TextField
                            id="clientName"
                            sx={{backgroundColor:lightGrayColor,borderRadius:5,border:2,borderColor:lightGrayColor}}
                            value={taskTagName}
                            required={true}
                            onChange={(event) => { setTaskTagName(event.target.value); }}
                        />
                    </FormControl>
                </DialogContent>
                <DialogContent>
                    <Button sx={{backgroundColor:greenColor,color:whiteColor,width:120,alignSelf:"end",mr:2,'&:hover': {backgroundColor: darkGreenColor,}}}
                            onClick={async()=>{await saveNewTaskTag()}}>Guardar</Button>
                </DialogContent>
            </Box>

        </Dialog>
    )}