import { Box, IconButton, Typography } from "@mui/material";
import { Edit as EditIcon, Close as CloseIcon } from "@mui/icons-material";
import { darkGray, whiteColor, skyBlueColor, tagColors} from "../../../constants/constants";
import { record as Record } from "../../../core/models/recordModel";
import { openSaveRecordDialog } from "../../../core/state/dialogs/saveRecordDialogSlice";
import { useAppDispatch } from "../../../core/state/hooks";
import { removeRecord } from "../../../core/state/dailyTracker/dailyTrackerSlice";
import { RecordDTO } from "../../../core/models/recordModelDTO";


const WeeklyViewColumn = (props: { records: RecordDTO[] }) => {
  const dispatch = useAppDispatch();
  var records = props.records;
  const styles = {
    skyBlueEraseButton: {backgroundColor:skyBlueColor, borderRadius:"0px 10px 0px 0px", padding:0.6,color:whiteColor},
    purpleEditButton: {backgroundColor:tagColors[0], borderRadius:"0px 0px 0px 4px", padding:0.6,color:whiteColor  },
    iconImage:{height:"1rem", width:"1rem"}
  }

  return <Box display={"flex"}  flexDirection="column" height={"fit-content"} flex={0.2} alignItems={"center"} sx={{backgroundColor:whiteColor}} py={2}>
    {
      records.map((record,index) => {
          return <Box key={record.id} display={"flex"} flexDirection={"column"} alignItems={"center"} width={0.95} flex={1} sx={{backgroundColor:darkGray,borderRadius:10/4}} my={1} mx={1} > 
            <Box display={"flex"} flexDirection={"row"}  width={1}>
              <Box display={"flex"} alignItems={"center"} justifyContent={"end"} flex={0.35}>
                <Typography variant={"h6"}>{record.timeQty.split(":")[0]+":"+record.timeQty.split(":")[1]}</Typography>
              </Box>
              <Box display={"flex"} justifyContent={"center"} alignItems={"center"} flex={1} flexDirection={"column"} sx={{wordBreak:"break-all",align:"justify"}} >
                <Typography variant={"h6"}>{record.projectName}</Typography>
                <Typography variant={"subtitle2"}>{record.taskName}</Typography>
              </Box>
              <Box display={"flex"} alignItems={"end"} justifyContent={"start"} flexDirection={"column"} align-items={"flex-end"} flex={0.1}> 
                <IconButton sx={styles.skyBlueEraseButton} onClick={() => dispatch(removeRecord(record))}> 
                  <CloseIcon sx={styles.iconImage}/>
                </IconButton>
                <IconButton sx={styles.purpleEditButton} onClick={()=>{dispatch(openSaveRecordDialog({record:record,date:record.date}))}}>
                  <EditIcon sx={styles.iconImage}/>
                </IconButton>
              </Box>
            </Box>
          
            <Box display={"flex"} textAlign={"center"} pb={1}>
              <Typography variant={"caption"} alignSelf={"baseline"} padding={"3%"}>{record.description}</Typography>
            </Box>
            
            <Box display={"flex"} flexDirection={"row"} pb={1} justifyContent={"center"}>
              {
              record.tagRecords?.map((tagRecord) => 
                  <Typography variant="body1" key={tagRecord.id} 
                  sx={{backgroundColor:tagColors[(tagRecord.id || 0) % tagColors.length],color:whiteColor,borderRadius:2,mr:1,px:"3%"}}
                  >{tagRecord.name}</Typography>)
              }
            </Box>
          </Box>
      })
    }
  </Box>
}

export default WeeklyViewColumn;