import { Add } from '@mui/icons-material';
import { Client } from '../../core/models/clientModel';
import { openSaveProjectDialog } from '../../core/state/dialogs/saveProjectDialogSlice';
import { ArrowDropDown, Search } from '@mui/icons-material';
import { TreeItem, TreeView } from '@mui/lab';
import { Box, Card, IconButton, MenuItem, Select, TextField, Typography} from '@mui/material';
import React, { useEffect } from 'react';
import {
  darkGrayColor,
  darkGreenColor,
  greenColor,
  lightGrayColor,
  projectStates,
  whiteColor,
  admin,
  pm,
  yearList,
  manager
} from '../../constants/constants';
import { Project } from '../../core/models/projectModel';
import { useAppDispatch, useAppSelector } from '../../core/state/hooks';
import { expandNode,fetchProjectsFromClients, selectClientFilter, selectNameFilter, selectStateFilter, selectYearFilter, setExpandedNodes } from '../../core/state/projects/projectSlice';
import ProjectRow from './projectRow';
import SaveProjectDialog from './saveProjectDialog';

const projectFilter = (project:Project,filter:string)=>{
  return project.name?.toLowerCase().includes(filter.toLowerCase()) || project.code?.toLowerCase().includes(filter.toLowerCase()) 
}

export default function Projects() {
  const dispatch = useAppDispatch();
  
  const { user } = useAppSelector((state) => state.user);
  const { projectsByClient,nameFilter,clientFilter,expandedNodes,stateFilter,yearFilter } = useAppSelector((state) => state.projects);
  
  const existProjectWhenFilter = (client:Client,isClientName:boolean) : Project[] => {
    let newArray = new Array<Project>();
    client.projects.map((project:Project)=> {
      if( ((isClientName && clientFilter===0) || projectFilter(project,nameFilter)) && ((stateFilter === projectStates[0]) ?  true : stateFilter === project.state) && ((yearFilter.toString() === yearList[0] ?  true : yearFilter.toString() === project.startDate?.toString().split("-")[0]))){
        newArray.push(project)
      }})
    return newArray;
  } 


  useEffect(() => {
    dispatch(fetchProjectsFromClients())
  }, [dispatch]);
    return <Box component="main" display={"flex"} flex={1} flexDirection="column">
      <Box display={"flex"} flexDirection="row" borderBottom={2} borderColor='rgba(35, 31, 32, 0.1)' pb={2} alignItems="center">
        <Typography variant='h5' > Proyectos</Typography>
        <Box display={"flex"} flex={0.05}/>
          <TextField
            value={nameFilter}
            onChange={(event)=>{
              dispatch(selectNameFilter(event.target.value))
            }}
            sx={{backgroundColor:whiteColor,boxShadow:"2px 2px 5px rgba(35, 31, 32, 0.1)",borderRadius:5,color:greenColor,mx:2}}
          />
        <IconButton sx={{color:whiteColor,backgroundColor:greenColor}}> 
          <Search/>
        </IconButton>
        <Box flex={1}></Box>
        
        <Select
              label="Año"
              value={yearFilter}
              onChange={(event)=>{dispatch(selectYearFilter(event.target.value))}}
              sx={{flex:0.4,mr:1,borderRadius:4,background:whiteColor,color:darkGrayColor}}
          >
            {
              yearList.map((year)=> <MenuItem value={year} key={year}> {year} </MenuItem>)
            }
        </Select>
        <Select
            label="Cliente"
            value={clientFilter}
            onChange={(event)=>{dispatch(selectClientFilter(event.target.value))
              dispatch(expandNode(event.target.value.toString()))}
            }
            sx={{flex:0.4,mr:1,borderRadius:4,background:whiteColor,color:darkGrayColor}}
        >
          <MenuItem value={0} key={0}>Cliente</MenuItem>
          {projectsByClient && projectsByClient.map((client) => {
              return <MenuItem value={client.id} key={client.id}> {client.name} </MenuItem>;
          })}
        </Select>
        <Select
            label="Estado"
            value={stateFilter}
            onChange={(event)=>{dispatch(selectStateFilter(event.target.value))}}
            sx={{flex:0.4,mr:1,borderRadius:4,background:whiteColor,color:darkGrayColor}}
        >
          {
            projectStates.map((state)=> <MenuItem value={state} key={state}> {state} </MenuItem>)
          }
        </Select>
        <Box flex={0.1}/>
      </Box>
      
    <Card sx={{display:"flex",flexDirection:"column",flex:"1 1 0",borderRadius: 12.5,bgcolor:whiteColor,pb:3,pt:2,mt:2,overflow:"auto",position:"relative"}}>
      <Box display={"flex"} flexDirection="row" alignItems={"center"} pb={1} pl={4.1} textAlign="center"
        borderBottom={3} borderColor={greenColor}>
        <Typography variant='h6' flex={0.2}></Typography>
        <Typography variant='h6' flex={0.20}> Codigo</Typography>
        <Typography variant='h6' flex={0.20}> Fecha de comienzo</Typography>
        <Typography variant='h6' flex={0.20}> Horas Reportadas </Typography>
        <Typography variant='h6' flex={0.20}> Días restantes </Typography>
        <Typography variant='h6' flex={0.20}> Estado</Typography>
        <Box flex={0.15}> </Box>
      </Box>
      <TreeView
        aria-label="file system navigator"
        sx={{overflowY: 'auto',background:lightGrayColor,borderBottom:3,borderColor:whiteColor}}
        onNodeToggle={(event: React.SyntheticEvent, nodeIds: string[]) => {
          dispatch(setExpandedNodes(nodeIds));
        }}
        expanded={(expandedNodes)}
      >
        {projectsByClient.map((client: Client)=> {
          const isClientName = nameFilter !== "" && client.name?.toLowerCase().includes(nameFilter.toLowerCase())
          const hasProjectsFiltered = client.projects.filter( (project) => projectFilter(project,nameFilter) && ((stateFilter === projectStates[0]) ?  true : stateFilter === project.state) && ((yearFilter.toString() === yearList[0] ?  true : yearFilter.toString() === project.startDate?.toString().split("-")[0]))).length > 0 ;

            if((clientFilter !== 0 && client.id === clientFilter) || (clientFilter === 0 && ( hasProjectsFiltered || (isClientName && client.projects.length>0)) )){
              const newProjectsArray = existProjectWhenFilter(client,isClientName);
              if(newProjectsArray.length !== 0){
                return (
                  <TreeItem  key={client.id} nodeId={client.id?.toString()} 
                    sx={{borderBottom:3,borderColor:whiteColor,color:"#918F8F",overflow:"hidden"," .Mui-selected .Mui-focused":{backgroundColor:darkGreenColor}}}
                    label={
                            <Box display={clientFilter === 0 ? "flex":"none"} flexDirection="row" alignItems={"center"} pr={3} height={40}  className="prueba">
                              <Typography color={expandedNodes.find((node)=> node === client.id.toString())? greenColor: darkGrayColor}>{client.name}</Typography>
                              <Box flex={1}/><ArrowDropDown/>
                            </Box>
                          }
                      >
                      {newProjectsArray.map((project)=> {
                        if( ((isClientName && clientFilter===0) || projectFilter(project,nameFilter)) && ((stateFilter === projectStates[0]) ?  true : stateFilter === project.state) && ((yearFilter.toString() === yearList[0] ?  true : yearFilter.toString() === project.startDate?.toString().split("-")[0]))){
                          return <TreeItem nodeId={"P"+project.id} key={project.id} sx={{borderTop:3,borderColor:whiteColor}}
                            label={<ProjectRow project={project} clients={projectsByClient} key={project.id}/>} />
                        }
                      }
                      )}
                  </TreeItem>
                )
              }
            }
        })} 
      </TreeView>
    </Card>
    
    {
      user && (user.appRoleId === admin || user.appRoleId === pm || user.appRoleId === manager)?
      <Box>
        <IconButton sx={{backgroundColor:greenColor,color:whiteColor,ml:"auto",mr:1,mt:-7,"&:hover":{backgroundColor:darkGreenColor},position:"absolute", 
        bottom:"1.5%", right:"0%", zIndex:10}} onClick={() => { dispatch(openSaveProjectDialog({project: undefined, clients:projectsByClient}))}}><Add /></IconButton> 
        <SaveProjectDialog />
      </Box>  
      : <></>
    }
  </Box>
}
