export const drawerWidth = 74;
export const spacingOpenedSidebar = 25;
export const daysOfTheWeek = ["DOM","LUN","MAR","MIE","JUE","VIE","SAB"];
export const sunday = 0;
export const monday = 1;
export const tuesday = 2;
export const wednesday = 3;
export const thursday = 4;
export const friday = 5;
export const saturday = 6;
export const time = 0;
export const dayIndex = 1;
export const optionalRoleServiceIds = [7];
export const cardMustNotShowThisValue = -1;
export const grayColor = "#c4c4c4";
export const lightGrayColor = "#F4F4F4";
export const blackColor = "#181617";
export const lightBlackColor = "#231F20";
export const darkGray = "#F4F0F0";
export const whiteColor = "#FCFCFC";
export const greenColor = "#01E69C";
export const darkGreenColor = "#46967C";
export const lightWhiteBg = "#FFFFFF";
export const violetColor = "#8E54E9"
export const darkGrayColor = "#918F8F"
export const lightBlueColor = '#51AED6'
export const purpleColor = "#8E54E9";
export const skyBlueColor = "#01E69C4F";
export const redColor = "#FF0000"

export const tagColors = [violetColor,darkGreenColor,lightBlueColor]
export const projectStates = ["Estado", "OPEN","CLOSED"];
export const projectStateES = ["Estado", "Activo", "No Activo"]

export const countryList = [
  "Pais",
  "Afghanistan",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bonaire, Sint Eustatius and Saba",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos Islands",
  "Colombia",
  "Comoros",
  "Congo",
  "Cook Islands",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Curaçao",
  "Cyprus",
  "Czechia",
  "Côte d'Ivoire",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Falkland Islands",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and McDonald Islands",
  "Holy See",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Republic of North Macedonia",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "Réunion",
  "Saint Barthélemy",
  "Saint Helena, Ascension and Tristan da Cunha",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Martin",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States Minor Outlying Islands",
  "United States of America",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Viet Nam",
  "Virgin Islands",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
  "Åland Islands",
];
export const manager = 5;
export const superAdmin = 4;
export const admin = 3;
export const pm = 2;
export const user = 1;

export const requestPending = "pending";
export const requestLoading = "loading";
export const requestSuccessful = "success";
export const requestMissed = "error";
export const createRecordSuccess = "El registro fue creado";
export const createRecordFail = "No se pudo crear el registro";
export const editRecordSuccess = "El registro fue editado";
export const editRecordFail = "No se pudo editar el registro";

export const userUpdateSuccess = "El Usuario fue editado";
export const userUpdateError = "No se pudo editar el usuario";
export const clientCreateSuccess = "Cliente creado";
export const allAreas = "Todas las Areas";
export const clientCreateError = "No pudo crearse el cliente";
export const bonoCreateSuccess = "Bono creado";
export const bonoCreateError = "No se pudo agregar el Bono";
export const clientUpdateSuccess = "El registro fue editado"
export const clientUpdateError = "No se pudo editar el registro"
export const projectUpdateSuccess = "Proyecto editado";
export const projectUpdateError = "No pudo editarse el proyecto";
export const recordDeleteSuccess = "El registro fue borrado";
export const recordDeleteError = "No pudo borrarse el registro";
export const projectCreateSuccess = "Proyecto creado";
export const projectCreateError = "No pudo crearse el proyecto";
export const projectDeleteSuccess = "El Proyecto fue borrado.";
export const projectDeleteError = "No se pueden eliminar Proyectos con horas registradas";
export const StageHasHoursError = "No se pueden eliminar Etapas con horas registradas";
export const StageHasHoursErrorFromResource = "No se pueden eliminar Recurso con horas registradas a la Etapa";
export const projectStageDeleteSuccess = "La Etapa fue borrada.";
export const projectStageDeleteError = "No se pudo eliminar la Etapa";
export const projectStageCreateSuccess = "Etapa creado";
export const projectStageCreateError = "No pudo crearse la etapa";
export const projectStageUpdateSuccess = "Etapa editado";
export const projectStageUpdateError = "No pudo editarse la etapa";
export const taskDeleteSuccess = "La Tarea fue borrada.";
export const taskEditSuccess = "La Tarea fue editada.";
export const taskEditError = "Tarea ya existe";
export const taskDeleteError = "No se pudo eliminar la Tarea";
export const taskCreateSuccess = "Tarea creada";
export const taskCreateError = "Tarea ya existe";
export const tagCreateSuccess = "Tag creado";
export const tagCreateError = "Tag ya existe";
export const tagEditSuccess = "Tag ya existe";
export const tagEditError = "No se pudo editar el Tag";
export const clientDeleteSuccess = "El Cliente fue borrado.";
export const clientDeleteError = "No se pueden eliminar Clientes con proyectos asociados";
export const salesRecurrencesSuccess = "Las ventas han sido registradas correctamente";
export const salesRecurrencesError = "Ops! :( No se pudieron registrar las ventas";
export const saleDeleteSuccess = "La venta fue eliminada";
export const saleDeleteError = "No se puedo eliminar la venta";
export const salesEditSuccess = "La venta ha sido actualizada correctamente";
export const salesEditError = "Ops! :( No se pudo actualizar la venta";

export const invalidStartDateMessage = "La fecha de inicio debe ser anterior a la de finalización";
export const invalidEndDateMessage = "La fecha de finalización debe ser posterior a la de inicio";
export const invalidNameMessage = "El nombre debe contener menos de 255 caracteres"
export const invalidDescriptionMessage = "La descripción debe contener menos de 256 caracteres";
export const invalidNumberMessage = "Debe de ser un número válido";
export const invalidNumberTypeMessage = "Debe ser un número";

export const exceddingRecordsHours = "Cannot save record: exceeding maximum hours per day";
export const exceddingRecordsHoursMessage = "Máximo de horas diarias excedidas";

export const recordCreateWeekSuccess = "Registro copiado a toda la semana";
export const recordCreateWeekFailPerDay = "Al menos un registro no pudo ser copiado ya que supero el limite de horas diarias";
export const recordCreateWeekError = "No fueron creados todos los registros";

export const salesCreateSuccess = "Ventas agregadas correctamente.";
export const salesCreateFailAll = "Al menos una venta no a podido agregarse correctamente, campos inválidos.";
export const salesCreateError = "No se pudieron agregar las ventas.";
export const salesCreateFail = "";

export const addSaleRecurrentCreateFail = "Para poder confirmar la venta, debe rellenar todos los campos.";
export const selectDateOfPaymentLesserFail = "La fecha de pago no válida";

export const recurringY='Y';
export const recurringN='N';

export const purchasesRecurrencesSuccess = "Las compras han sido registradas correctamente";
export const purchasesRecurrencesError = "Ops! :( No se pudieron registrar las compras";
export const purchaseDeleteSuccess = "La compra fue eliminada";
export const purchaseDeleteError = "No se puedo eliminar la compra";
export const purchasesEditSuccess = "La compra ha sido actualizada correctamente";
export const purchasesEditError = "Ops! :( No se pudo actualizar la compra";
export const purchasesCreateSuccess = "Compras agregadas correctamente.";
export const purchasesCreateFailAll = "Al menos una compra no a podido agregarse correctamente, campos inválidos.";
export const purchasesCreateError = "No se pudieron agregar las compras.";
export const purchasesCreateFail = "";

export const addPurchaseRecurrentCreateFail = "Para poder confirmar la compra, debe rellenar todos los campos.";

export const noneValue = 'none';

export const isTaskDialog = 'task';
export const emptyString = '';
export const nonSubArea = "Subarea no disponble";
export const nonCompanyRole = "Rol no disponible";

export const successSave = 0;
export const errorSave = 1;
export const exceedingSave = 2;
export const supportTask = 'Ticket Soporte';

export const TypeOfMoneys = ["UYU","USD"]

export const documentList = [
  "Factura",
  "NC",
  "N/A",
]

export const monthList = [
 "Meses",
 "Enero",
 "Febrero",  
 "Marzo",
 "Abril",  
 "Mayo",
 "Junio",  
 "Julio",
 "Agosto",  
 "Septiembre",
 "Octubre",
 "Noviembre",
 "Diciembre",
]

export const areaList = [
  "Todas las Areas",
  "Desarrollo",
  "Consultoría",
  "Comercial",
  "Recursos Humanos",
  "Administración",
  "Marketing",
  "Dirección",
];

export const yearList = [
  "Años",
  "2029",
  "2028",
  "2027",
  "2026",
  "2025",
  "2024",
  "2023",
  "2022",
  "2021",
  "2020",
  "2019",
  "2018",  
  "2017",
  "2016",
  "2015",
  "2014",
  "2013",
  "2012",
  "2011",
]