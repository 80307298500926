import { createAsyncThunk,createSlice, current } from "@reduxjs/toolkit";
import { SubArea } from "../../models/subAreaModel";
import { getSubAreas, getAllAreas, updateResource, getCompanyRoles, getResourceIncomeById} from "../../http/httpClient";
import { Area } from "../../models/areaModel";
import { toast } from "react-toastify";
import { RootState } from "../store";
import { fetchPaginatedResources } from "../resources/resourceSlice";
import { requestLoading, requestSuccessful, requestMissed, invalidNumberMessage, TypeOfMoneys,requestPending } from "../../../constants/constants";
import { CompanyRole } from "../../models/companyRoleModel";
import {ResourceDTO} from "../../models/resourceModelDTO";

const namespace = 'saveResourceDialog';
const initialState = {
    isOpen: false,
    moneys: [{name:"UYU - Pesos Uruguayos", id:1},{name:"USD - Dolares", id:2}],
    weekHours:[
        {hours:4,id:4},
        {hours:6,id:6},
        {hours:8,id:8},
        {hours:9,id:9},
        {hours:'No ', id:0}
    ],
    areas:new Array<Area>(),
    areasStatus : requestPending,
    subAreas:new Array<SubArea>(),
    subAreasStatus : requestPending,
    resourceIncomeStatus: requestPending,
    companyRoleList : new Array<CompanyRole>(),
    companyRoleListStatus : requestPending,
    selectedData:{
        id: 0,
        userId:'',
        name: '',
        lastName: '',
        image: '',
        companyRoleId: 0,
        companyRole : '',
        subAreaId:0,
        areaId:0,
        typeOfMoneyName : TypeOfMoneys[0],
        typeOfMoneyID : 1,
        resourceHours :0,
        resourceLiquid:0,
        resourceNominal:0,
        resourceIncomeUpdateDate: new Date(),
    },
    status : requestPending,
}

export const fetchSubAreas = createAsyncThunk(
    `${namespace}/fetchSubAreas`,
    async () => await getSubAreas(),
);

export const fetchResourceIncome = createAsyncThunk(
    `${namespace}/fetchResourceIncome`,
    async (resourceId:number) => await getResourceIncomeById(resourceId),
);
export const fetchAreas = createAsyncThunk(
    `${namespace}/fetchAreas`,
    async () => await getAllAreas(),
);

export const fetchCompanyRole = createAsyncThunk(
    `${namespace}/fetchCompanyRole`,
    async () => await getCompanyRoles(),
);

export const saveResource = createAsyncThunk(
    `${namespace}/saveResource`,
    async ({resourceId, offset, limit, area, searchTerm, }: {resourceId:number, offset: number; limit: number, area: string, searchTerm: string},thunkAPI) => {
        const { saveResourceDialog : state } = thunkAPI.getState() as RootState;

        await updateResource({
            id: resourceId,
            resourceHours : state.selectedData.resourceHours,
            resourceLiquid : state.selectedData.resourceLiquid,
            resourceNominal : state.selectedData.resourceNominal,
            TypeOfMoneyName : (state.selectedData.typeOfMoneyID === 1 ? TypeOfMoneys[0]:TypeOfMoneys[1]),
            subAreaId : state.selectedData.subAreaId,
            companyRole : state.selectedData.companyRole,
            CompanyRoleId : state.selectedData.companyRoleId,
        });
        thunkAPI.dispatch(fetchPaginatedResources({offset: offset,limit: limit, area:area, searchTerm: searchTerm}))
    }
);

export const saveResourceDialog = createSlice({
    name: namespace,
    initialState : initialState,
    reducers:{
        openSaveResourceDialog:(state, action) => {
            state.selectedData = {...initialState.selectedData}
            state.isOpen = true;
            if(action.payload.resource){
                const resource = action.payload.resource as ResourceDTO;
                state.selectedData.id = resource.id;
                state.selectedData.userId = resource.id.toString();
                state.selectedData.name = resource.name as string;
                state.selectedData.lastName = resource.lastName as string;
                state.selectedData.image = resource.image as string;
                state.selectedData.companyRole = resource.companyRole;
                state.selectedData.companyRoleId = resource.companyRoleId;
                state.selectedData.subAreaId = resource.subAreaId as number;
                state.selectedData.areaId = resource.areaId as number;
            }
        },
        closeSaveResourceDialog: (state) => {
            state.isOpen = false;
        },
        setDateUpdate: (state,action) => {
            state.selectedData.resourceIncomeUpdateDate = action.payload;
        },
        setNominal: (state, action) =>{
            if(!isNaN(action.payload)){
                state.selectedData.resourceNominal = action.payload;
            }else{
                toast.error(invalidNumberMessage);
            }
        },
        setLiquid: (state,action) =>{
            if(!isNaN(action.payload)){
                state.selectedData.resourceLiquid = action.payload;
            }else{
                toast.error(invalidNumberMessage);
            }
        },
        setHours: (state, action) =>{
            state.selectedData.resourceHours = action.payload;
        },
        setTypeOfMoney: (state, action) =>{
            if(action.payload){
                state.selectedData.typeOfMoneyName = TypeOfMoneys[0]
            }else{
                state.selectedData.typeOfMoneyName = TypeOfMoneys[1]
            }
            state.selectedData.typeOfMoneyID = action.payload;
        },
        setRole:(state, action) =>{
            state.selectedData.companyRoleId = action.payload;
        },
        setArea:(state, action) =>{
            state.selectedData.areaId = action.payload;
            const saId = current(state).subAreas.find((sa) => sa.areaId === action.payload)?.id;
            state.selectedData.subAreaId = saId ? saId : 1;
        },
        setSubArea:(state, action) =>{
            state.selectedData.subAreaId = action.payload;
        },filterArea:(state,action)=> {

        },
    },
    extraReducers:(builder) => {
        builder.addCase(fetchSubAreas.pending, (state) => {
            state.subAreasStatus = requestLoading;
        }).addCase(fetchSubAreas.fulfilled, (state, action) => {
            state.subAreas = action.payload;
            state.subAreasStatus = requestSuccessful;
        }).addCase(fetchSubAreas.rejected, (state) => {
            state.subAreasStatus = requestMissed;
        });

        builder.addCase(fetchResourceIncome.pending, (state) => {
            state.resourceIncomeStatus = requestLoading;
        }).addCase(fetchResourceIncome.fulfilled, (state, action) => {
            if(action.payload !== undefined){
                state.selectedData.typeOfMoneyID = action.payload.typeOfMoney===undefined? 1: TypeOfMoneys.indexOf(action.payload.typeOfMoney)+1;
                state.selectedData.resourceLiquid = action.payload.liquid;
                state.selectedData.resourceHours = action.payload.hours;
                state.selectedData.resourceIncomeUpdateDate = action.payload.fechaUpdate;
                state.selectedData.resourceNominal = action.payload.nominal;
            }else {
                state.selectedData.resourceLiquid = 0;
                state.selectedData.resourceHours = 0;
                state.selectedData.resourceNominal = 0;
                state.selectedData.typeOfMoneyID = 1;
            }
            state.resourceIncomeStatus = requestSuccessful;
        }).addCase(fetchResourceIncome.rejected, (state) => {
            state.resourceIncomeStatus = requestMissed;
        });

        builder.addCase(fetchAreas.pending, (state) => {
            state.areasStatus = requestLoading;
        }).addCase(fetchAreas.fulfilled, (state, action) => {
            state.areas = action.payload;
            state.areasStatus = requestSuccessful;
        }).addCase(fetchAreas.rejected, (state) => {
            state.areasStatus = requestMissed;
        });

        builder.addCase(fetchCompanyRole.pending, (state) => {
            state.companyRoleListStatus = requestLoading;
        }).addCase(fetchCompanyRole.fulfilled, (state, action) => {
            state.companyRoleList = action.payload;
            state.companyRoleListStatus = requestSuccessful;
        }).addCase(fetchCompanyRole.rejected, (state) => {
            state.companyRoleListStatus = requestMissed;
        });
       
    },
})

export const saveResourceDialogReducer = saveResourceDialog.reducer;
export const { openSaveResourceDialog, closeSaveResourceDialog, setDateUpdate, setArea,
                setSubArea,setLiquid,setHours,setNominal,setRole,setTypeOfMoney } = saveResourceDialog.actions;