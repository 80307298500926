import { useAppDispatch, useAppSelector } from "../../core/state/hooks";
import { Box, Button, Dialog, DialogContent, FormControl,MenuItem, FormLabel, ButtonGroup , Select, TextField, Typography } from "@mui/material";
import { fetchResourceBonus, closeSaveBonusDialog, setBonusResource, setTypeOfMoney, saveBonusData,
    } from "../../core/state/dialogs/saveBonusDialogSlice";
import { darkGreenColor, greenColor, lightGrayColor, whiteColor } from "../../constants/constants";
import {useEffect} from "react";

export default function SaveBonusDialog() {
    const dispatch = useAppDispatch();
    const {isOpen: dialogIsOpen, selectedData,MoneysType} = useAppSelector((state) => state.saveBonusDialog);
    const {selectedArea, page, rowsPerPage, searchFilter} = useAppSelector((state) => state.resources);

    useEffect(() => {
        if(dialogIsOpen){
            dispatch(fetchResourceBonus(selectedData.id))
        }
    },[dialogIsOpen])

    const saveNewBonus = () => {
        dispatch(saveBonusData({resourceId: selectedData.id,offset: page*rowsPerPage,limit: rowsPerPage,area: selectedArea, searchTerm: searchFilter}));
        dispatch(closeSaveBonusDialog());
    }
  
    return (
    <Dialog open={dialogIsOpen} onClose={() => { dispatch(closeSaveBonusDialog()); }} maxWidth="md">
        <DialogContent sx={{display:"flex", flexDirection:"column", alignItems:"center", m:3,p:2 }}>
            <DialogContent>
                <Box>
                    <Typography variant='h4'>Enviar BONO</Typography>
                </Box>
            </DialogContent>

            <DialogContent>
                <FormControl sx={{width:250}}>
                    <FormLabel sx={{pb:1}}><Typography variant="h6">Beneficiari@</Typography></FormLabel>
                    <TextField 
                    sx={{backgroundColor:lightGrayColor,borderRadius:4,}} 
                    disabled value={selectedData.name + " " + selectedData.lastName}/>
                </FormControl>
            </DialogContent>
            
            <DialogContent>
                <FormControl sx={{width:250}}>
                    <FormLabel sx={{pb:1}}><Typography variant="h6">Monto</Typography></FormLabel>
                    <ButtonGroup sx={{backgroundColor:lightGrayColor,borderRadius:4}} disableElevation variant="contained" >
                        <Select 
                            value={selectedData.typeOfMoney} sx={{backgroundColor:lightGrayColor,borderRadius:4}}
                            onChange={ event => dispatch(setTypeOfMoney(event.target.value))}
                        >
                            {MoneysType && MoneysType.map((money) => {
                                return <MenuItem value={money.id} key={money.id}>{money.name}$</MenuItem>;
                            })}
                        </Select>
                        <TextField value={selectedData.money} onChange={event => dispatch(setBonusResource(event?.target.value))}/>
                    </ButtonGroup >
                </FormControl>
            </DialogContent>

            <DialogContent>
                <Box>
                    <Button onClick={saveNewBonus} sx={{p:1,backgroundColor:greenColor, color:whiteColor, width:200, '&:hover': {backgroundColor: darkGreenColor,}}}>
                        <Typography variant="h5">Confirmar</Typography>
                    </Button>
                </Box>
            </DialogContent>

        </DialogContent>
    </Dialog>
    );
}