import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { CssBaseline } from '@mui/material';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import { msalConfig } from './app/core/authorization/authConfig';
import { store } from './app/core/state/store';

export const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
      <Provider store={store}>
        <CssBaseline/>
        <MsalProvider instance={msalInstance}>
            <App />
        </MsalProvider>
      </Provider>,
  document.getElementById('root')
);





