import { Add } from "@mui/icons-material";
import { Box, Card, Typography, IconButton, Hidden, MenuItem, Select, TextField, Checkbox } from "@mui/material";
import { useEffect } from "react";
import {
    greenColor,
    whiteColor,
    darkGreenColor,
    projectStates,
    darkGrayColor,
    countryList,
    admin,
    pm,
    lightGrayColor,
    manager
} from "../../constants/constants";
import { useAppDispatch, useAppSelector } from "../../core/state/hooks";
import { setChecked, selectNameFilter, fetchClients, selectCountryFilter, fetchDeleteClient } from "../../core/state/client/clientSlice";
import "./client.css";
import { openSaveClientDialog } from "../../core/state/dialogs/saveClientDialogSlice";
import SaveClientDialog from "./saveClientDialog";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/DeleteIcon.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/EditIcon.svg";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Search } from '@mui/icons-material';
import React from "react";
import { openRemoveDialog } from "../../core/state/dialogs/removeClientDialogSlice";
import RemoveClientDialog from "./removeClientDialog";

export default function Clients() {
  const dispatch = useAppDispatch();
  const { selectedDate } = useAppSelector((state) => state.date);
  const { clients, checkBoxChecked, country, nameFilter} = useAppSelector((state) => state.clients);
  const { user } = useAppSelector((state) => state.user);
  const [isNew, setIsNew] = React.useState(true);

  useEffect(() => {
    dispatch(fetchClients());
  }, [dispatch]);

  return (
    <Box component="main" display={"flex"} flex={1} flexDirection="column" >
      <Box display={"flex"} flexDirection="row" borderBottom={2} borderColor='rgba(35, 31, 32, 0.1)' pb={2} alignItems="center">
        <Typography variant='h5' > Clientes</Typography>
        <Box display={"flex"} flex={0.05}/>
          <TextField
            placeholder="Nombre"
            value={nameFilter}
            onChange={(event)=>{
              dispatch(selectNameFilter(event.target.value))
            }}
            sx={{backgroundColor:whiteColor,boxShadow:"2px 2px 5px rgba(35, 31, 32, 0.1)",borderRadius:5,color:greenColor,mx:2}}
          />
       
        <IconButton sx={{color:whiteColor,backgroundColor:greenColor}}> 
          <Search/>
        </IconButton>
        <Box flex={1}/>

        <Checkbox
            sx={{color:darkGrayColor, '&.Mui-checked': {
              color: greenColor}}}
            checked={checkBoxChecked}
            onChange={(event)=>{
              dispatch(setChecked(event.target.checked))
            }}
            inputProps={{ 'aria-label': 'controlled' }}
          />
          <Box sx={{mr:3, color:darkGrayColor}}>
            Activo
          </Box>

        <Select
              label="Country"
              value={country}
              onChange={(event)=>{dispatch(selectCountryFilter(event.target.value))}}
              sx={{flex:0.6,mr:1,borderRadius:4,background:whiteColor,color:darkGrayColor}}
          >
            {
              countryList.map((country)=> <MenuItem value={country} key={country}> {country} </MenuItem>)
            }
        </Select>
        
        <Box flex={0.1}/>
      </Box>
      
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: "1 1 0",
          borderRadius: 12.5,
          bgcolor: whiteColor,
          pb: 3,
          mt: 2,
          overflow: "auto",
        }}
      >
        <Table >
          <TableHead sx={{borderBottom:3, borderColor:greenColor}}>
            <TableRow  >
            <TableCell />
              <TableCell >
                <Typography variant="h6" textAlign="left" ml={2}> Cliente </Typography>
              </TableCell>
              <TableCell >
                <Typography variant="h6" textAlign="left" ml={0}> País </Typography>
              </TableCell>

              <Hidden smDown>
                <TableCell>
                  <Typography variant="h6" textAlign="center"> Fecha de alta </Typography>
                </TableCell>
              </Hidden>
              
              <Hidden mdDown>
                <TableCell>
                  <Typography variant="h6" textAlign="center"> Proyectos activos </Typography>
                </TableCell>
              </Hidden>

              <Hidden mdDown>
                <TableCell>
                  <Typography></Typography>
                </TableCell>
              </Hidden>
              
              <TableCell></TableCell>
            </TableRow>        
          </TableHead>
          
          <TableBody >
            {
            (clients
              .filter( client => (
                  (client.name.toLowerCase().match(nameFilter.trim().toLowerCase()))
                ))
              .filter( client => (  country === countryList[0] ? client.country !== countryList[0] : client.country?.match(country)))
              .filter( client => checkBoxChecked === true ? client.projects.filter( project => project.state === projectStates[1]).length > 0 : client.id !== 0 )).map((client,index) => {
                return (
                <TableRow key={client.id} sx={{backgroundColor: (index % 2) ? whiteColor : lightGrayColor}}>
                  <TableCell />
                  <TableCell >
                    <Typography textAlign="left" ml={2}>
                      {client.name}
                    </Typography>
                  </TableCell >
                  <TableCell >
                    <Typography textAlign="left">
                      {client.country}
                    </Typography>
                  </TableCell >
                  <Hidden smDown>
                    <TableCell >
                      <Typography textAlign="center">
                        {client.registrationDate?.toString().substring(0, 10)}
                      </Typography>
                    </TableCell >
                  </Hidden>
                  
                  <Hidden mdDown>
                    <TableCell >
                      <Typography textAlign="center">
                      {client.projects.filter(f => f.state === projectStates[1]).length}
                      </Typography>
                    </TableCell >
                  </Hidden>

                  <Hidden smDown><TableCell sx={{ width:'5%'}} /></Hidden>
                 
                  <TableCell sx={{p:0}}>
                    {
                      (user.appRoleId === admin || user.appRoleId === pm || user.appRoleId === manager) ? <IconButton onClick={()=> {
                        setIsNew(false);
                        dispatch(openSaveClientDialog({ client: client , date: selectedDate }))
                      }}  children={<EditIcon />} />:<></>
                    }
                    {
                      user.appRoleId === admin ?<IconButton onClick={() => {
                        dispatch(openRemoveDialog(client.id))
                      }}
                       children={<DeleteIcon />}/>:<></>
                    }

                  </TableCell >           
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        </Card>
        { user.appRoleId === admin || user.appRoleId === pm || user.appRoleId === manager ? <IconButton
          sx={{   
            backgroundColor: greenColor,
            color: whiteColor,
            ml: "auto",
            mr: 1,
            mt: -3.2,
            mb: 0,
            zIndex:1,
            "&:hover": { backgroundColor: darkGreenColor },
          }}
          size='large'
          onClick={() => {
            setIsNew(true);
            dispatch(
              openSaveClientDialog({ client: undefined, date: selectedDate })
            );
          }}
          children={<Add />}
        /> :<></>     }
      <SaveClientDialog isNew={isNew} />
      <RemoveClientDialog />
    </Box>
  );
}