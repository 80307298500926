import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { requestLoading, requestSuccessful, requestMissed,requestPending } from "../../../constants/constants";
import { deleteRecord, getWeeklyRecords } from "../../http/httpClient";
import { record, record as Record } from "../../models/recordModel";
import { closeRemoveDialog } from "../dialogs/removeRecordDialogSlice";
import { fetchMonthlyRecords } from "../monthlyTracker/monthlyTrackerSlice";
import { RootState } from "../store";
import { RecordDTO } from "../../models/recordModelDTO";

const namespace = 'dailyTracker';
const initialState = {
    weekRecords: new Array<{
        date: Date,
        records: Array<RecordDTO>,
        dayHoursQty: String,
    }>(),
    weeklyViewClicked: false,
    weekRecordsStatus: requestPending,
}

export const fetchWeeklyRecords = createAsyncThunk(
    `${namespace}/fetchWeeklyRecords`,
    async (idResource: number, thunkAPI) => {
        const { date: { selectedDate } } = thunkAPI.getState() as RootState;
        return await getWeeklyRecords(idResource,selectedDate);
    }
)

export const removeRecord = createAsyncThunk(
    `${namespace}/removeRecord`,
    async (record : RecordDTO, thunkAPI) => {
        await deleteRecord(record.id);
        thunkAPI.dispatch(fetchMonthlyRecords(record.resourceId));
        thunkAPI.dispatch(fetchWeeklyRecords(record.resourceId));
        thunkAPI.dispatch(closeRemoveDialog());
    }
)

export const dailyTrackerSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        handleWeeklyViewClick (state) {
            state.weeklyViewClicked = !state.weeklyViewClicked;
        }, 
        addRecord (state, action) {
            let record = action.payload;
            const newWeekRecords = [...state.weekRecords];
            let dayOfTheWeek = (record.date as Date).getDay() - 1;
            newWeekRecords[dayOfTheWeek].records? newWeekRecords[dayOfTheWeek].records.push(record) : newWeekRecords[dayOfTheWeek].records = [record]
        },
        removeRecordFromState(state,action){
            var recordIndex = state.weekRecords[action.payload.selectedDate.getDay() - 1].records.findIndex((record) => record.id === action.payload.recordId);
            state.weekRecords[action.payload.selectedDate.getDay() - 1].records.splice(recordIndex,1);
        }         
    },
    extraReducers: (builder) => {
        builder.addCase(fetchWeeklyRecords.pending, (state) => {
            state.weekRecordsStatus = requestLoading;
        }).addCase(fetchWeeklyRecords.fulfilled, (state, action) => {
            state.weekRecords = action.payload;
            state.weekRecordsStatus = requestSuccessful;
        }).addCase(fetchWeeklyRecords.rejected, (state) => {
            state.weekRecordsStatus = requestMissed;
        });
    },
})

export const dailyTrackerReducer = dailyTrackerSlice.reducer;

export const {handleWeeklyViewClick, addRecord, removeRecordFromState} = dailyTrackerSlice.actions;