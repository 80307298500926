import {time, dayIndex, cardMustNotShowThisValue, monday, sunday, saturday, thursday, tuesday, greenColor} from "../../../constants/constants";
import { useAppSelector } from "../../../core/state/hooks";
import DayInfo from "./dayInfo";
import {getDayName} from "../dailyTracker/dailyTracker";
import { Box, Typography } from "@mui/material";

const DayOfTheWeekColumn = (props:{dayOfTheWeek:number, exteriorColumn: boolean, records: Array<[string, number]>}) =>{
  const dayOfTheWeek = props.dayOfTheWeek;
  const exteriorColumn = props.exteriorColumn;
  const records = props.records;
  const { weekDayOfFirstDayOfMonth } = useAppSelector((state) => state.monthlyTracker);
  const { selectedDate } = useAppSelector((state) => state.date);

  return <Box display={"flex"} flexDirection={"column"} flex={1} justifyContent={"space-between"} textAlign={"center"}
        className={(dayOfTheWeek === tuesday || dayOfTheWeek === thursday || dayOfTheWeek === saturday ? " light-gray-bg" : "")}>

      <Box display={"flex"} flexDirection={"row"} flex={1} alignItems={"center"} justifyContent={"center"} borderBottom={"1px solid rgba(0, 0, 0, 0.03)"}>
          <Box display={"flex"} flexDirection={"column"} flex={dayOfTheWeek === sunday? 0 : 0.6}></Box>
          <Box display={"flex"}  pt={{xs:1, sm:3 ,md:5}} mb={1} textAlign={"center"} className={ ((getDayName(selectedDate.getDay()) === getDayName(dayOfTheWeek)) ? "" : "opacity-25")} >
              <Typography className="" variant="h6" sx={{color: ((getDayName(selectedDate.getDay()) === getDayName(dayOfTheWeek)) ? greenColor : "")}}>{getDayName(dayOfTheWeek)}</Typography>
          </Box>
          <Box display={"flex"} flexDirection={"column"} flex={dayOfTheWeek === monday? 0 : 0.6}></Box>
      </Box>
      {
        dayOfTheWeek !== sunday && (dayOfTheWeek < weekDayOfFirstDayOfMonth || weekDayOfFirstDayOfMonth === sunday) ? 
        <DayInfo time={""} key={Math.random()} isInMonthLastPosition={false} dayNumber={cardMustNotShowThisValue}  isInOuterColumn={exteriorColumn} 
          isAtLeft={dayOfTheWeek === monday} 
        />
        : <></>
      }
      {
        records && records.map((timeRegister, index) =>
        <DayInfo time={timeRegister[time]} isInMonthLastPosition={index === records.length - 1}
          key={timeRegister[dayIndex]} dayNumber={timeRegister[dayIndex]} isInOuterColumn={exteriorColumn} isAtLeft={dayOfTheWeek === sunday}
        />)
      }
  </Box>
}

export default DayOfTheWeekColumn;