import { createSlice } from "@reduxjs/toolkit";
import { requestPending } from "../../../constants/constants";
import {task} from "../../models/taskModel";
import {Tag} from "../../models/tagModel";

const namespace = 'saveTaskTagDialog'
const initialState = {
    isOpen: false,
    isEditing: false,
    selectedData:{
        task: new task(),
        tag: new Tag(),
    },
    status: requestPending,
    dialogType: '',

}

export const saveTaskTagDialog = createSlice({
    name: namespace,
    initialState: initialState,
    reducers: {
        openSaveTaskDialog: (state) => {
            state.dialogType = 'task';
            state.isEditing = false;
            state.isOpen = true;
        },
        openSaveTagDialog: (state) => {
            state.dialogType = 'tag';
            state.isEditing = false;
            state.isOpen = true;
        },
        closeSaveDialog: (state) => {
            state.isOpen = false;
            state.dialogType = '';
        },
        openEditTagDialog: (state, action) => {
            state.selectedData.tag = action.payload;
            state.isEditing = true;
            state.isOpen = true;
            state.dialogType = 'tag';
        },
        openEditTaskDialog: (state, action) => {
            state.selectedData.task = action.payload;
            state.isEditing = true;
            state.isOpen = true;
            state.dialogType = 'task';
        }
    },
})

export const saveTaskTagDialogReducer = saveTaskTagDialog.reducer;
export const { openSaveTaskDialog, openEditTaskDialog, openEditTagDialog, openSaveTagDialog, closeSaveDialog } = saveTaskTagDialog.actions;