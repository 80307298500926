import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SaleDTO } from "../../models/saleModelDTO";
import {
    getPaginatedSales,
    putRecurrency,
    deleteSale,
    getRecurringSales
} from "../../http/httpClient";
import { emptyString, monthList, requestLoading, requestMissed, requestPending, requestSuccessful } from "../../../constants/constants";
import { RootState } from "../store";
import { closeRemoveDialog } from "../dialogs/removeSaleDialogSlice";

const namespace = "sales";

export const fetchGetPaginatedSales = createAsyncThunk(
    `${namespace}/fetchGetSales`,
    async ({ offset, limit, month, year, searchTerm}: {offset: number; limit: number; month: string; year: string, searchTerm: string}) => {
        return await getPaginatedSales(offset, limit, month, year, searchTerm);
    }
);

export const fetchDeleteSale = createAsyncThunk(
    `${namespace}/fetchDeleteSale`,
    async ({ saleId, offset, limit, month, year, searchTerm }: { saleId: number; offset: number; limit: number; month: string; year: string, searchTerm: string}, thunkAPI) => {
        await deleteSale(saleId);
        thunkAPI.dispatch(fetchGetPaginatedSales({offset: offset,limit: limit,month: month, year:year, searchTerm: searchTerm}))
        thunkAPI.dispatch(closeRemoveDialog());
    }
);

export const fetchRecurringSales = createAsyncThunk(
    `${namespace}/fetchRecurringSales`,
    async () => {
        return getRecurringSales();
    }
);

export const addNewsRecurrency = createAsyncThunk(
    `${namespace}/addNewsRecurrency`,
    async ({ offset, limit, month, year, searchTerm }: { offset: number; limit: number; month: string; year: string, searchTerm: string },thunkAPI) => {
        const {sales : state} = thunkAPI.getState() as RootState;
        await putRecurrency(state.changedRecurrences);
        thunkAPI.dispatch(fetchGetPaginatedSales({offset: offset,limit: limit,month: month, year:year, searchTerm:searchTerm}));
    }
)

export const salesSlice = createSlice({
    name:`${namespace}`,
    initialState:{
        sales: new Array<SaleDTO>(),
        allRecurrencyInTheSystem: new Array<number>(),
        salesRecurrences: new Array<number>(),
        changedRecurrences: new Array<number>(),
        recurringSalesStatus: requestPending,
        selectedMonth : monthList[0],
        status: requestPending,
        searchFilter : emptyString,
        yearFilter : new Date().getFullYear(),
        resourcesCount: 0,
        page: 0,
        rowsPerPage: 6,
    },
    reducers:{
        selectSearchFilter: (state,action) => {
            state.searchFilter = action.payload;
        },selectedMonthFilter:(state,action) => {
            state.selectedMonth = action.payload;
        },setChangedRecurrences:(state,action) =>{
            state.changedRecurrences = action.payload
        },setSystemRecurrences:(state,action) =>{
            state.allRecurrencyInTheSystem = action.payload
        },cancelNewRecurrency:(state) =>{
            state.salesRecurrences = state.allRecurrencyInTheSystem;
        }, setSelected: (state,action) => {
            state.salesRecurrences = action.payload
        },changeElementRecurrency: (state,action) => {
            const elementId = parseInt(action.payload);
            const index = state.salesRecurrences.indexOf(elementId);
            if (index !== -1) {
                state.salesRecurrences.splice(index, 1);
            } else {
                state.salesRecurrences.push(elementId);
            }

        },selectYearFilter:(state,action) =>{
            state.yearFilter = action.payload
        },setRowsPerPage:(state,action) => {
            state.rowsPerPage = action.payload
        },setFilters:(state,action) => {
            state.rowsPerPage = action.payload;
        },setPage:(state,action) => {
            state.page = action.payload;
        },cleanFilter:(state)=>{
            state.searchFilter = emptyString;
            state.selectedMonth = monthList[0];
            state.yearFilter = new Date().getFullYear();
        }
    },
    extraReducers:(builder) =>{
        builder.addCase(fetchGetPaginatedSales.pending, (state) =>{
            state.status = requestLoading;
        });
        builder.addCase(fetchGetPaginatedSales.fulfilled, (state,action) =>{
            state.sales = action.payload.pageItems;
            state.resourcesCount = action.payload.itemsCount;
            state.status = requestSuccessful;
        });
        builder.addCase(fetchGetPaginatedSales.rejected, (state) => {
            state.status = requestMissed;
        });

        builder.addCase(fetchRecurringSales.pending, (state) => {
            state.recurringSalesStatus = requestLoading;
        }).addCase(fetchRecurringSales.fulfilled, (state, action) => {
            state.recurringSalesStatus = requestSuccessful;
            state.salesRecurrences = [];
            state.allRecurrencyInTheSystem = [];
            state.changedRecurrences = [];
            action.payload.map((sale) => {
                state.salesRecurrences.push(sale.id);
                state.allRecurrencyInTheSystem.push(sale.id)
            })
        }).addCase(fetchRecurringSales.rejected, (state) => {
            state.recurringSalesStatus = requestMissed;
        });

    }
})

export const salesReducer = salesSlice.reducer;
export const {selectSearchFilter, changeElementRecurrency, setSystemRecurrences, setChangedRecurrences,selectedMonthFilter,cancelNewRecurrency,selectYearFilter, cleanFilter, setRowsPerPage, setPage} = salesSlice.actions;