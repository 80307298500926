import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {task} from "../../models/taskModel";
import {Tag} from "../../models/tagModel";
import {deleteTag, deleteTask, getTags, getTasks, postTag, postTask, putTag, putTask} from "../../http/httpClient";
import {requestLoading, requestMissed, requestPending, requestSuccessful} from "../../../constants/constants";

const namespace = "tasksTags"
export const fetchTasks = createAsyncThunk(
    `${namespace}/fetchTasks`,
    async () => await getTasks(false),
);
export const fetchTags = createAsyncThunk(
    `${namespace}/fetchTags`,
    async () => await getTags(false),
);

export const fetchDeleteTask = createAsyncThunk(
    `${namespace}/fetchDeleteTask`,
    async (id: number) => await deleteTask(id),
);

export const fetchDeleteTag = createAsyncThunk(
    `${namespace}/fetchDeleteTag`,
    async (id: number) => await deleteTag(id),
);

export const saveTask = createAsyncThunk(
    `${namespace}/fetchSaveTask`,
    async (name: string) => await postTask(name),
);

export const editTask = createAsyncThunk(
    `${namespace}/editTask`,
    async ({ id, name }: { id: number, name: string }) => await putTask(id, name)
);


export const editTag = createAsyncThunk(
    `${namespace}/editTag`,
    async ({ id, name }: { id: number, name: string }) => await putTag(id,name),

);

export const saveTag = createAsyncThunk(
    `${namespace}/fetchSaveTag`,
    async (name: string) => await postTag(name),
);

export const tasksTagsSlice = createSlice({
    name:`${namespace}`,
    initialState:{
        tasks: Array<task>(),
        tasksStatus: requestPending,
        tags: Array<Tag>(),
        tagsStatus: requestPending,
        selectedData:{
            taskName: "",
            tagName: ""
        }
    },
    reducers:{
        addTask:(state) =>{
            const newTask = new task();
            newTask.name = state.selectedData.taskName;
            state.tasks.push(newTask);
        },
        addTag:(state) =>{
            const newTag = new Tag();
            newTag.name = state.selectedData.tagName;
            state.tasks.push(newTag);
        },
        removeTask:(state, action) =>{
            state.tasks = state.tasks.filter(task => task.id !== action.payload.id);
        },
        removeTag:(state, action) =>{
            state.tags = state.tags.filter(tag => tag.id !== action.payload.id);
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchTasks.pending, (state) => {
            state.tasksStatus = requestLoading;
        }).addCase(fetchTasks.fulfilled, (state, action) => {
            state.tasks = action.payload;
            state.tasksStatus = requestSuccessful;
        }).addCase(fetchTasks.rejected, (state) => {
            state.tasksStatus = requestMissed;
        });
        builder.addCase(fetchTags.pending, (state) => {
            state.tagsStatus = requestLoading;
        }).addCase(fetchTags.fulfilled, (state, action) => {
            state.tags = action.payload;
            state.tagsStatus = requestSuccessful;
        }).addCase(fetchTags.rejected, (state) => {
            state.tagsStatus = requestMissed;
        });
    }
})

export const {removeTag, addTag, removeTask, addTask} = tasksTagsSlice.actions;

export const tasksTagsReducer =  tasksTagsSlice.reducer;