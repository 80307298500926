import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getMonthlyRecords} from "../../http/httpClient";
import { RootState } from "../store";
import { saturday, sunday, cardMustNotShowThisValue, monday, requestLoading, requestSuccessful, requestMissed,requestPending } from "../../../constants/constants";

const namespace = 'monthlyTracker';
let date = new Date();
const initialState  = {
    monthlyRecords: Array<Array<[string,number]>>(),
    weekDayOfFirstDayOfMonth: new Date(date.getFullYear(), date.getMonth(), 1).getDay(),
    monthlyRecordsStatus: requestPending,
}

export const fetchMonthlyRecords = createAsyncThunk(
    `${namespace}/fetchMonthlyRecords`,
    async (idResource:number,thunkAPI) => {
        const {date:{selectedDate}} = thunkAPI.getState() as RootState;
        const stringDate = `${selectedDate.getFullYear()}-${selectedDate.getMonth()+1}-${selectedDate.getDate()}`;
        return await getMonthlyRecords(idResource,stringDate);
    }
)

export const monthlyTrackerSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        setWeekDayOfFirstDayOfMonth: (state, action) => {
            state.weekDayOfFirstDayOfMonth = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchMonthlyRecords.pending, (state) => {
            state.monthlyRecordsStatus = requestLoading;
        }).addCase(fetchMonthlyRecords.fulfilled, (state, action) => {
            var firstDayOfMonth = state.weekDayOfFirstDayOfMonth;
            var dayOfWeek = firstDayOfMonth;
            var month = new Array<Array<[string,number]>>();
            var counter = 1;
            month.push([],[],[],[],[],[],[]);
            action.payload?.forEach( (record: string) => {
                month[dayOfWeek].push([record,counter]);
                if (dayOfWeek === saturday){
                    dayOfWeek = sunday;
                } else {
                    dayOfWeek++;
                }
                counter++
            });
            if (dayOfWeek === sunday) {
                month[0].push(["", cardMustNotShowThisValue]);
            } else if (dayOfWeek !== monday && (firstDayOfMonth !== 6 || action.payload.length !== 30) ) {
                for (let missingDateDays = dayOfWeek; missingDateDays < 7; missingDateDays++) {
                    month[missingDateDays].push(["", cardMustNotShowThisValue]);
                }
                month[0].push(["", cardMustNotShowThisValue]);
            }
            state.monthlyRecords = month;
            state.monthlyRecordsStatus = requestSuccessful;
        }).addCase(fetchMonthlyRecords.rejected, (state) => {
            state.monthlyRecordsStatus = requestMissed;
        });
    },
})

export const monthlyTrackerReducer = monthlyTrackerSlice.reducer;
export const { setWeekDayOfFirstDayOfMonth } = monthlyTrackerSlice.actions;
