import { useMsal } from '@azure/msal-react';
import { Logout } from '@mui/icons-material';
import { AppBar, Box, IconButton, ListItemIcon, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as MenuIcon } from "../../../assets/icons/menu.svg";
import { ReactComponent as InTrackerLogo } from "../../../assets/Logo_InTracker.svg";
import { blackColor, drawerWidth } from '../../constants/constants';
import { msalConfig } from '../authorization/authConfig';
import { useAppDispatch, useAppSelector } from '../state/hooks';
import { toggleSideBar } from '../state/sideBar/sideBarSlice';
import { fetchImage, fetchUser } from '../state/user/userSlice';
import defaultUserImage from './../../../assets/icons/default-profile-icon.png'

function AccountMenu() {
  const dispatch = useAppDispatch();
  const { instance } = useMsal();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const imageBytes = useAppSelector((state) => state.user.user.image);
  
  useEffect(() => {
    dispatch(fetchUser());
    dispatch(fetchImage());
  }, [dispatch]);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const signOutClickHandler = () => {
    const logoutRequest = {
        account: instance.getAllAccounts()[0],
        postLogoutRedirectUri: msalConfig.auth.redirectUri
    }
    instance.logoutRedirect(logoutRequest);
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <img className='w-35 border-r-50per' src={ imageBytes? `data:image/jpeg;base64,${imageBytes}` : defaultUserImage}
            alt=''></img>
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => signOutClickHandler()}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </Box>
  )
}


export default function NavBar() {
  const { instance } = useMsal();
  const dispatch = useAppDispatch();
  const username = instance.getAllAccounts()[0].name;
  
  return <AppBar position='sticky' sx ={{display:'flex', flexDirection: 'row', alignItems:'center',backgroundColor:blackColor,p:0}} >
  <Toolbar sx={{display:'flex',width:`calc(100% - ${drawerWidth}px) `,flex:1}}>
    <Box sx={{display:"flex",justifyItems:"center",width:`${drawerWidth}px`,position:"absolute",left:14}}> 
      <IconButton onClick={() => dispatch(toggleSideBar())}>
        <MenuIcon/>
      </IconButton>
    </Box>
    <Box sx={{display:'flex',flexDirection:'row',flex:1,pl:8}}>
      <Box display={{xs:"none",md:"flex"}}><Link to='/home'><InTrackerLogo/></Link> </Box>
      <Box display={"flex"} flex={1}/>
      <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
          <Typography variant='h5' component="div" mb={0.6}>{username}</Typography>
          <AccountMenu />
      </Box>
    </Box>
  </Toolbar>
</AppBar>
}
