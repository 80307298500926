import { LocalizationProvider } from '@mui/lab';
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    FormControl,
    FormLabel,
    IconButton,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@mui/material';
import './projects.css'
import {
    darkGreenColor,
    greenColor,
    lightGrayColor,
    whiteColor,
    darkGrayColor,
    redColor,
    StageHasHoursErrorFromResource, emptyString, invalidEndDateMessage, invalidStartDateMessage,
} from '../../constants/constants';
import {
    deleteAssignment,
    setWeekHours,
    selectResource,
    addAssignment,
    fetchResources,
    closeSaveProjectStageDialog,
    setStageName,
    setStageStartDate,
    setStageFinishDate,
    setStageNumber,
    addStage,
    updateStage, deleteStage
} from '../../core/state/dialogs/saveProjectDialogSlice';
import { useAppDispatch, useAppSelector } from '../../core/state/hooks';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DatePicker } from '@mui/lab';
import { Add, Close } from '@mui/icons-material';
import React, {useEffect, useState} from 'react';
import {getStageHasHoursFromResource} from "../../core/http/httpClient";
import {toast} from "react-toastify";
export default function SaveProjectDialog() {
    const dispatch = useAppDispatch();
    const { stageDialogisOpen, selectedData, stageDialogisCreating, selectedStageData, resources } = useAppSelector((state) => state.saveProjectDialog);
    const [errors, setErrors] = useState({stageName: false, stageNumber: false, stageText: false, stageResources: false});
    const styles ={
        greyLine: {border:"1.5px solid #F1F0F0", backgroundColor:"#F1F0F0"},
        secondGreyLine :{border:"1.5px solid #F1F0F0", backgroundColor:"#F1F0F0", mt:2, position:"relative"}
    }
    const successField = {backgroundColor:lightGrayColor,borderRadius:4,border:2,borderColor:lightGrayColor};
    const errorField = {backgroundColor:lightGrayColor,borderRadius:4,border:2,borderColor:redColor};

    const isNumberDisabled = (number: number) => {
        return selectedData.Stages.some((stage) => stage.number === number);
    };

    const includeValidResources = () => {
        let validResource = true;
        selectedStageData.stage.stageResources?.forEach((resource: { resourceId: number | null; }) => {
            if(resource.resourceId === 0 || resource.resourceId == null){
                validResource = false;
            }
        });
        return validResource;
    }

    const removeResourceFromStage = async (resourceIndex: number) => {
        const stageHasHoursFromResource = await getStageHasHoursFromResource(selectedData.Stages[selectedStageData.index].id, selectedStageData.stage.stageResources[resourceIndex].resourceId);
        if (stageHasHoursFromResource) {
            toast.error(StageHasHoursErrorFromResource);
        }else{
            dispatch(deleteAssignment(resourceIndex))
        }
    }

    const onClose = () => {
        dispatch(closeSaveProjectStageDialog());
        setStyleSuccess();
    }

    const setStyleSuccess = () => {
        setErrors({stageName: false, stageNumber: false, stageText: false, stageResources: false});
    }

    const validateProjectStage = () => {

        let newErrors = {...errors};
        let isValid = true;

        const validateField = (condition: boolean, fieldName: keyof typeof newErrors) => {
            if (condition) {
                newErrors[fieldName] = true;
                isValid = false;
            } else {
                newErrors[fieldName] = false;
            }
        };
        const stageNumber = selectedStageData.stage.number;
        const stageName = selectedStageData.stage.name;
        const resources = selectedStageData.stage.stageResources;

        validateField(stageName === '', 'stageName');
        validateField(stageNumber === 0, 'stageNumber');
        validateField(!includeValidResources(), 'stageResources');
        validateField(resources?.length === 0, 'stageText');

        setErrors(newErrors);

        return isValid;
    }

    const disablePastStartDate = (date: any) => {
        return date.getTime() < new Date(selectedStageData.stage.startDate).getTime();
    };

    const saveNewProjectStage = () => {
        const isValid = validateProjectStage();
        if(isValid){
            if(stageDialogisCreating){
                dispatch(addStage())
                dispatch(closeSaveProjectStageDialog());
            }else{
                dispatch(updateStage())
                dispatch(closeSaveProjectStageDialog());
            }
        }
    }

    useEffect(() => {
        dispatch(fetchResources());
    }, [dispatch]);

    useEffect(() => {
        const handleWheel = (event: WheelEvent) => {
            if ((document.activeElement as HTMLInputElement)?.type === "number") {
                (document.activeElement as HTMLInputElement)?.blur();
            }
        };
        document.addEventListener("wheel", handleWheel);
    }, []);

    return (
        <Dialog open={stageDialogisOpen} fullWidth maxWidth="xs">
            <DialogContent sx={{display:"flex",flexDirection:"column"}}>
                <DialogContent sx={{display:"flex",flexDirection:"row", pb:'0px !important', position:'relative', overflowY: 'visible'}}>
                    <Typography variant='h5' sx={{ borderBottom: 2, borderBottomColor: 'rgba(35, 31, 32, 0.1)', pb: 1, flex:1 }}>{stageDialogisCreating? 'Crear' : 'Editar'} Etapa</Typography>
                </DialogContent>

                <DialogContent sx={{display:"flex", justifyContent:"space-between", overflowY:'initial', pb: '2px !important'}}>
                    <FormControl sx={{ flex: 1 }}>
                        <FormLabel sx={{ ml: 0.5, mb: 0.5 }}>Número etapa (*)</FormLabel>
                        <Select
                            sx={errors.stageNumber? errorField : successField}
                            id="stage"
                            value={selectedStageData.stage.number}
                            onChange={(event) => {
                                dispatch(setStageNumber(event.target.value));
                            }}
                        >
                            {[...Array(20)].map((_, index) => (
                                <MenuItem
                                    key={index + 1}
                                    value={index + 1}
                                    disabled={isNumberDisabled(index + 1)} // Check if the current number is in the stages list
                                >
                                    {index + 1}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>

                <DialogContent sx={{display:"flex", overflowY:'initial', pb: '2px !important'}}>

                    <FormControl sx={{flex:1}}>
                        <FormLabel sx={{ml:0.5, mb:0.5}}>Etapa (*)</FormLabel>
                        <TextField sx={errors.stageName? errorField : successField} id="stage"
                                   value={selectedStageData.stage.name}
                                   onChange={(event) => { dispatch(setStageName(event.target.value)); }} />
                    </FormControl>
                </DialogContent>

                <DialogContent sx={{display:"flex", justifyContent:"space-between", overflowY:'initial', pb: '2px !important', mb:3}}>
                    <FormControl sx={{display:"flex",flexDirection:"row",flex:1, alignItems:"self-end"}}>
                        <FormControl sx={{flex:1, mr:1.5}}>
                            <FormLabel sx={{ml:1, mb:0.5}}>Fecha</FormLabel>
                            <LocalizationProvider dateAdapter={AdapterDateFns} >
                                <Box sx={{backgroundColor:lightGrayColor,borderRadius:9}}>
                                    <DatePicker
                                        inputFormat="dd/MM/yyyy"
                                        value={selectedStageData.stage.startDate}
                                        onChange={(selectedDate) => { dispatch(setStageStartDate(selectedDate)) }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </Box>
                            </LocalizationProvider>
                        </FormControl>
                        <FormControl sx={{flex:1, ml:1.5}}>
                            <FormControl>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Box sx={{backgroundColor:lightGrayColor,borderRadius:9}}>
                                        <DatePicker
                                            inputFormat="dd/MM/yyyy"
                                            value={selectedStageData.stage.endDate}
                                            onChange={(selectedDate) => { dispatch(setStageFinishDate(selectedDate)) }}
                                            shouldDisableDate={disablePastStartDate}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </Box>
                                </LocalizationProvider>
                            </FormControl>
                        </FormControl>

                    </FormControl>
                </DialogContent>

                <DialogContent sx={{display:"flex",flexDirection:"row", pb:'0px !important', position:'relative', overflowY: 'visible'}}>
                    <Typography variant='h6' sx={{ml:0.5, borderBottom: 2, borderBottomColor: 'rgba(35, 31, 32, 0.1)', pb: 1, flex:1, fontSize:'16px !important'}}>Recursos (**)</Typography>
                </DialogContent>
                <DialogContent sx={{display:"flex", flexDirection:"column", flex:"1 50 auto !important",zIndex:10, minHeight:'200px'}}>
                    {
                        selectedStageData.stage.stageResources?.length > 0? selectedStageData.stage.stageResources.map((assignment: { resourceId: any; weeklyHours: unknown; }, _index: string | number | null | undefined) => {
                            return (
                                <Box key={_index} sx={{display:"flex",flexDirection:"row", justifyContent:"space-between", mb:2}}>
                                    <Box display={"flex"} flexDirection={"column"} justifyContent={"end"}>
                                        <Button sx={{color:darkGrayColor,minWidth:10,alignSelf:"end", p:0.2 }}
                                                onClick={() => {removeResourceFromStage(Number(_index))}}
                                        >
                                            <Close sx={{fontSize:"1rem"}}/>
                                        </Button>
                                        <Box flex={0.15}></Box>
                                    </Box>
                                    <FormControl sx={{flex:0.45}} fullWidth>
                                        <FormLabel sx={{ml:0.5, mb:0.5}}>Recurso (*)</FormLabel>
                                        <Select
                                            id="resource-select"
                                            label="Resource"
                                            value={assignment.resourceId}
                                            onChange={(event) => { dispatch(selectResource({data:event.target.value, assignmentIndex:_index}))}}
                                            sx={errors.stageResources? errorField : successField}
                                        >
                                            {
                                                resources && resources.map((resource) => {
                                                    const isResourceAlreadySelected = selectedStageData.stage.stageResources.some((assignment: { resourceId: number; }, index: string | number | null | undefined) =>
                                                        assignment.resourceId === resource.id && index !== _index
                                                    );

                                                    return (
                                                        <MenuItem
                                                            value={resource.id}
                                                            key={resource.id}
                                                            disabled={isResourceAlreadySelected}
                                                        >
                                                            {resource.name + " " + resource.lastName}
                                                        </MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                    <FormControl sx={{flex:0.45}}>
                                        <FormLabel sx={{ml:0.5, mb:0.5}}>Horas semanales</FormLabel>
                                        <TextField sx={{backgroundColor:lightGrayColor,borderRadius:9}} id="weekHours"
                                            type='number'
                                            value={assignment.weeklyHours}
                                            onChange={event => {
                                                const newValue = event.target.value !== '' ? Number(event.target.value) : 0;
                                                if (!isNaN(newValue)) {
                                                    dispatch(setWeekHours({data:newValue, assignmentIndex:_index}));
                                                }
                                            }}
                                        />
                                    </FormControl>

                                </Box>)
                        }) : <Box my={3}></Box>
                    }
                </DialogContent>

                <Box sx={styles.secondGreyLine}>
                    <IconButton children={<Add />} onClick={() => {dispatch(addAssignment())}}
                                sx={{zIndex:10,position:"absolute", right:"-5px",top:"-20px",backgroundColor:greenColor,color:whiteColor,"&:hover":{backgroundColor:darkGreenColor}}}/>
                </Box>

                <Box display={'flex'} sx={{mt:5,ml:2, color: errors.stageText? redColor : "None"}}>
                    (*) Campos obligatorios <br/>
                    (**) El proyecto debe tener al menos un recurso asignado
                </Box>

                <Box display={'flex'} sx={{justifyContent:'space-between'}}>
                    <Box display={'flex'}>
                        <Button sx={{backgroundColor:redColor,color:whiteColor,width:120,alignSelf:"end",mr:2, mt:5,'&:hover': {backgroundColor: darkGreenColor,}}}
                                onClick={onClose}>Cancelar</Button>
                    </Box>
                    <Box display={'flex'}>
                        <Button sx={{backgroundColor:greenColor,color:whiteColor,width:120,alignSelf:"end",mr:2, mt:5,'&:hover': {backgroundColor: darkGreenColor,}}}
                                onClick={() => {saveNewProjectStage()}}>Guardar</Button>
                    </Box>
                </Box>
            </DialogContent>

        </Dialog>
    )
};