
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { emptyString, recurringN, requestLoading, requestMissed, requestPending, requestSuccessful, purchasesCreateError, purchasesCreateFailAll, purchasesCreateSuccess, TypeOfMoneys } from "../../../constants/constants";
import {
    postPurchase,
    getSectores,
    getSubSectores,
    getProjects,
    getRecurringPurchases, getProjectServices
} from "../../http/httpClient";
import { Project } from "../../models/projectModel";
import { Purchase } from "../../models/purchaseModel";
import { Sector } from "../../models/sectorModel";
import { SubSector } from "../../models/subSectorModel";
import { fetchGetPurchases } from "../purchases/purchaseSlice";
import { RootState } from "../store";
import { PurchaseDTO} from "../../models/purchaseModelDTO";
import {projectService} from "../../models/projectServiceModel";

const namespace = 'savePurchaseDialog';

export const fetchSector = createAsyncThunk(
    `${namespace}/fetchSector`,
    async () =>  await getSectores(),
);

export const fetchSubSector = createAsyncThunk(
    `${namespace}/fetchSubSector`,
    async () => await getSubSectores(),
);

export const fetchRecurringPurchases = createAsyncThunk(
    `${namespace}/fetchRecurringPurchases`,
    async () => await getRecurringPurchases(),
);
export const fetchProjectServices = createAsyncThunk(
    `${namespace}/fetchProjectServices`,
    async () => await getProjectServices(false)
);

export const savesPurchases = createAsyncThunk(
    `${namespace}/savesPurchases`,
    async (_,thunkAPI) => {

        const { savePurchaseDialog : state, purchases: purchaseState } = thunkAPI.getState() as RootState;
        const { rowsPerPage, page, selectedMonth, yearFilter, searchFilter, selectedSector, selectedSubSector} = purchaseState

        let newPurchase = new Array<Purchase>();
        state.futureSaveList.forEach((p : Purchase) => {
            newPurchase.push(p)
        })
        state.recurringNewPurchases.forEach((p : Purchase) => {
            newPurchase.push({...p,id:0})
        })
        const isPosted = await postPurchase(newPurchase);

        if(isPosted === 0){
            toast.success(purchasesCreateSuccess);
        }else if(isPosted === 2){
            toast.info(purchasesCreateFailAll)
        }else{
            toast.error(purchasesCreateError);
        }

        thunkAPI.dispatch(fetchGetPurchases({offset: page*rowsPerPage, limit: rowsPerPage, month: selectedMonth, year: yearFilter.toString(), searchTerm: searchFilter, sector: selectedSector, subSector: selectedSubSector}))
    },
);

export const fetchGetProjects = createAsyncThunk(
    `${namespace}/fetchGetProjects`,
    async () => await getProjects(),
)

const initialState = {
    purchaseList : new Array<{id:number,key:number}>(),
    futureSaveList : new Array<Purchase>(),
    recurringNewPurchases: new Array<Purchase>(),
    recurringPurchases : new Array<PurchaseDTO>(),
    recurringPurchasesStatus : requestPending,
    projectsList:new Array<Project>(),
    projectsListStatus : requestPending,
    projectServices : new Array<projectService>(),
    projectServicesStatus: requestPending,
    sectorList:new Array<Sector>(),
    sectorListStatus : requestPending,
    subSectorList:new Array<SubSector>(),
    subSectorListStatus : requestPending,
    isOpen:false,
    btnNew:false,
    uniqueId:0,
}

const emptyPurchase = {
    id: 0,
    date: new Date(),
    document: emptyString,
    supplier: emptyString,
    rut : emptyString,
    subSectorId :  0,
    subSector: new SubSector(),
    concept : emptyString,
    serviceId:0,
    currency:TypeOfMoneys[1],
    projectId : 0,
    project : undefined,
    netOrigin:0,
    tax: 0,
    total: 0,
    recurring :recurringN,
}

export const savePurchaseDialog = createSlice({
    name:namespace,
    initialState: initialState,
    reducers: {
        openSavePurchaseDialog:(state, action)=> {
            state.isOpen = true;
        },closeSavePurchaseDialog:(state) => {
            state.isOpen=false;
        },addAssignment: (state) => {
            state.futureSaveList.push(emptyPurchase);
            state.purchaseList.push({id:state.purchaseList.length+1,key:state.uniqueId});
            state.btnNew = true;
            state.uniqueId = state.uniqueId + 1;
        },deleteAssignment:(state,action)=>{
            state.btnNew = false;
            state.purchaseList.splice(action.payload,1);
            state.futureSaveList.splice(action.payload,1);
        },updateAssignment:(state,action) => {
            state.futureSaveList.splice(action.payload.index,1,action.payload.purchase)
        },updatePurchaseRecurrences:(state,action) => {
            state.recurringNewPurchases.splice(action.payload.index,1,action.payload.sale);
        },addPurchaseRecurrences:(state,action) => {
            state.recurringNewPurchases.push(action.payload);
        },deletePurchaseRecurrences:(state,action) => {
            const i = state.recurringNewPurchases.findIndex( (p : Purchase) => (p.id === action.payload))
            state.recurringNewPurchases.splice(i,1);
        },availableBtnNew:(state,action)=>{
            state.btnNew = action.payload;
        },cleanList:(state) => {
            state.purchaseList = [];
            state.futureSaveList = [];
            state.recurringNewPurchases = [];
            state.btnNew = false;
        }

    },
    extraReducers:(builder) => {
        builder.addCase(fetchGetProjects.pending, (state) => {
            state.projectsListStatus = requestLoading;
        }).addCase(fetchGetProjects.fulfilled, (state, action) => {
            state.projectsList = action.payload;
            state.projectsListStatus = requestSuccessful;
        }).addCase(fetchGetProjects.rejected, (state) => {
            state.projectsListStatus = requestMissed;
        });
        builder.addCase(fetchProjectServices.pending, (state) => {
            state.projectServicesStatus = requestLoading;
        }).addCase(fetchProjectServices.fulfilled, (state, action) => {
            state.projectServices = action.payload;
            state.projectServicesStatus = requestSuccessful;
        }).addCase(fetchProjectServices.rejected, (state) => {
            state.projectServicesStatus = requestMissed;
        });
        builder.addCase(fetchSector.pending, (state) => {
            state.sectorListStatus = requestLoading;
        }).addCase(fetchSector.fulfilled, (state, action) => {
            state.sectorList = action.payload;
            state.sectorListStatus = requestSuccessful;
        }).addCase(fetchSector.rejected, (state) => {
            state.sectorListStatus = requestMissed;
        });

        builder.addCase(fetchRecurringPurchases.pending, (state) => {
            state.recurringPurchasesStatus = requestLoading;
        }).addCase(fetchRecurringPurchases.fulfilled, (state, action) => {
            state.recurringPurchases = action.payload;
            state.recurringPurchasesStatus = requestSuccessful;
        }).addCase(fetchRecurringPurchases.rejected, (state) => {
            state.recurringPurchasesStatus = requestMissed;
        });

        builder.addCase(fetchSubSector.pending, (state) => {
            state.subSectorListStatus = requestLoading;
        }).addCase(fetchSubSector.fulfilled, (state, action) => {
            state.subSectorList = action.payload;
            state.subSectorListStatus = requestSuccessful;
        }).addCase(fetchSubSector.rejected, (state) => {
            state.subSectorListStatus = requestMissed;
        });

    }
});


export const savePurchaseDialogReducer = savePurchaseDialog.reducer;
export const { addAssignment,closeSavePurchaseDialog,openSavePurchaseDialog, deleteAssignment, updateAssignment,deletePurchaseRecurrences,
    updatePurchaseRecurrences,addPurchaseRecurrences,availableBtnNew,cleanList } = savePurchaseDialog.actions;



