import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { areaList } from "../../../constants/constants";
import { getPaginatedResources } from "../../http/httpClient";
import { requestLoading, requestSuccessful, requestMissed,requestPending } from "../../../constants/constants";
import { ResourceDTO } from "../../models/resourceModelDTO";

const namespace = "resources";

interface FetchPageDataParams {
    offset: number;
    limit: number;
    area: string;
    searchTerm?: string
}

export const fetchPaginatedResources = createAsyncThunk(
    `${namespace}/fetchAllResources`,
    async ({ offset, limit, area, searchTerm}: FetchPageDataParams) => {
        return (await getPaginatedResources(offset,limit,area, searchTerm));
});


export const resourceSlice = createSlice({  
    name:`${namespace}`,
    initialState:{
        resources: new Array<ResourceDTO>(),
        resourcesCount: 0,
        page: 0,
        rowsPerPage: 6,
        status: requestPending,
        selectedArea : areaList[0],
        searchFilter: ""

    },
    reducers:{
        selectAreaFilter: (state,action) => {
            state.selectedArea = action.payload;
        },selectSearchFilter: (state,action) => {
        state.searchFilter = action.payload;
        },setRowsPerPage: (state,action) => {
            state.rowsPerPage = action.payload;
        },setPage: (state,action) => {
            state.page = action.payload;
        }

    },
    extraReducers:(builder) =>{
        builder.addCase(fetchPaginatedResources.pending, (state) =>{
            state.status = requestLoading;
        });
        builder.addCase(fetchPaginatedResources.fulfilled, (state,action) =>{
            state.resources = action.payload.pageItems;
            state.resourcesCount = action.payload.itemsCount;
            state.status = requestSuccessful;
        });
        builder.addCase(fetchPaginatedResources.rejected, (state) => {
            state.status = requestMissed;
        });
    }
});

export const { selectAreaFilter,selectSearchFilter, setRowsPerPage, setPage} = resourceSlice.actions;
export const resourcesReducer =  resourceSlice.reducer;