import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CheckIfNewRessource, getProfilePicture, getResourceById, getResourceProjects, saveImage } from "../../http/httpClient";
import { Client } from "../../models/clientModel";
import { User } from "../../models/userModel";
import { requestLoading, requestSuccessful, requestMissed,requestPending } from "../../../constants/constants";
const namespace = "user"

export const fetchUser = createAsyncThunk(
    `${namespace}/fetchUser`,
    async () => {
        const user = await CheckIfNewRessource();
        return await getResourceById(user.id);
    },
);

export const fetchImage = createAsyncThunk(
    `${namespace}/fetchImage`,
    async () => await (getProfilePicture()),
);

export const fetchUserProjects = createAsyncThunk(
    `${namespace}/fecthProjects`,
    async (idUser: number) => await getResourceProjects(idUser,false),
);

export const userSlice = createSlice({
    name: `${namespace}`,
    initialState: {
        user: new User(),
        projectsByClient: new Array<Client>(),
        projectStatus: requestPending,
        status: requestPending,
        imageState : requestPending
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(fetchUser.pending, (state) => {
            state.status = requestLoading;
        });
        builder.addCase(fetchUser.fulfilled, (state, action) => {
            state.user.appRoleId = action.payload.appRoleId;
            state.user.email = action.payload.email;
            state.user.id = action.payload.id;
            state.user.lastName = action.payload.lastName;
            state.user.name = action.payload.name;
            state.user.image = action.payload.image;
            state.status = requestSuccessful;
        });
        builder.addCase(fetchUser.rejected, (state) => {
            state.status = requestMissed;
        });

        builder.addCase(fetchImage.pending, (state) => {
            state.imageState = requestLoading;
        });
        builder.addCase(fetchImage.fulfilled, (state, action) => {
            state.user.image = action.payload as string;
            saveImage({image: action.payload})
            state.imageState = requestSuccessful;
        });
        builder.addCase(fetchImage.rejected, (state) => {
            state.imageState = requestMissed;
        });

        builder.addCase(fetchUserProjects.pending, (state) => {
            state.projectStatus = requestLoading;
        }).addCase(fetchUserProjects.fulfilled, (state, action) => {
            state.projectsByClient = action.payload;
            state.projectStatus = requestSuccessful;
        }).addCase(fetchUserProjects.rejected, (state) => {
            state.projectStatus = requestMissed;
        });
    },
})
export const userReducer = userSlice.reducer;


