import { SubArea } from "./subAreaModel";
import { ResourceIncome } from "./resourceIncomeModel";
import { Bonus } from "./bonosModels";
import { HistoricalResource } from "./historicalResourceModel";
import { record } from "./recordModel";
import { CredentialField } from "./credentialFieldModel";
import { CompanyRole } from "./companyRoleModel";

export class Resource{
    id: number = 0;
    userId: string = "";
    appRoleId: number = 0;
    name: string = "";
    lastName: string = "";
    email: string = "";
    image: string = "";
    companyRoleId : number = 0;
    companyRole!: CompanyRole;
    subAreaId : number = 0;
    subArea! : SubArea;
    resourceIncomes : Array<ResourceIncome> = [];
    records : Array<record> = [];
    historicalResources : Array<HistoricalResource> = [];
    credentialFields : Array<CredentialField> = []
    bonuses : Array<Bonus> = [];
}