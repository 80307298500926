import { DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {
    Autocomplete,
  Box, Button, Dialog, DialogContent, FormControl, FormLabel, MenuItem,
  Select, TextField, Typography
} from "@mui/material";
import React, {useState} from "react";
import { countryList, darkGreenColor, greenColor,lightGrayColor, redColor, whiteColor } from "../../constants/constants";
import {
    closeSaveClientDialog,
    fetchPartners,
    fetchIndustryVerticals,
    saveClient,
    setContactMail,
    setContactPerson,
    setContactPhone,
    setContactRole,
    setCountry,
    setDate,
    setName,
    setPartner,
    setVertical
} from "../../core/state/dialogs/saveClientDialogSlice";
import { useAppDispatch, useAppSelector } from "../../core/state/hooks";

export default function SaveClientDialog( props : any ) {
  const dispatch = useAppDispatch();
  
  const successField = {backgroundColor:lightGrayColor,borderRadius:5,border:2,borderColor:lightGrayColor}
  const errorField = {backgroundColor:lightGrayColor,borderRadius:5,border:2,borderColor:redColor};
  const [errors, setErrors] = useState({client: false, vertical: false, partner: false, country: false, contact: false, mail: false});
  const { isOpen: dialogIsOpen, partner: partners, selectedData, industryVerticals} = useAppSelector((state) => state.saveClientDialog);
  const { user } = useAppSelector((state) => state.user);

    const validateClient = () => {

        const { name, country,partnerId,contactMail,contactPerson, industryVerticalId} = selectedData;

        let newErrors = {...errors};
        let isValid = true;

        const validateField = (condition: boolean, fieldName: keyof typeof newErrors) => {
            if (condition) {
                newErrors[fieldName] = true;
                isValid = false;
            } else {
                newErrors[fieldName] = false;
            }
        };

        validateField(name === '', 'client');
        validateField(industryVerticalId === null || industryVerticalId === 0, 'vertical');
        validateField(partnerId === 0, 'partner');
        validateField(country === '', 'country');
        validateField(contactPerson === '', 'contact');
        validateField(contactMail === '', 'mail');

        setErrors(newErrors);

        return isValid;
    }
  const saveClientData = () => {

      if(validateClient()){
      dispatch(saveClient(user.id));
      dispatch(closeSaveClientDialog());
    }
  };

  const init = () => {
    dispatch(fetchPartners());
    dispatch(fetchIndustryVerticals());
  };

    const setStyleSuccess = () => {
        setErrors({...errors, client: false, mail: false, contact: false, country: false, partner: false, vertical: false})
    }

    return (
    <Dialog
      onAnimationStart={init}
      open={dialogIsOpen}
      fullWidth  
    >
      <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
        <DialogContent sx={{display:"flex",flexDirection:"row"}}>
            <Typography variant='h5' sx={{ borderBottom: 2, borderBottomColor: 'rgba(35, 31, 32, 0.1)', pb: 1, flex:1 }}>
                {props.isNew ? "Crear Cliente" : "Editar Cliente"}
            </Typography>
          </DialogContent>
        <DialogContent sx={{ borderBottom: 1, borderColor:"rgba(0, 0, 0, 0.1)"}}>
          <FormControl sx={{ flexDirection: { xs: "column", sm: "row" , width: '100%'} }}>
              <FormControl sx={{width: {xs: '100%', sm:'65%'}, mb:1, pr:1 }}>
                <FormLabel sx={{mb:0.5, ml:0.5}}>Cliente (*)</FormLabel>
                <TextField
                  sx={errors.client? errorField : successField}
                  id="clientName"
                  value={selectedData.name}
                  required={true}
                  onChange={(event) => {
                    if (event.target.value === "") {
                      dispatch(setName(''));
                    } else {
                      dispatch(setName(event.target.value));
                    }
                  }}
                />
              </FormControl>

                        <FormControl sx={{width: {xs: '100%', sm:'35%'}, mb:1, pr:1}}>
                            <FormLabel sx={{mb:0.5, ml:0.5}}>País (*)</FormLabel>
                            <Select
                                id="country-select"
                                label="country"
                                value={selectedData.country}
                                onChange={(event) => {
                                    if (event.target.value === "Pais" || event.target.value === '') {
                                        dispatch(setCountry(''))
                                    } else {
                                        dispatch(setCountry(event.target.value))
                                    }
                                }}
                                sx={errors.country? errorField : successField}
                            >
                                {countryList.map((val) =>
                                    <MenuItem value={val} key={val}>{val}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </FormControl>

              <FormControl sx={{ flexDirection: { xs: "column", sm: "row" , width: '100%'} }}>

                  <FormControl sx={{width: {xs: '100%', sm:'65%'}, mb:1, pr:1 }}>
                      <FormLabel sx={{mb:0.5, ml:0.5}}>Vertical Industrial (*)</FormLabel>
                      <Autocomplete
                          id="vertical-autocomplete"
                          options={
                              industryVerticals
                                  ? [...industryVerticals].sort((a, b) => (a?.name || '').localeCompare(b?.name || ''))
                                  : []
                          }
                          getOptionLabel={(option) => option.name || ''}
                          value={
                              industryVerticals.find(
                                  (vertical) => vertical.id === selectedData.industryVerticalId
                              ) || null
                          }
                          onChange={(event, newValue) => {
                              if (newValue) {
                                  dispatch(setVertical(newValue.id));
                              }
                          }}
                          onInputChange={(event, value, reason) => {
                              if (reason === 'clear') {
                                  dispatch(setVertical(null));
                              }
                          }}
                          renderInput={(params) => (
                              <TextField
                                  {...params}
                                  InputProps={{
                                      ...params.InputProps,
                                      disableUnderline: true,
                                      style: { paddingLeft: 12 },
                                  }}
                                  variant="standard"
                              />
                          )}
                          sx={errors.vertical? errorField : successField}
                      />
                  </FormControl>
                  <FormControl sx={{width: {xs: '100%', sm:'35%'}, mb:1, pr:1}}>
                      <FormLabel sx={{ml:0.5}}>Fecha de Alta (*)</FormLabel>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Box
                                    sx={successField}
                                >
                                    <DatePicker
                                        value={selectedData.registrationDate}
                                        inputFormat="dd/MM/yyyy"
                                        onChange={(selectedDate) => {
                                            dispatch(setDate(selectedDate));
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </Box>
                            </LocalizationProvider>
                        </FormControl>
                    </FormControl>

            <FormControl sx={{ flexDirection: { xs: "column", sm: "row" , width: '100%'} }}>
            <FormControl sx={{width: {xs: '100%', sm:'65%'}, mb:1, pr:1 }}>
                <FormLabel sx={{mb:0.5, ml:0.5}}>Partner (*)</FormLabel>
                <Select
                    id="partner-select"
                    label="Partner"
                    sx={errors.partner? errorField : successField}
                    value={selectedData.partnerId}
                    onChange={(event) => { 
                      if (event.target.value === 0) {
                        dispatch(setPartner(0))
                      } else {
                        dispatch(setPartner(event.target.value)) 
                      }
                    }}
                    >
                    {partners && partners.map((partner) =>
                        <MenuItem value={partner.id} key={partner.id}>{partner.name}</MenuItem>)}
                </Select>
              </FormControl>
            </FormControl>

          <FormControl sx={{ flexDirection: { xs: "column", sm: "row" , width: '100%'} }}>
            <Box sx={{ borderBottom: 2, borderBottomColor: 'rgba(35, 31, 32, 0.1)', mb: 2, pt: 2, flex:1 }}>
                  </Box>
              </FormControl>

          <FormControl sx={{ flexDirection: { xs: "column", sm: "row" , width: '100%'} }}>

            <FormControl sx={{width: {xs: '100%', sm:'65%'}, mb:1, pr:1 }}>
              <FormLabel sx={{mb:0.5, ml:0.5}} >Persona de Contacto (*)</FormLabel>
              <TextField
                sx={errors.contact? errorField : successField}
                id="contact" 
                value={selectedData.contactPerson} 
                onChange={(event) => {
                  if (event.target.value === "") {
                    dispatch(setContactPerson(''));
                  } else {
                    dispatch(setContactPerson(event.target.value));
                  }
                }}
              />
            </FormControl>
          
            <FormControl sx={{width: {xs: '100%', sm:'35%'}, mb:1, pr:1}}>
              <FormLabel sx={{mb:0.5, ml:0.5}}>Teléfono</FormLabel>
              <TextField
                sx={successField}
                id="contactPhone"
                value={selectedData.contactPhone}
                onChange={(event) => {
                  dispatch(setContactPhone(event.target.value));
                }}
              />
            </FormControl>
            
          </FormControl>

          <Box sx={{ width: '100%' }}>
            <FormControl sx={{ width: '100%', mb:1, pr:1 }}>
              <FormLabel sx={{mb:0.5, ml:0.5}}>Rol</FormLabel>
              <TextField
                sx={successField}
                id="contactRole"
                value={selectedData.contactRole}
                onChange={(event) => {
                  dispatch(setContactRole(event.target.value));
                }}
              />
            </FormControl>              
          </Box>
            
          <Box sx={{ width: '100%' }}>
            <FormControl sx={{ width: '100%', pr:1, mb:1 }}>
              <FormLabel sx={{mb:0.5, ml:0.5}}>Mail (*)</FormLabel>
              <TextField
                  sx={errors.mail? errorField : successField}
                id="contactMail"
                value={selectedData.contactMail}
                onChange={(event) => {
                    if (event.target.value === "") {
                      dispatch(setContactMail(''));
                    } else {
                      dispatch(setContactMail(event.target.value));
                    }
                  }}
              />
            </FormControl>      
          </Box>
        </DialogContent>
      
        <Box display={'flex'} sx={{justifyContent:'space-between'}}>
          <Box display={'flex'} sx={{mt:2,ml:2}}>(*) Campos obligatorios</Box>
            <Box display={'flex'}>
                <Button sx={{
                    backgroundColor:redColor,
                    color:whiteColor,
                    width:120,
                    mr:2,
                    mt:2,
                    '&:hover': {backgroundColor: darkGreenColor,}
                }}
                    onClick={() => {
                        dispatch(closeSaveClientDialog());
                        setStyleSuccess();
                    }}>Cancelar</Button>
            </Box>
          <Box display={'flex'}>
            <Button
              sx={{
                backgroundColor: greenColor,
                color: whiteColor,
                width: 120,
                mr: 2,
                mt:2,
                "&:hover": { backgroundColor: darkGreenColor },
              }}
              onClick={saveClientData}
              >Guardar</Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
