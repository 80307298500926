import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {getIndustryVerticals, getPartners, postClient, updateClient} from "../../http/httpClient";
import { Client } from "../../models/clientModel";
import { Partner } from "../../models/partnerModel";
import { IndustryVertical } from "../../models/IndustryVerticalModel";
import { Project } from "../../models/projectModel";
import { fetchClients } from "../client/clientSlice";
import { RootState } from "../store";
import { requestLoading, requestSuccessful, requestMissed,requestPending } from "../../../constants/constants";

const namespace = 'saveClientDialog';
const initialState = {
    isOpen: false,
    partner: new Array<Partner>(),
    industryVerticals: new Array<IndustryVertical>(),
    industryVerticalsStatus: requestPending,
    partnerStatus: requestPending,
    selectedData:{
        id: 0,
        name: '',
        registrationDate: new Date(),
        country:'',
        partnerId: 0,
        industryVerticalId: 0,
        contactPerson: '',
        contactRole: '',
        contactMail: '',
        contactPhone: 0,
        projects : new Array<Project>(),    
    },
    status: requestPending,
};

export const fetchPartners = createAsyncThunk(
    `${namespace}/fetchPartners`,
    async () => await getPartners(false),
);
export const fetchIndustryVerticals = createAsyncThunk(
    `${namespace}/fetchIndustryVerticals`,
    async () => await getIndustryVerticals(false),
);
export const saveClient = createAsyncThunk(
    `${namespace}/Client`,
    async (ClientId:number,thunkAPI) => {
        const {saveClientDialog:state} = thunkAPI.getState() as RootState;
        const clientData = {
            ...state.selectedData,
        }
        clientData.id === 0 ? await postClient(clientData) : await updateClient(clientData);
        thunkAPI.dispatch(fetchClients());
    }, 
);


export const saveClientDialog = createSlice({
    name: namespace,
    initialState: initialState,
    reducers: {
        openSaveClientDialog: (state,action) => {
            state.selectedData = {...initialState.selectedData}
            state.isOpen = true;
            if(action.payload.client){
                const client = action.payload.client as Client;
                state.selectedData.id = client.id;
                state.selectedData.industryVerticalId = client.industryVerticalId;
                state.selectedData.name = client.name;
                state.selectedData.registrationDate = client.registrationDate;
                state.selectedData.country = client.country;
                state.selectedData.partnerId = client.partnerId;
                state.selectedData.contactPerson = client.contactPerson;
                state.selectedData.contactMail = client.contactMail;
                if (client.contactPhone){
                    state.selectedData.contactPhone = client.contactPhone;
                }
                if (client.contactRole){
                    state.selectedData.contactRole = client.contactRole;
                }
                state.selectedData.projects = client.projects;
            }
        },
        closeSaveClientDialog: (state)=> {
            state.isOpen = false;
        },
        setCountry: (state, action) => {
            state.selectedData.country = action.payload;
        },
        
        setDate: (state,action) =>{
            state.selectedData.registrationDate = action.payload;
        },

        setName: (state, action) => {
                state.selectedData.name = action.payload;            
        },
        setPartner: (state, action) => {
            state.selectedData.partnerId = action.payload;            
        },
        setVertical: (state, action) => {
            state.selectedData.industryVerticalId = action.payload;
        },
        setContactPerson: (state, action) => {
            state.selectedData.contactPerson = action.payload;            
        },
        setContactRole: (state, action) => {
            state.selectedData.contactRole = action.payload;            
        },
        setContactMail: (state, action) => {
            state.selectedData.contactMail = action.payload;            
        },
        setContactPhone: (state, action) => {
            state.selectedData.contactPhone = action.payload;            
        },

    },
    extraReducers: (builder) => {
        builder.addCase(fetchPartners.pending, (state) => {
            state.partnerStatus = requestLoading;
        }).addCase(fetchPartners.fulfilled, (state, action) => {
            state.partner = action.payload;
            state.partnerStatus = requestSuccessful;
        }).addCase(fetchPartners.rejected, (state) => {
            state.partnerStatus = requestMissed;
        });

        builder.addCase(fetchIndustryVerticals.pending, (state) => {
            state.industryVerticalsStatus = requestLoading;
        }).addCase(fetchIndustryVerticals.fulfilled, (state, action) => {
            state.industryVerticals = action.payload;
            state.industryVerticalsStatus = requestSuccessful;
        }).addCase(fetchIndustryVerticals.rejected, (state) => {
            state.industryVerticalsStatus = requestMissed;
        });

        builder.addCase(saveClient.pending, (state) => {
            state.status = requestLoading;
        }).addCase(saveClient.fulfilled, (state) => {
            state.status = requestSuccessful;
        }).addCase(saveClient.rejected, (state) => {
            state.status = requestMissed;
        });
    },
})

export const saveClientDialogReducer = saveClientDialog.reducer;
export const {openSaveClientDialog, closeSaveClientDialog, setVertical, setDate, setCountry, setPartner, setName,
    setContactPerson, setContactRole, setContactMail, setContactPhone} = saveClientDialog.actions;