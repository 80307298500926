import { Resource } from "./resourceModel";

export class ResourceIncome{
    id : number = 0;
    resourceId : number = 0;
    resource : Resource = new Resource();
    hours : number = 0;
    liquid : number = 0;
    nominal : number = 0;
    typeOfMoney : string = '';
    fechaUpdate : Date = new Date();
}