import { Typography, Button, Box } from "@mui/material";
import {blackColor, cardMustNotShowThisValue, greenColor, whiteColor} from "../../../constants/constants";
import { useAppDispatch, useAppSelector } from "../../../core/state/hooks";
import { selectDate } from "../../../core/state/date/dateSlice";
import { setWeekDayOfFirstDayOfMonth } from "../../../core/state/monthlyTracker/monthlyTrackerSlice";
import { fetchWeeklyRecords } from "../../../core/state/dailyTracker/dailyTrackerSlice";
import { isSameMonth, isSameWeek} from 'date-fns';


const DayInfo = (props:{time:string, dayNumber:number, isInMonthLastPosition:boolean, isInOuterColumn:boolean, isAtLeft:boolean}) =>{
  const dispatch = useAppDispatch();
  const { selectedDate } = useAppSelector((state) => state.date);
  const { user: { id: userId } } = useAppSelector((state) => state.user);
  const fetchData = (date:Date)=>{
    const actual=selectedDate;
    dispatch(selectDate(date));
    if(!isSameWeek(date,actual,{weekStartsOn: 1})){ 
      if(!isSameMonth(date,actual)){
        let firstDayOfMonth = new Date(date.getTime());
        firstDayOfMonth.setDate(1);
        let dayNumber = firstDayOfMonth.getDay();
        dispatch(setWeekDayOfFirstDayOfMonth(dayNumber));
      }
      dispatch(fetchWeeklyRecords(userId));
    }
  }
  const isInMonthLastPosition = props.isInMonthLastPosition;
  const isAtLeft = props.isAtLeft;
  const isInOuterColumn = props.isInOuterColumn;
  const time = props.time;
  const dayNumber = props.dayNumber;
  const thisDayDate = new Date();
  thisDayDate.setTime(selectedDate.getTime());
  thisDayDate.setDate(dayNumber);
  const timeStringSlices = props.time.split(":");
  const styles = {
    dayButtonClicked: {color:whiteColor, backgroundColor:greenColor, borderRadius:7, minWidth:{xs:30,md:32,lg:35}, mx:"auto", p:{xs:2/8,md:3/8,lg:5/8}},
    dayButtonNoClicked: {color:blackColor, borderRadius:7, minWidth:{xs:30,md:32,lg:35}, mx:"auto", p:{xs:2/8,md:3/8,lg:5/8}}
  }
  
  return <Box display={"flex"} flexDirection={"row"}  justifyContent={"center"} flex={1} minHeight={{xs: "3rem", md: "0rem"}} p={{xs:0.5, sm:1, md:1}} 
        borderBottom={"1px solid rgba(0, 0, 0, 0.03)"} className={isInMonthLastPosition? " border-b-0 m-b-15r" : "" + ((dayNumber !== cardMustNotShowThisValue)? " cursor-pointer" : "")}
        onClick={() => { 
          if (dayNumber !== cardMustNotShowThisValue){
            fetchData(thisDayDate)
          }
        }}>
      {(isInOuterColumn && dayNumber !== cardMustNotShowThisValue)? <Box display={"flex"} flexDirection={"column"} flex={isAtLeft? 0 : 0.5}></Box> : <></>}
      <Box display={"flex"} flex={1} flexDirection={"column"}  justifyContent={{xs:"end", md:"space-evenly"}}>
            {
              selectedDate && selectedDate.toISOString() === thisDayDate.toISOString() ?
              <Button sx={styles.dayButtonClicked}>
                <Typography className="" variant="h6">{dayNumber}</Typography> 
              </Button> :
              <Box>
                {
                  dayNumber !== cardMustNotShowThisValue?  
                  <Button sx={styles.dayButtonNoClicked} className="opacity-25">
                        <Typography variant="h6">{dayNumber}</Typography>
                  </Button>
                  :
                  <Box display={"flex"} flex={1} flexDirection={"column"}></Box>
                }
              </Box>
            }
            <Typography variant="subtitle2" sx={{color:blackColor}} className={selectedDate && selectedDate.toISOString() === thisDayDate.toISOString() ? "" : "opacity-4"}>
              {time && time !== "" && timeStringSlices[0] + ":" + timeStringSlices[1]}
            </Typography>
      </Box>
      {(isInOuterColumn && dayNumber !== cardMustNotShowThisValue)? <Box display={"flex"} flexDirection={"column"} flex={!isAtLeft? 0 : 0.5}></Box> : <></>}
    </Box>
}

export default DayInfo;