import {TableRow,TableCell, Checkbox } from "@mui/material";
import { emptyString, noneValue, } from "../../constants/constants";
import { SaleDTO } from "../../core/models/saleModelDTO";

export default function SalesRows (props : { sale : SaleDTO, visibility:string, isChecked : boolean, option:number, handleOnChange:(event: any) => void}) {

    return (
        <TableRow  hover role="checkbox" tabIndex={-1} sx={{maxHeight:'50px'}} >
            <TableCell align="right" sx={{p:0}}>
                <Checkbox id={`${props.sale.id}`} sx={{ display:props.visibility === noneValue ? noneValue : '',p:0,}} 
                    checked={props.isChecked}
                    onChange={props.handleOnChange}
                    value={props.option}
                    inputProps={{
                        'aria-labelledby': "labelId",
                    }} 
                />
            </TableCell>
            <TableCell align="center">{`${(props.sale.dateSale.toString().split("-")[2]).split('T')[0]}/${props.sale.dateSale.toString().split("-")[1]}/${props.sale.dateSale.toString().split("-")[0]}`}</TableCell>
            <TableCell align="center">{props.sale.client}</TableCell>
            <TableCell align="center">{props.sale.document}</TableCell>
            <TableCell align="center">{props.sale.number}</TableCell>
            <TableCell align="center">{props.sale && props.sale.concept ? (props.sale.concept.length > 23? `${props.sale.concept.slice(0, 22)}...`: props.sale.concept) : ('')}</TableCell>
            <TableCell align="center">{props.sale && props.sale.serviceName ? (props.sale.serviceName.length > 23? `${props.sale.serviceName.slice(0, 22)}...`: props.sale.serviceName) : ('')}</TableCell>
            <TableCell align="center">{props.sale.projectCode}</TableCell>
            <TableCell align="center">{props.sale.currency}</TableCell>
            <TableCell align="center">{`$${props.sale.netOrigin}`}</TableCell>
            <TableCell align="center">{`$${props.sale.tax}`}</TableCell>
            <TableCell align="center">{`$${props.sale.total}`}</TableCell>
            <TableCell align="center">{props.sale.dateOfPayment}</TableCell>
        </TableRow>
    )
}