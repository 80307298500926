import { TableRow, TableCell, Checkbox } from "@mui/material";
import { noneValue } from "../../constants/constants";
import { PurchaseDTO } from "../../core/models/purchaseModelDTO";
import { projectService} from "../../core/models/projectServiceModel";
import React from "react";
import {deletePurchaseRecurrences} from "../../core/state/dialogs/savePurchaseDialogSlice";

export default function PurchaseRow(props : {services:Array<projectService>, purchase : PurchaseDTO,visibility:string, isChecked : boolean, option:number, handleOnChange:(event: any) => void}) {
    const getPurchaseDate = (date : Date) : string => {
        const stringDateArray = date.toString().split("-");
        return `${(stringDateArray[2]).split('T')[0]}/${stringDateArray[1]}/${stringDateArray[0]}`
    }

    return (

        <TableRow  hover role="checkbox" tabIndex={-1} sx={{maxHeight:'50px'}} >
            <TableCell align="right" sx={{p:0}}>
                <Checkbox
                    id={`1`}
                    sx={{ display:props.visibility === noneValue ? noneValue : '',p:0,}}
                    checked={props.isChecked}
                    onChange={props.handleOnChange}
                    value={props.option}
                    inputProps={{
                        'aria-labelledby': "labelId",
                    }}
                />
            </TableCell>
            
            <TableCell align="center">{getPurchaseDate(props.purchase.date)}</TableCell>
            <TableCell align="center">{props.purchase.document.length > 30 ? `${props.purchase.document.slice(0,30)}...` : props.purchase.document}</TableCell>
            <TableCell align="center">{props.purchase.supplier.length > 30 ? `${props.purchase.supplier.slice(0,30)}...` : props.purchase.supplier}</TableCell>
            <TableCell align="center">{props.purchase.rut.length > 30 ? `${props.purchase.rut.slice(0,30)}...` : props.purchase.rut}</TableCell>
            <TableCell align="center">{props.purchase.sector}</TableCell>
            <TableCell align="center">{props.purchase.subSector}</TableCell>
            <TableCell align="center">{props.purchase.concept.length > 23 ? `${props.purchase.concept.slice(0,23)}...` : props.purchase.concept }</TableCell>
            <TableCell align="center">{props.purchase.service ? (props.purchase.service.length > 26 ? `${props.purchase.service.slice(0,25)}...` : props.purchase.service) : null}</TableCell>
            <TableCell align="center">{props.purchase.projectCode}</TableCell>
            <TableCell align="center">{props.purchase.currency}</TableCell>
            <TableCell align="center">{props.purchase.netOrigin.toString().length > 10 ? `${props.purchase.netOrigin.toString().slice(0,10)}...` : props.purchase.netOrigin.toString()}</TableCell>
            <TableCell align="center">{props.purchase.tax ? (props.purchase.tax.toString().length > 10 ? `${props.purchase.tax.toString().slice(0,10)}...` : props.purchase.tax.toString()) : ''}</TableCell>
            <TableCell align="center">{props.purchase.total ? (props.purchase.total.toString().length > 10 ? `${props.purchase.total.toString().slice(0,10)}...` : props.purchase.total.toString()) : ''}</TableCell>

        </TableRow>
    )
}